import actionTypes from './actionTypes'

export const actionFetchPenilaianTugasPelajar = (data) => {
  return {
    type: actionTypes.FETCH_PENILAIAN_TUGAS_PELAJAR,
    data
  }
}

export const actionFetchPenilaianTugasPelajarStart = () => {
  return {
    type: actionTypes.FETCH_PENILAIAN_TUGAS_PELAJAR_START
  }
}

export const actionFetchPenilaianTugasPelajarEnd = () => {
  return {
    type: actionTypes.FETCH_PENILAIAN_TUGAS_PELAJAR_END
  }
}

export const actionFetchPenilaianTugasPelajarSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PENILAIAN_TUGAS_PELAJAR_SUCCESS,
    data
  }
}

export const actionUpdatePenilaianTugasPelajar = (data) => {
  return {
    type: actionTypes.UPDATE_PENILAIAN_TUGAS_PELAJAR,
    data
  }
}

export const actionUpdatePenilaianTugasPelajarStart = () => {
  return {
    type: actionTypes.UPDATE_PENILAIAN_TUGAS_PELAJAR_START
  }
}

export const actionUpdatePenilaianTugasPelajarEnd = () => {
  return {
    type: actionTypes.UPDATE_PENILAIAN_TUGAS_PELAJAR_END
  }
}

export const actionUpdatePenilaianTugasPelajarSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_PENILAIAN_TUGAS_PELAJAR_SUCCESS,
    data
  }
}

export const actionUpdatePenilaianTugasPelajarFailed = (data) => {
  return {
    type: actionTypes.UPDATE_PENILAIAN_TUGAS_PELAJAR_FAILED,
    data
  }
}

export const actionResetFormPenilaianTugasPelajar = () => {
  return {
    type: actionTypes.RESET_FORM_PENILAIAN_TUGAS_PELAJAR
  }
}