import actionTypes from './actionTypes'

export const actionFetchVideo = (data) => {
  return {
    type: actionTypes.FETCH_VIDEO,
    data
  }
}

export const actionFetchVideoStart = () => {
  return {
    type: actionTypes.FETCH_VIDEO_START
  }
}

export const actionFetchVideoEnd = () => {
  return {
    type: actionTypes.FETCH_VIDEO_END
  }
}

export const actionFetchVideoSuccess = (data) => {
  return {
    type: actionTypes.FETCH_VIDEO_SUCCESS,
    data
  }
}

export const actionCreateVideo = (data) => {
  return {
    type: actionTypes.CREATE_VIDEO,
    data
  }
}

export const actionCreateVideoStart = () => {
  return {
    type: actionTypes.CREATE_VIDEO_START
  }
}

export const actionCreateVideoEnd = () => {
  return {
    type: actionTypes.CREATE_VIDEO_END
  }
}

export const actionCreateVideoSuccess = (data) => {
  return {
    type: actionTypes.CREATE_VIDEO_SUCCESS,
    data
  }
}

export const actionCreateVideoFailed = (data) => {
  return {
    type: actionTypes.CREATE_VIDEO_FAILED,
    data
  }
}

export const actionUpdateVideo = (data) => {
  return {
    type: actionTypes.UPDATE_VIDEO,
    data
  }
}

export const actionUpdateVideoStart = () => {
  return {
    type: actionTypes.UPDATE_VIDEO_START
  }
}

export const actionUpdateVideoEnd = () => {
  return {
    type: actionTypes.UPDATE_VIDEO_END
  }
}

export const actionUpdateVideoSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_VIDEO_SUCCESS,
    data
  }
}

export const actionUpdateVideoFailed = (data) => {
  return {
    type: actionTypes.UPDATE_VIDEO_FAILED,
    data
  }
}

export const actionResetFormVideo = () => {
  return {
    type: actionTypes.RESET_FORM_VIDEO
  }
}

export const actionDeleteVideo = (data) => {
  return {
    type: actionTypes.DELETE_VIDEO,
    data
  }
}

export const actionDeleteVideoStart = () => {
  return {
    type: actionTypes.DELETE_VIDEO_START
  }
}

export const actionDeleteVideoEnd = () => {
  return {
    type: actionTypes.DELETE_VIDEO_END
  }
}

export const actionDeleteVideoSuccess = () => {
  return {
    type: actionTypes.DELETE_VIDEO_SUCCESS
  }
}

export const actionDeleteVideoFailed = () => {
  return {
    type: actionTypes.DELETE_VIDEO_FAILED
  }
}

export const actionResetDeleteVideo = () => {
  return {
    type: actionTypes.RESET_DELETE_VIDEO
  }
}