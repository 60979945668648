import React from "react"

const DetailView = ({ data, key }) => {
    return <React.Fragment>
        <div key={key} className="table-responsive text-nowrap detail-view mt-2">
            <table className="table table-bordered">
                <tbody className="table-border-bottom-0">
                    {
                        data?.map((val, key) => {
                            return <tr key={key}>
                                <th style={{ width: "25%" }}>
                                    <strong>{val?.label}</strong>
                                </th>
                                <td>
                                    {
                                        val?.inner === true ? <span dangerouslySetInnerHTML={{ __html: val?.value }}></span>
                                            : <span>{val?.value}</span>
                                    }
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    </React.Fragment >
}

export default DetailView