import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionUpdateGambar, actionResetFormGambar } from "../../store/gambar/actions"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import Dropdown from "../../components/Form/Dropdown"
import { request } from "../../utils/request"
import NumberInput from "../../components/Form/NumberInput"
import TextInput from "../../components/Form/TextInput"
import FileInput from "../../components/Form/FileInput"
import TextEditor from "../../components/Form/TextEditor"
import MultiSelect from "../../components/Form/MultiSelect"
import { closeModal } from "../../utils/modal"

const initialPayload = {
    nama: "",
    pertemuan_id: "",
    keterangan: "",
    kelompok: [],
    file: {
        name: "",
        base64: "",
        size: "",
        extension: ""
    },
    status: ""
}

const GambarUpdate = (props) => {
    const { actionUpdateGambar, actionResetFormGambar, accessToken, match: { params: { id: _id } }, history: { location: { state } }, form, fromModal = false, gambarId = null, callbackSuccessModal = null } = props

    const [id, setId] = useState()

    const [payload, setPayload] = useState(initialPayload)
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)
    const [noData, setNoData] = useState(false)
    const [from, setFrom] = useState("/gambar")
    const [selectedFiles, setSelectedFiles] = useState({
        file: null,
    })
    const [optKelompok, setOptKelompok] = useState([])

    useEffect(() => {
        // const titlePage = "Ubah Gambar"
        // changeTitle(titlePage)
        // changeBreadcrumbs(titlePage)

        if (fromModal === false) {
            setId(_id)
            getData(_id)
        } else {
            setId(gambarId)
            getData(gambarId)
        }

        // const query = new URLSearchParams(props.location.search)
        // if (query.get('from') === "detail") {
        //     setFrom("/gambar/detail/" + id)
        // }

        actionResetFormGambar()
        getKelompok()

        return () => actionResetFormGambar()
    }, [gambarId, _id])

    const getKelompok = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_KELOMPOK + '/all', {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setOptKelompok(res?.result)
            }
        } catch (error) {
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const getData = async (_id = null) => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }

            if (_id === null) {
                _id = id
            }

            const res = await request(process.env.REACT_APP_SERVICE_GAMBAR + '/' + _id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                let result = {
                    ...res?.result,
                    "keterangan": ""
                }
                setPayload(result)
                setTimeout(() => {
                    setPayload({
                        ...result,
                        "keterangan": res?.result?.keterangan
                    })
                }, 500)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    useEffect(async () => {
        if (fromModal === true) {
            callbackSuccessModal(null)
        }

        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                if (fromModal !== true) {
                    pushUrl(from)
                } else {
                    callbackSuccessModal(true)
                    closeModal()
                }
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const onChange = ((field, value) => {
        if (field === "kelompok") {
            let kelompok = []

            value?.map((val) => {
                kelompok.push(val?.value)
            })

            payload[field] = kelompok
        } else {
            payload[field] = value
        }

        setPayload({
            ...payload,
        })
    })


    const onDrop = async (name, files) => {
        if (files?.length > 0) {
            let reader = new FileReader()
            reader.readAsDataURL(files[0])

            reader.onload = () => {
                let _selectedFiles = {
                    ...selectedFiles
                }
                _selectedFiles[name] = files
                setSelectedFiles(_selectedFiles)

                let _payload = {
                    ...payload
                }
                let fileName = files[0]?.name
                // let base64 = btoa(reader.result)
                let base64 = reader.result
                let extension = fileName?.substring(fileName.lastIndexOf('.') + 1)
                let size = files[0]?.size
                _payload[name] = {
                    name: fileName,
                    base64,
                    size,
                    extension
                }
                // console.log({ _payload })
                setPayload(_payload)
            }
            reader.onerror = () => {
                let jsonErrors = {
                    ...errors
                }
                jsonErrors[name] = "Terjadi kesalahan"
                setErrors(jsonErrors)
            }
        }

        // console.log({ files })
    }

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            actionUpdateGambar({
                id,
                payload,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.nama || payload?.nama === "") {
            hasError = true
            jsonErrors["nama"] = "Nama harus diisi"
        }
        if (!payload?.keterangan || payload?.keterangan === "") {
            hasError = true
            jsonErrors["keterangan"] = "Keterangan harus diisi"
        }
        if (!payload?.kelompok || payload?.kelompok?.length < 1) {
            hasError = true
            jsonErrors["kelompok"] = "Kelompok harus diisi"
        }
        if (!payload?.status || payload?.status === "") {
            hasError = true
            jsonErrors["status"] = "Status harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    const formView = () => {
        return <React.Fragment>
            {
                noData === true ?
                    <span>Data tidak ditemukan</span>
                    :
                    <form onSubmit={onSubmit} >
                        <div className="row">
                            <div className="col-lg-12 col-xl-10 col-xxl-8">
                                <TextInput
                                    id={"nama"}
                                    name={"nama"}
                                    placeholder={"Masukkan nama gambar"}
                                    label={"Nama"}
                                    onChange={(e) => onChange('nama', e?.target?.value)}
                                    required={true}
                                    value={payload?.nama}
                                    error={errors?.nama}
                                    errors={form?.errors?.nama}
                                    tabIndex={1} />

                                <FileInput
                                    name={"file"}
                                    label={"File"}
                                    error={errors?.file}
                                    accept={{
                                        // 'video/*': ['.mp4']
                                        'image/*': ['.png']
                                    }}
                                    placeholder={"Pilih file"}
                                    selectedFiles={selectedFiles?.file}
                                    onDrop={(e) => onDrop('file', e)}
                                    tabIndex={4}
                                />

                                <TextEditor
                                    id={"keterangan"}
                                    name={"keterangan"}
                                    placeholder={"Masukkan keterangan"}
                                    label={"Keterangan"}
                                    onChange={(value) => onChange('keterangan', value)}
                                    required={true}
                                    value={payload?.keterangan}
                                    error={errors?.keterangan}
                                    errors={form?.errors?.keterangan}
                                    tabIndex={4} />

                                <MultiSelect
                                    id={"kelompok"}
                                    name={"kelompok"}
                                    placeholder={"Pilih kelompok"}
                                    label={"Kelompok"}
                                    onChange={(e) => onChange('kelompok', e)}
                                    required={true}
                                    value={payload?.kelompok}
                                    error={errors?.kelompok}
                                    errors={form?.errors?.kelompok}
                                    tabIndex={5}
                                    options={optKelompok}
                                />

                                <Dropdown
                                    id={"status"}
                                    name={"status"}
                                    placeholder={"Pilih status"}
                                    label={"Status"}
                                    onChange={(e) => onChange('status', e?.target?.value)}
                                    required={true}
                                    value={payload?.status}
                                    error={errors?.status}
                                    errors={form?.errors?.status}
                                    tabIndex={6}
                                    options={[
                                        { label: "Aktif", value: "Aktif" },
                                        { label: "Tidak Aktif", value: "Tidak Aktif" }
                                    ]} />

                                <button
                                    tabIndex={7}
                                    type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                                    Kirim
                                    {form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                </button>
                            </div>
                        </div>
                    </form>
            }
        </React.Fragment>
    }

    return <React.Fragment>
        {
            fromModal === false ?
                <div className="container-xxl flex-grow-1 container-p-y">
                    <Link to={process.env.REACT_APP_SUBDIR + from} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

                    <div className="card mt-3">
                        <div className="card-body">
                            {formView()}
                        </div>
                    </div>
                </div>
                :
                formView()
        }
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, gambar: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionUpdateGambar, actionResetFormGambar })(GambarUpdate))