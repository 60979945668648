import { takeEvery, put, all, call, select } from 'redux-saga/effects'
import actionTypes from './actionTypes'
import { request } from '../../utils/request'
import { alert } from '../../utils/alert'
import { actionUpdateLaporanEnd, actionUpdateLaporanFailed, actionUpdateLaporanStart, actionUpdateLaporanSuccess, actionCreateLaporanEnd, actionCreateLaporanFailed, actionCreateLaporanStart, actionCreateLaporanSuccess, actionFetchLaporanEnd, actionFetchLaporanStart, actionFetchLaporanSuccess, actionDeleteLaporanSuccess, actionDeleteLaporanFailed, actionDeleteLaporanStart, actionDeleteLaporanEnd } from './actions'
import { pushUrl } from '../../utils/url'

function* sagaFetchLaporan(params) {
  yield put(actionFetchLaporanStart())

  const { data: { accessToken, search, page, sort, perPage, pertemuanId } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let sortParam = ""
    if (sort?.by) {
      sortParam += "&sortBy=" + sort?.by
    }
    if (sort?.type) {
      sortParam += "&sortType=" + sort?.type
    }

    let perPageParam = perPage ? "&perPage=" + perPage : ""

    let pageParam = page ? "?page=" + page : "?page=1"

    let searchParam = search ? "&search=" + search : ""

    let pertemuan_id = pertemuanId ? "&pertemuan_id=" + pertemuanId : ""

    let url = `${process.env.REACT_APP_SERVICE_LAPORAN}${pageParam}${searchParam}${sortParam}${perPageParam}${pertemuan_id}`
    const res = yield call(request, url, {
      method: 'GET',
      headers: header
    })

    if (res?.success === true) {
      let data = {
        list: res?.result?.data,
        page: res?.result?.current_page,
        pageUrl: {
          first_page_url: res?.result?.first_page_url,
          links: res?.result?.links,
          last_page_url: res?.result?.last_page_url
        },
        last_page: res?.result?.last_page,
        from: res?.result?.from,
        to: res?.result?.to,
        total: res?.result?.total
      }
      yield put(actionFetchLaporanSuccess(data))
    } else {
      alert({ title: "Gagal", html: res?.message })
    }
  } catch (err) {
    alert({ title: "Gagal" })
    // yield put(actionSignInFailed())
  }

  yield put(actionFetchLaporanEnd())
}

function* sagaCreateLaporan(params) {
  yield put(actionCreateLaporanStart())

  const { data: { accessToken, payload } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_LAPORAN}`
    const res = yield call(request, url, {
      method: 'POST',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionCreateLaporanSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionCreateLaporanFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal menambah data",
      errors: null
    }

    yield put(actionCreateLaporanFailed(data))
  }

  yield put(actionCreateLaporanEnd())
}

function* sagaUpdateLaporan(params) {
  yield put(actionUpdateLaporanStart())

  const { data: { accessToken, payload, id } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_LAPORAN}/${id}`
    const res = yield call(request, url, {
      method: 'PUT',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionUpdateLaporanSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionUpdateLaporanFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal merubah data",
      errors: null
    }

    yield put(actionUpdateLaporanFailed(data))
  }

  yield put(actionUpdateLaporanEnd())
}

function* sagaDeleteLaporan(params) {
  yield put(actionDeleteLaporanStart())

  const { data: { accessToken, id } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_LAPORAN}/${id}`
    const res = yield call(request, url, {
      method: 'DELETE',
      headers: header
    })

    if (res?.success === true) {
      yield put(actionDeleteLaporanSuccess())
    } else {
      yield put(actionDeleteLaporanFailed())
    }
  } catch (err) {
    yield put(actionDeleteLaporanFailed())
  }

  yield put(actionDeleteLaporanEnd())
}

function* laporanSaga() {
  yield all([
    takeEvery(actionTypes.FETCH_LAPORAN, sagaFetchLaporan),
    takeEvery(actionTypes.CREATE_LAPORAN, sagaCreateLaporan),
    takeEvery(actionTypes.UPDATE_LAPORAN, sagaUpdateLaporan),
    takeEvery(actionTypes.DELETE_LAPORAN, sagaDeleteLaporan),
  ])
}

export default laporanSaga