import { all } from 'redux-saga/effects'
import authSaga from './auth/saga'
import notifikasiSaga from './notifikasi/saga'

import pengajarSaga from './pengajar/saga'
import pelajarSaga from './pelajar/saga'
import kursusSaga from './kursus/saga'
import modulSaga from './modul/saga'
import pertemuanSaga from './pertemuan/saga'
import jobsheetSaga from './jobsheet/saga'
import gambarSaga from './gambar/saga'
import videoSaga from './video/saga'
import laporanSaga from './laporan/saga'
import quizSaga from './quiz/saga'
import quizAwalSaga from './quiz-awal/saga'
import kelompokSaga from './kelompok/saga'

import laporanTugasPelajarSaga from './laporan-tugas-pelajar/saga'
import laporanQuizAwalPelajarSaga from './laporan-quiz-awal-pelajar/saga'
import laporanQuizPelajarSaga from './laporan-quiz-pelajar/saga'
import laporanCapaianPelajarSaga from './laporan-capaian-pelajar/saga'
import penilaianTugasPelajarSaga from './penilaian-tugas-pelajar/saga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    notifikasiSaga(),
    
    pengajarSaga(),
    pelajarSaga(),
    kursusSaga(),
    modulSaga(),
    pertemuanSaga(),
    jobsheetSaga(),
    gambarSaga(),
    videoSaga(),
    laporanSaga(),
    quizSaga(),
    quizAwalSaga(),
    kelompokSaga(),

    laporanTugasPelajarSaga(),
    laporanQuizAwalPelajarSaga(),
    laporanQuizPelajarSaga(),
    laporanCapaianPelajarSaga(),
    penilaianTugasPelajarSaga()
  ])
}