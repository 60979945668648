import Immutable from 'seamless-immutable'

const FETCH_QUIZ = 'FETCH_QUIZ'
const FETCH_QUIZ_START = 'FETCH_QUIZ_START'
const FETCH_QUIZ_END = 'FETCH_QUIZ_END'
const FETCH_QUIZ_SUCCESS = 'FETCH_QUIZ_SUCCESS'
const FETCH_QUIZ_FAILED = 'FETCH_QUIZ_FAILED'

const CREATE_QUIZ = 'CREATE_QUIZ'
const CREATE_QUIZ_START = 'CREATE_QUIZ_START'
const CREATE_QUIZ_END = 'CREATE_QUIZ_END'
const CREATE_QUIZ_SUCCESS = 'CREATE_QUIZ_SUCCESS'
const CREATE_QUIZ_FAILED = 'CREATE_QUIZ_FAILED'

const UPDATE_QUIZ = 'UPDATE_QUIZ'
const UPDATE_QUIZ_START = 'UPDATE_QUIZ_START'
const UPDATE_QUIZ_END = 'UPDATE_QUIZ_END'
const UPDATE_QUIZ_SUCCESS = 'UPDATE_QUIZ_SUCCESS'
const UPDATE_QUIZ_FAILED = 'UPDATE_QUIZ_FAILED'

const DELETE_QUIZ = 'DELETE_QUIZ'
const DELETE_QUIZ_START = 'DELETE_QUIZ_START'
const DELETE_QUIZ_END = 'DELETE_QUIZ_END'
const DELETE_QUIZ_SUCCESS = 'DELETE_QUIZ_SUCCESS'
const DELETE_QUIZ_FAILED = 'DELETE_QUIZ_FAILED'

const RESET_FORM_QUIZ = 'RESET_FORM_QUIZ'
const RESET_DELETE_QUIZ = 'RESET_DELETE_QUIZ'

const actionTypes = Immutable({
  FETCH_QUIZ,
  FETCH_QUIZ_START,
  FETCH_QUIZ_END,
  FETCH_QUIZ_SUCCESS,
  FETCH_QUIZ_FAILED,

  CREATE_QUIZ,
  CREATE_QUIZ_START,
  CREATE_QUIZ_END,
  CREATE_QUIZ_SUCCESS,
  CREATE_QUIZ_FAILED,

  UPDATE_QUIZ,
  UPDATE_QUIZ_START,
  UPDATE_QUIZ_END,
  UPDATE_QUIZ_SUCCESS,
  UPDATE_QUIZ_FAILED,

  DELETE_QUIZ,
  DELETE_QUIZ_START,
  DELETE_QUIZ_END,
  DELETE_QUIZ_SUCCESS,
  DELETE_QUIZ_FAILED,

  RESET_FORM_QUIZ,
  RESET_DELETE_QUIZ
})

export default actionTypes
