import React from "react"

const NumberInput = ({ id = null, name, value, className = "", error = null, errors = null, hint = null, placeholder = null, label = null, onChange, required = false, tabIndex = null, unsigned = true, min = null, max = null }) => {
    return <React.Fragment>
        <div className="mb-3">
            <label htmlFor={id} className="form-label">
                {label}
                {required === true ? <span className="text-danger"> *</span> : null}
            </label>
            <input
                id={id}
                type="number"
                className={"form-control " + (error || errors ? "is-invalid" : "") + className}
                placeholder={placeholder}
                aria-label={label}
                aria-describedby={label}
                name={name}
                value={value}
                onChange={onChange}
                tabIndex={tabIndex}
                onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                min={unsigned === true ? 0 : min}
                max={max}
            />
            {
                hint ? <div className="form-text">{hint}</div> : null
            }
            {
                error ? <div className="invalid-feedback">{error}</div> : null
            }
            {
                errors?.map((val, key) => {
                    return <div key={key} className="invalid-feedback">{val}</div>
                })
            }
        </div>
    </React.Fragment >
}

export default NumberInput