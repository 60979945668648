import actionTypes from './actionTypes'

export const actionFetchLaporanQuizAwalPelajar = (data) => {
  return {
    type: actionTypes.FETCH_LAPORAN_QUIZ_AWAL_PELAJAR,
    data
  }
}

export const actionFetchLaporanQuizAwalPelajarStart = () => {
  return {
    type: actionTypes.FETCH_LAPORAN_QUIZ_AWAL_PELAJAR_START
  }
}

export const actionFetchLaporanQuizAwalPelajarEnd = () => {
  return {
    type: actionTypes.FETCH_LAPORAN_QUIZ_AWAL_PELAJAR_END
  }
}

export const actionFetchLaporanQuizAwalPelajarSuccess = (data) => {
  return {
    type: actionTypes.FETCH_LAPORAN_QUIZ_AWAL_PELAJAR_SUCCESS,
    data
  }
}