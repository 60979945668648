import actionTypes from './actionTypes'

export const actionFetchQuizAwal = (data) => {
  return {
    type: actionTypes.FETCH_QUIZ_AWAL,
    data
  }
}

export const actionFetchQuizAwalStart = () => {
  return {
    type: actionTypes.FETCH_QUIZ_AWAL_START
  }
}

export const actionFetchQuizAwalEnd = () => {
  return {
    type: actionTypes.FETCH_QUIZ_AWAL_END
  }
}

export const actionFetchQuizAwalSuccess = (data) => {
  return {
    type: actionTypes.FETCH_QUIZ_AWAL_SUCCESS,
    data
  }
}

export const actionCreateQuizAwal = (data) => {
  return {
    type: actionTypes.CREATE_QUIZ_AWAL,
    data
  }
}

export const actionCreateQuizAwalStart = () => {
  return {
    type: actionTypes.CREATE_QUIZ_AWAL_START
  }
}

export const actionCreateQuizAwalEnd = () => {
  return {
    type: actionTypes.CREATE_QUIZ_AWAL_END
  }
}

export const actionCreateQuizAwalSuccess = (data) => {
  return {
    type: actionTypes.CREATE_QUIZ_AWAL_SUCCESS,
    data
  }
}

export const actionCreateQuizAwalFailed = (data) => {
  return {
    type: actionTypes.CREATE_QUIZ_AWAL_FAILED,
    data
  }
}

export const actionUpdateQuizAwal = (data) => {
  return {
    type: actionTypes.UPDATE_QUIZ_AWAL,
    data
  }
}

export const actionUpdateQuizAwalStart = () => {
  return {
    type: actionTypes.UPDATE_QUIZ_AWAL_START
  }
}

export const actionUpdateQuizAwalEnd = () => {
  return {
    type: actionTypes.UPDATE_QUIZ_AWAL_END
  }
}

export const actionUpdateQuizAwalSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_QUIZ_AWAL_SUCCESS,
    data
  }
}

export const actionUpdateQuizAwalFailed = (data) => {
  return {
    type: actionTypes.UPDATE_QUIZ_AWAL_FAILED,
    data
  }
}

export const actionResetFormQuizAwal = () => {
  return {
    type: actionTypes.RESET_FORM_QUIZ_AWAL
  }
}

export const actionDeleteQuizAwal = (data) => {
  return {
    type: actionTypes.DELETE_QUIZ_AWAL,
    data
  }
}

export const actionDeleteQuizAwalStart = () => {
  return {
    type: actionTypes.DELETE_QUIZ_AWAL_START
  }
}

export const actionDeleteQuizAwalEnd = () => {
  return {
    type: actionTypes.DELETE_QUIZ_AWAL_END
  }
}

export const actionDeleteQuizAwalSuccess = () => {
  return {
    type: actionTypes.DELETE_QUIZ_AWAL_SUCCESS
  }
}

export const actionDeleteQuizAwalFailed = () => {
  return {
    type: actionTypes.DELETE_QUIZ_AWAL_FAILED
  }
}

export const actionResetDeleteQuizAwal = () => {
  return {
    type: actionTypes.RESET_DELETE_QUIZ_AWAL
  }
}