import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionDeletePertemuan, actionResetDeletePertemuan } from "../../store/pertemuan/actions"
import { request } from "../../utils/request"
import { alert, confirm } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import Modal from "../../components/Modal"
import PDFViewer from "../../components/PDFViewer"
import VideoPlayer from "../../components/VideoPlayer"
import { default as IndexJobsheet } from "../Jobsheet/index"
import { default as IndexGambar } from "../Gambar/index"
import { default as IndexVideo } from "../Video/index"
import { default as IndexLaporan } from "../Laporan/index"
import { default as IndexQuiz } from "../Quiz/index"
import { default as IndexQuizAwal } from "../QuizAwal/index"
import { default as UpdatePertemuan } from "../Pertemuan/update"
import TextEditor from "../../components/Form/TextEditor"
import { closeModal } from "../../utils/modal"

const PertemuanDetail = (props) => {
    const { actionDeletePertemuan, actionResetDeletePertemuan, accessToken, match: { params: { id: _id } }, history: { location: { state } }, index, del, directCall = true, pertemuanId = null, onClickBack = null } = props
    const [data, setData] = useState(null)
    const [noData, setNoData] = useState(false)
    const [submit, setSubmit] = useState(false)

    const [payloadRasional, setPayloadRasional] = useState("")
    const [errorsRasional, setErrorsRasional] = useState(null)
    const [loadingRasional, setLoadingRasional] = useState(false)

    const [tab, setTab] = useState("Rasional")
    const [refreshData, setRefreshData] = useState(false)

    const [id, setId] = useState()

    const [successUpdatePertemuan, setSuccessUpdatePertemuan] = useState(null)

    useEffect(() => {
        const titlePage = "Detail Pertemuan"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        if (directCall === true) {
            setId(_id)
            if (state) {
                setData(state)
            } else {
                getData()
            }
        } else {
            setId(pertemuanId)
            getData(pertemuanId)
        }

        actionResetDeletePertemuan()

        return () => actionResetDeletePertemuan()
    }, [])

    useEffect(() => {
        if (refreshData === true) {
            getData()
            setRefreshData(false)
        }
    }, [refreshData])

    useEffect(() => {
        if (submit === true) {
            if (del?.success === true) {
                alert({ title: "Berhasil hapus data" })
                pushUrl("/pertemuan")
            } else if (del?.success === false) {
                alert({ title: "Gagal hapus data" })
            }
        }
    }, [del?.success, submit])

    const getData = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            let _id = id
            if (pertemuanId) {
                _id = pertemuanId
            }
            const res = await request(process.env.REACT_APP_SERVICE_PERTEMUAN + '/' + _id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setData(res?.result)
                setPayloadRasional(res?.result?.rasional)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const classStatus = (status) => {
        if (status === "Aktif") {
            return "bg-label-success"
        } else if (status === "Tidak Aktif") {
            return "bg-label-danger"
        }

        return "bg-label-secondary"
    }

    const deleteData = async (e) => {
        e.preventDefault()

        let res = await confirm({
            title: "Hapus pertemuan",
            html: "Apakah anda yakin menghapus data ini?"
        })
        if (res === true) {
            setSubmit(true)
            actionDeletePertemuan({ accessToken, id })
        }
    }

    const dataView = () => {
        return <React.Fragment>
            {
                noData === true ?
                    <span>Data tidak ditemukan</span>
                    :
                    <React.Fragment>
                        {/* <small className="text-muted text-uppercase">Pertemuan</small> */}
                        <div className="table-responsive text-nowrap detail-view mt-2">
                            <table className="table table-bordered">
                                <tbody className="table-border-bottom-0">
                                    <tr>
                                        <th style={{ width: "25%" }}>
                                            <strong>Nama Modul</strong>
                                        </th>
                                        <td>{data?.modul?.nama}</td>
                                    </tr>
                                    <tr>
                                        <th style={{ width: "25%" }}>
                                            <strong>Nama Pertemuan</strong>
                                        </th>
                                        <td>{data?.nama}</td>
                                    </tr>
                                    <tr>
                                        <th style={{ width: "25%" }}>
                                            <strong>Keterangan</strong>
                                        </th>
                                        <td><span dangerouslySetInnerHTML={{ __html: data?.keterangan }}></span></td>
                                    </tr>
                                    <tr>
                                        <th style={{ width: "25%" }}>
                                            <strong>Durasi Quiz</strong>
                                        </th>
                                        <td>{data?.durasi_quiz} menit</td>
                                    </tr>
                                    <tr>
                                        <th style={{ width: "25%" }}>
                                            <strong>Status</strong>
                                        </th>
                                        <td><span className={"badge me-1 " + classStatus(data?.status)}>{data?.status}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
            }
        </React.Fragment >
    }

    useEffect(() => {
        if (successUpdatePertemuan === true) {
            getData()
            setSuccessUpdatePertemuan(null)
        }
    }, [successUpdatePertemuan])

    const actionSubmitRasional = async () => {
        try {
            const headerPost = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`,
            }

            const res = await request(process.env.REACT_APP_SERVICE_PERTEMUAN + '/rasional/' + id, {
                method: 'POST',
                headers: headerPost,
                body: JSON.stringify({
                    rasional: payloadRasional,
                })
            })

            if (res?.success === true) {
                alert({ title: "Berhasil", html: "Berhasil " + (data?.id ? "mengubah" : "menambah") + " rasional" })
                getData()
            }
        } catch (error) {
            alert({ title: "Gagal", html: "Gagal mengirim data ke server" })
        }
    }

    const onSubmitRasional = async (e) => {
        e.preventDefault()

        if (validateRasional() === true) {
            setLoadingRasional(true)

            await actionSubmitRasional()

            setLoadingRasional(false)
            closeModal()
        }

        e.preventDefault()
    }

    const validateRasional = () => {
        let errors = null
        let hasError = false

        if (!payloadRasional || payloadRasional === "") {
            hasError = true
            errors = ["Rasional harus diisi"]
        }

        setErrorsRasional(errors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    const itemView = () => {
        return <React.Fragment>
            {
                noData !== true ?
                    <React.Fragment>
                        <div className="nav-align-top mt-4">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={"nav-link " + (tab === "Rasional" ? "active" : "")} onClick={() => setTab('Rasional')} tabIndex="-1">Rasional</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={"nav-link " + (tab === "Quiz Awal" ? "active" : "")} onClick={() => setTab('Quiz Awal')} tabIndex="-1">Quiz Awal [{data?.jumlah_quiz_awal || 0}]</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={"nav-link " + (tab === "Jobsheet" ? "active" : "")} onClick={() => setTab('Jobsheet')} aria-selected="true">Jobsheet [{data?.jumlah_jobsheet || 0}]</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={"nav-link " + (tab === "Gambar" ? "active" : "")} onClick={() => setTab('Gambar')} tabIndex="-1">Gambar [{data?.jumlah_gambar || 0}]</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={"nav-link " + (tab === "Video" ? "active" : "")} onClick={() => setTab('Video')} tabIndex="-1">Video [{data?.jumlah_video || 0}]</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={"nav-link " + (tab === "Quiz" ? "active" : "")} onClick={() => setTab('Quiz')} tabIndex="-1">Quiz [{data?.jumlah_quiz || 0}]</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" className={"nav-link " + (tab === "Laporan" ? "active" : "")} onClick={() => setTab('Laporan')} tabIndex="-1">Tugas [{data?.jumlah_laporan || 0}]</button>
                                </li>
                            </ul>

                            {
                                data?.id ?
                                    <React.Fragment>
                                        <div className={"card mt-2 " + (tab === "Rasional" ? "" : "d-none")}>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-8">
                                                        <a href="#" type="button" data-bs-toggle="modal" data-bs-target="#modalRasional" className="btn btn-primary mb-2 btn-md-block">{data?.rasional ? "Ubah Rasional" : "Tambah Rasional"}</a>
                                                    </div>
                                                </div>

                                                <div className="table-responsive text-nowrap detail-view mt-3">
                                                    <table className="table table-bordered">
                                                        <tbody className="table-border-bottom-0">
                                                            <tr>
                                                                <th style={{ width: "25%" }}>
                                                                    <strong>Rasional</strong>
                                                                </th>
                                                                <td>
                                                                    {
                                                                        data?.rasional ?
                                                                            <span dangerouslySetInnerHTML={{ __html: data?.rasional }} />
                                                                            :
                                                                            <span>-</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"card mt-2 " + (tab === "Quiz Awal" ? "" : "d-none")}>
                                            <div className="card-body">
                                                <IndexQuizAwal directCall={false} pertemuanId={id} callback={() => setRefreshData(true)} />
                                            </div>
                                        </div>

                                        <div className={"card mt-2 " + (tab === "Jobsheet" ? "" : "d-none")}>
                                            <div className="card-body">
                                                <IndexJobsheet directCall={false} pertemuanId={id} callback={() => setRefreshData(true)} />
                                            </div>
                                        </div>

                                        <div className={"card mt-2 " + (tab === "Gambar" ? "" : "d-none")}>
                                            <div className="card-body">
                                                <IndexGambar directCall={false} pertemuanId={id} callback={() => setRefreshData(true)} />
                                            </div>
                                        </div>

                                        <div className={"card mt-2 " + (tab === "Video" ? "" : "d-none")}>
                                            <div className="card-body">
                                                <IndexVideo directCall={false} pertemuanId={id} callback={() => setRefreshData(true)} />
                                            </div>
                                        </div>

                                        <div className={"card mt-2 " + (tab === "Quiz" ? "" : "d-none")}>
                                            <div className="card-body">
                                                <IndexQuiz directCall={false} pertemuanId={id} callback={() => setRefreshData(true)} />
                                            </div>
                                        </div>

                                        <div className={"card mt-2 " + (tab === "Laporan" ? "" : "d-none")}>
                                            <div className="card-body">
                                                <IndexLaporan directCall={false} pertemuanId={id} callback={() => setRefreshData(true)} />
                                            </div>
                                        </div>

                                        <Modal id={"modalRasional"} title={data?.rasional ? "Ubah Rasional" : "Tambah Rasional"} size={"modal-lg"} buttons={false}>
                                            <form onSubmit={onSubmitRasional} >
                                                <TextEditor
                                                    id={"rasional"}
                                                    name={"rasional"}
                                                    placeholder={"Masukkan rasional"}
                                                    label={"Rasional"}
                                                    onChange={(value) => setPayloadRasional(value)}
                                                    required={true}
                                                    value={payloadRasional}
                                                    errors={errorsRasional} />

                                                <button
                                                    type="submit" className="btn btn-primary btn-md-block" disabled={loadingRasional === true}>
                                                    Kirim
                                                    {loadingRasional === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                                </button>
                                            </form>
                                        </Modal>
                                    </React.Fragment>
                                    : null
                            }
                        </div>
                    </React.Fragment>
                    : null
            }
        </React.Fragment>
    }

    return <React.Fragment>
        {
            directCall === true ?
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row">
                        <div className="col-lg-4">
                            <Link to={process.env.REACT_APP_SUBDIR + "/pertemuan"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>
                        </div>
                        <div className="col-lg-8 text-end">
                            <Link to={process.env.REACT_APP_SUBDIR + "/pertemuan/update/" + data?.id + "?from=detail"} className="btn btn-success mb-2 btn-md-block">Ubah</Link>
                            <a href="/#" onClick={(e) => deleteData(e)} className={"btn btn-danger mb-2 btn-md-block " + (del?.loading === true ? "disabled" : "")}>Hapus {del?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}</a>
                        </div>
                    </div>

                    <div className="mt-2">
                        <small className="text-muted text-uppercase">Pertemuan</small>
                    </div>

                    <div className="card mt-2">
                        <div className="card-body">
                            {dataView()}
                        </div>
                    </div>

                    {itemView()}
                </div>
                :
                <React.Fragment>
                    <Modal id={"modalUpdateKursusModulPertemuan"} title={"Ubah Pertemuan"} button={{ visible: true, label: "Kirim" }} size={"modal-lg"} buttons={false}>
                        {
                            id ?
                                <UpdatePertemuan fromModal={true} pertemuanId={id} callbackSuccessModal={setSuccessUpdatePertemuan} />
                                : null
                        }
                    </Modal>

                    <div className="mt-2">
                        <div className="mt-4">
                            <small className="text-muted text-uppercase">Pertemuan</small>
                        </div>

                        <div className="card mt-2">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <button type="button" className="btn btn-white mb-2 btn-md-block" onClick={onClickBack}>Kembali ke List</button>
                                    </div>
                                    <div className="col-md-8 text-end">
                                        <a href="#" type="button" data-bs-toggle="modal" data-bs-target="#modalUpdateKursusModulPertemuan" className="btn btn-success  mb-2">Ubah</a>
                                        <a href="/#" onClick={(e) => deleteData(e)} className={"btn btn-danger mb-2 btn-md-block " + (del?.loading === true ? "disabled" : "")}>Hapus Modul {del?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}</a>
                                    </div>
                                </div>

                                {dataView()}
                            </div>
                        </div>

                        {itemView()}
                    </div >
                </React.Fragment>
        }
    </React.Fragment >
}

const mapStateToProps = state => {
    const { auth: { accessToken }, pertemuan: { del, index } } = state

    return { accessToken, del, index }
}

export default withRouter(connect(mapStateToProps, { actionDeletePertemuan, actionResetDeletePertemuan })(PertemuanDetail))