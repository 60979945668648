import React from "react"

const OuterSortTable = ({ sortBy, onChange, sortItem }) => {
    return <React.Fragment>
        <div className="d-block d-lg-flex">
            <label className="me-2 justify-content-center align-self-center">Urutkan</label>
            <select aria-controls="Maks" className="form-select" value={sortBy} onChange={(e) => onChange(e?.target?.value)}>
                {
                    sortItem?.map((val, key) => {
                        return <option value={val?.value} key={key}>{val?.label}</option>
                    })
                }
            </select>
        </div>
    </React.Fragment>
}

export default OuterSortTable