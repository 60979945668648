import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionCreateKursus, actionResetFormKursus } from "../../store/kursus/actions"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import Dropdown from "../../components/Form/Dropdown"
import { request } from "../../utils/request"
import NumberInput from "../../components/Form/NumberInput"
import TextInput from "../../components/Form/TextInput"
import FileInput from "../../components/Form/FileInput"
import TextArea from "../../components/Form/TextArea"
import TextEditor from "../../components/Form/TextEditor"

const KursusCreate = (props) => {
    const { actionCreateKursus, actionResetFormKursus, accessToken, form } = props

    const [payload, setPayload] = useState({
        nama: "",
        jenis_urutan: "",
        logo: {
            name: "",
            base64: "",
            size: "",
            extension: ""
        },
        banner: {
            name: "",
            base64: "",
            size: "",
            extension: ""
        },
        keterangan: "",
        status: ""
    })
    const [selectedFiles, setSelectedFiles] = useState({
        logo: null,
        banner: null,
    })
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)

    useEffect(() => {
        const titlePage = "Tambah Kursus"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        actionResetFormKursus()

        return () => actionResetFormKursus()
    }, [])

    useEffect(async () => {
        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                pushUrl("/kursus")
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const onChange = ((field, value) => {
        payload[field] = value

        setPayload({
            ...payload,
        })
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            actionCreateKursus({
                payload,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.nama || payload?.nama === "") {
            hasError = true
            jsonErrors["nama"] = "Nama harus diisi"
        }
        if (!payload?.jenis_urutan || payload?.jenis_urutan === "") {
            hasError = true
            jsonErrors["jenis_urutan"] = "Jenis urutan harus diisi"
        }
        if (!payload?.logo?.name || payload?.logo?.name === "") {
            hasError = true
            jsonErrors["logo"] = "Logo harus diisi"
        }
        if (!payload?.banner?.name || payload?.logo?.banner === "") {
            hasError = true
            jsonErrors["banner"] = "Banner harus diisi"
        }
        if (!payload?.keterangan || payload?.keterangan === "") {
            hasError = true
            jsonErrors["keterangan"] = "Keterangan harus diisi"
        }
        if (!payload?.status || payload?.status === "") {
            hasError = true
            jsonErrors["status"] = "Status harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }


    const onDrop = async (name, files) => {
        if (files?.length > 0) {
            let reader = new FileReader()
            reader.readAsDataURL(files[0])

            reader.onload = function () {
                let _selectedFiles = {
                    ...selectedFiles
                }
                _selectedFiles[name] = files
                setSelectedFiles(_selectedFiles)

                let _payload = {
                    ...payload
                }
                let fileName = files[0]?.name
                let base64 = reader.result
                let extension = fileName?.substring(fileName.lastIndexOf('.') + 1)
                let size = files[0]?.size
                _payload[name] = {
                    name: fileName,
                    base64,
                    size,
                    extension
                }
                // console.log({ _payload })
                setPayload(_payload)
            };
            reader.onerror = () => {
                let jsonErrors = {
                    ...errors
                }
                jsonErrors[name] = "Terjadi kesalahan"
                setErrors(jsonErrors)
            };
        }

        // console.log({ files })
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <Link to={process.env.REACT_APP_SUBDIR + "/kursus"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

            <div className="card mt-3">
                <div className="card-body">
                    <form onSubmit={onSubmit} >
                        <div className="row">
                            <div className="col-lg-12 col-xl-10 col-xxl-8">
                                <TextInput
                                    id={"nama"}
                                    name={"nama"}
                                    placeholder={"Masukkan nama kursus"}
                                    label={"Nama"}
                                    onChange={(e) => onChange('nama', e?.target?.value)}
                                    required={true}
                                    value={payload?.nama}
                                    error={errors?.nama}
                                    errors={form?.errors?.nama}
                                    tabIndex={1} />

                                <Dropdown
                                    id={"jenis_urutan"}
                                    name={"jenis_urutan"}
                                    placeholder={"Pilih jenis urutan"}
                                    label={"Jenis Urutan"}
                                    onChange={(e) => onChange('jenis_urutan', e?.target?.value)}
                                    required={true}
                                    value={payload?.jenis_urutan}
                                    error={errors?.jenis_urutan}
                                    errors={form?.errors?.jenis_urutan}
                                    tabIndex={2}
                                    options={[
                                        { label: "Urut", value: "Urut" },
                                        { label: "Acak", value: "Acak" }
                                    ]} />

                                <FileInput
                                    name={"logo"}
                                    label={"Logo"}
                                    error={errors?.logo}
                                    accept={{
                                        'image/*': ['.png']
                                    }}
                                    placeholder={"Pilih logo"}
                                    selectedFiles={selectedFiles?.logo}
                                    onDrop={(e) => onDrop('logo', e)}
                                    tabIndex={3}
                                />

                                <FileInput
                                    name={"banner"}
                                    label={"Banner"}
                                    error={errors?.banner}
                                    accept={{
                                        'image/*': ['.png']
                                    }}
                                    placeholder={"Pilih banner"}
                                    selectedFiles={selectedFiles?.banner}
                                    onDrop={(e) => onDrop('banner', e)}
                                    tabIndex={4}
                                />

                                <TextEditor
                                    id={"keterangan"}
                                    name={"keterangan"}
                                    placeholder={"Masukkan keterangan"}
                                    label={"Keterangan"}
                                    onChange={(value) => onChange('keterangan', value)}
                                    required={true}
                                    value={payload?.keterangan}
                                    error={errors?.keterangan}
                                    errors={form?.errors?.keterangan}
                                    tabIndex={5} />

                                <Dropdown
                                    id={"status"}
                                    name={"status"}
                                    placeholder={"Pilih status"}
                                    label={"Status"}
                                    onChange={(e) => onChange('status', e?.target?.value)}
                                    required={true}
                                    value={payload?.status}
                                    error={errors?.status}
                                    errors={form?.errors?.status}
                                    tabIndex={6}
                                    options={[
                                        { label: "Aktif", value: "Aktif" },
                                        { label: "Tidak Aktif", value: "Tidak Aktif" }
                                    ]} />

                                <button
                                    tabIndex={7}
                                    type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                                    Kirim
                                    {form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, kursus: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionCreateKursus, actionResetFormKursus })(KursusCreate))