import actionTypes from './actionTypes'

export const actionFetchGambar = (data) => {
  return {
    type: actionTypes.FETCH_GAMBAR,
    data
  }
}

export const actionFetchGambarStart = () => {
  return {
    type: actionTypes.FETCH_GAMBAR_START
  }
}

export const actionFetchGambarEnd = () => {
  return {
    type: actionTypes.FETCH_GAMBAR_END
  }
}

export const actionFetchGambarSuccess = (data) => {
  return {
    type: actionTypes.FETCH_GAMBAR_SUCCESS,
    data
  }
}

export const actionCreateGambar = (data) => {
  return {
    type: actionTypes.CREATE_GAMBAR,
    data
  }
}

export const actionCreateGambarStart = () => {
  return {
    type: actionTypes.CREATE_GAMBAR_START
  }
}

export const actionCreateGambarEnd = () => {
  return {
    type: actionTypes.CREATE_GAMBAR_END
  }
}

export const actionCreateGambarSuccess = (data) => {
  return {
    type: actionTypes.CREATE_GAMBAR_SUCCESS,
    data
  }
}

export const actionCreateGambarFailed = (data) => {
  return {
    type: actionTypes.CREATE_GAMBAR_FAILED,
    data
  }
}

export const actionUpdateGambar = (data) => {
  return {
    type: actionTypes.UPDATE_GAMBAR,
    data
  }
}

export const actionUpdateGambarStart = () => {
  return {
    type: actionTypes.UPDATE_GAMBAR_START
  }
}

export const actionUpdateGambarEnd = () => {
  return {
    type: actionTypes.UPDATE_GAMBAR_END
  }
}

export const actionUpdateGambarSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_GAMBAR_SUCCESS,
    data
  }
}

export const actionUpdateGambarFailed = (data) => {
  return {
    type: actionTypes.UPDATE_GAMBAR_FAILED,
    data
  }
}

export const actionResetFormGambar = () => {
  return {
    type: actionTypes.RESET_FORM_GAMBAR
  }
}

export const actionDeleteGambar = (data) => {
  return {
    type: actionTypes.DELETE_GAMBAR,
    data
  }
}

export const actionDeleteGambarStart = () => {
  return {
    type: actionTypes.DELETE_GAMBAR_START
  }
}

export const actionDeleteGambarEnd = () => {
  return {
    type: actionTypes.DELETE_GAMBAR_END
  }
}

export const actionDeleteGambarSuccess = () => {
  return {
    type: actionTypes.DELETE_GAMBAR_SUCCESS
  }
}

export const actionDeleteGambarFailed = () => {
  return {
    type: actionTypes.DELETE_GAMBAR_FAILED
  }
}

export const actionResetDeleteGambar = () => {
  return {
    type: actionTypes.RESET_DELETE_GAMBAR
  }
}