import actionTypes from './actionTypes'

export const actionFetchLaporanQuizPelajar = (data) => {
  return {
    type: actionTypes.FETCH_LAPORAN_QUIZ_PELAJAR,
    data
  }
}

export const actionFetchLaporanQuizPelajarStart = () => {
  return {
    type: actionTypes.FETCH_LAPORAN_QUIZ_PELAJAR_START
  }
}

export const actionFetchLaporanQuizPelajarEnd = () => {
  return {
    type: actionTypes.FETCH_LAPORAN_QUIZ_PELAJAR_END
  }
}

export const actionFetchLaporanQuizPelajarSuccess = (data) => {
  return {
    type: actionTypes.FETCH_LAPORAN_QUIZ_PELAJAR_SUCCESS,
    data
  }
}