import React, { useEffect } from "react"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = ({ id = null, name, value, className = "", error = null, errors = null, hint = null, placeholder = null, label = null, onChange, required = false, tabIndex = null }) => {

    return <React.Fragment>
        <div className="mb-3">
            <label htmlFor={id} className="form-label">
                {label}
                {required === true ? <span className="text-danger"> *</span> : null}
            </label>

            <ReactDatePicker
                showMonthDropdown={true}
                showYearDropdown={true}
                // dropdownMode="select"
                minDate={new Date()}
                // maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                selected={value}
                autoComplete="off"
                id={id}
                className={"form-control date-picker " + (error || errors ? "is-invalid" : "") + className}
                placeholderText={placeholder}
                aria-label={label}
                aria-describedby={label}
                name={name}
                onChange={onChange}
                tabIndex={tabIndex}
            />
            {
                hint ? <div className="form-text">{hint}</div> : null
            }
            {
                error ? <div className="invalid-feedback d-block">{error}</div> : null
            }
            {
                errors?.map((val, key) => {
                    return <div key={key} className="invalid-feedback d-block">{val}</div>
                })
            }
        </div>
    </React.Fragment >
}

export default DatePicker