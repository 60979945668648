import React from "react"

const Modal = ({ children, id, title, button = { visible: false, disabled: false, label: "", onClick: null, class: "" }, button2 = { visible: true }, size = "", buttons = true }) => {
    return <React.Fragment>
        <div className={"modal fade"} id={id} tabIndex="-1" aria-hidden="true">
            <div className={"modal-dialog " + size} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    {
                        buttons === true ?
                            <div className="modal-footer">
                                {
                                    button2?.visible === true ?
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        : null
                                }
                                {
                                    button?.visible === true ?
                                        <button type="button" onClick={button?.onClick} className={"btn btn-primary " + button?.class} disabled={button?.disabled}>{button?.label}</button>
                                        : null
                                }
                            </div>
                            : null
                    }
                </div>
            </div>
        </div>
    </React.Fragment >
}

export default Modal