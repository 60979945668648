import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionUpdatePenilaianTugasPelajar, actionResetFormPenilaianTugasPelajar } from "../../store/penilaian-tugas-pelajar/actions"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import Dropdown from "../../components/Form/Dropdown"
import { request } from "../../utils/request"
import NumberInput from "../../components/Form/NumberInput"
import TextInput from "../../components/Form/TextInput"
import HeadTable from "../../components/Table/HeadTable"

const PenilaianTugasPelajarUpdate = (props) => {
    const { actionUpdatePenilaianTugasPelajar, actionResetFormPenilaianTugasPelajar, accessToken, match: { params: { id } }, history: { location: { state } }, form } = props

    const [payload, setPayload] = useState([])
    const [data, setData] = useState(null)
    const [errors, setErrors] = useState([])
    const [submit, setSubmit] = useState(false)
    const [noData, setNoData] = useState(false)
    const [from, setFrom] = useState("/penilaian-tugas-pelajar")

    useEffect(() => {
        const titlePage = "Penilaian Tugas Pelajar"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        getData()

        const query = new URLSearchParams(props.location.search)
        if (query.get('from') === "detail") {
            setFrom("/penilaian-tugas-pelajar/detail/" + id)
        }

        actionResetFormPenilaianTugasPelajar()

        return () => actionResetFormPenilaianTugasPelajar()
    }, [])

    const getData = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_PENILAIAN_TUGAS_PELAJAR + '/' + id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setData(res?.result)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    useEffect(async () => {
        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                pushUrl(from)
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const getNilaiPelajar = (nilai) => {
        let rangeNilai = data?.rangeNilai
        let hasil = "-"

        let objNilai = rangeNilai?.filter((val) => val?.nilaiAwal <= nilai && nilai <= val?.nilaiAkhir)
        if (objNilai?.length > 0) {
            hasil = objNilai[0]?.hasil
        }
        
        return hasil
    }

    const onChangeNilai = ((id, value) => {
        if (value >= 0 && value <= 100) {
            let _payload = [...payload]
            let _data = { ...data }
            let hasil = ""

            hasil = getNilaiPelajar(value)

            let existKey = _payload?.findIndex(val => val?.id === id)
            if (existKey >= 0) {
                _payload[existKey] = { id, nilai: value }
            } else {
                _payload.push({ id, nilai: parseInt(value) })
            }

            existKey = _data?.tugas?.findIndex(val => val?.id === id)

            if (existKey >= 0) {
                _data.tugas[existKey] = { ..._data?.tugas[existKey] }
                _data.tugas[existKey]["nilai"] = parseInt(value)
                _data.tugas[existKey]["hasil"] = hasil
            }

            setPayload(_payload)
            setData(_data)
        }
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            actionUpdatePenilaianTugasPelajar({
                id,
                payload: { data: payload },
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = []
        let hasError = false

        data?.tugas?.map((val, key) => {
            if (!val?.nilai || val?.nilai === "") {
                hasError = true
                jsonErrors[val?.id] = "Nilai harus diisi"
            }
        })

        console.log({ jsonErrors })

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    const listTugas = () => {
        return <React.Fragment>
            <div className="table-responsive">
                <table className="table table-bordered">
                    <HeadTable
                        colorized={false}
                        columns={[
                            { type: "label", label: "No." },
                            { type: "label", label: "Tugas" },
                            { type: "label", label: "File" },
                            { type: "label", label: "Nilai" },
                            { type: "label", label: "Hasil" }
                        ]}
                    />
                    <tbody className="table-border-bottom-0">
                        {
                            data?.tugas?.length > 0 ?
                                data?.tugas?.map((val, key) => {
                                    return <React.Fragment key={key}>
                                        <tr>
                                            <td rowSpan={errors[val?.id] ? 2 : 1}><strong>{key + 1}</strong></td>
                                            <td>{val?.laporan?.nama || "-"}</td>
                                            <td>
                                                <a href={process.env.REACT_APP_PELAJAR_SERVICE + '/uploads/laporan/' + val?.file} target="_blank">
                                                    Download
                                                </a>
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className={"form-control"}
                                                    placeholder={"Nilai 1-100"}
                                                    name={"nilai-" + key}
                                                    value={val?.nilai || ""}
                                                    onChange={(e) => onChangeNilai(val?.id, e?.target?.value)}
                                                    tabIndex={"nilai-" + key}
                                                    onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                                    min={0}
                                                    max={100}
                                                    style={{ width: "100px" }}
                                                />
                                            </td>
                                            <td>{val?.hasil || "-"}</td>
                                        </tr>
                                        {
                                            errors[val?.id] ?
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className="d-block invalid-feedback">{errors[val?.id]}</div>
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                    </React.Fragment>
                                })
                                : <tr>
                                    <td colSpan={5}>Data tidak ditemukan</td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <form onSubmit={onSubmit} >
                <div className="row">
                    <div className="col-sm-4">
                        <Link to={process.env.REACT_APP_SUBDIR + from} className="btn btn-white mb-2 btn-md-block">Kembali</Link>
                    </div>
                    <div className="col-sm-8 text-end">
                        {
                            noData === true ?
                                null
                                :
                                <button
                                    tabIndex={data?.tugas?.length > 0 ? data?.tugas?.length + 1 : 0}
                                    type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                                    Kirim
                                    {form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                </button>
                        }
                    </div>
                </div>

                <div className="card mt-3">
                    <div className="card-body">
                        {
                            noData === true ?
                                <span>Data tidak ditemukan</span>
                                :
                                noData !== true ?
                                    listTugas()
                                    : null
                        }
                    </div>
                </div>
            </form>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, penilaianTugasPelajar: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionUpdatePenilaianTugasPelajar, actionResetFormPenilaianTugasPelajar })(PenilaianTugasPelajarUpdate))