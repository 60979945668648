import Immutable from 'seamless-immutable'

const FETCH_VIDEO = 'FETCH_VIDEO'
const FETCH_VIDEO_START = 'FETCH_VIDEO_START'
const FETCH_VIDEO_END = 'FETCH_VIDEO_END'
const FETCH_VIDEO_SUCCESS = 'FETCH_VIDEO_SUCCESS'
const FETCH_VIDEO_FAILED = 'FETCH_VIDEO_FAILED'

const CREATE_VIDEO = 'CREATE_VIDEO'
const CREATE_VIDEO_START = 'CREATE_VIDEO_START'
const CREATE_VIDEO_END = 'CREATE_VIDEO_END'
const CREATE_VIDEO_SUCCESS = 'CREATE_VIDEO_SUCCESS'
const CREATE_VIDEO_FAILED = 'CREATE_VIDEO_FAILED'

const UPDATE_VIDEO = 'UPDATE_VIDEO'
const UPDATE_VIDEO_START = 'UPDATE_VIDEO_START'
const UPDATE_VIDEO_END = 'UPDATE_VIDEO_END'
const UPDATE_VIDEO_SUCCESS = 'UPDATE_VIDEO_SUCCESS'
const UPDATE_VIDEO_FAILED = 'UPDATE_VIDEO_FAILED'

const DELETE_VIDEO = 'DELETE_VIDEO'
const DELETE_VIDEO_START = 'DELETE_VIDEO_START'
const DELETE_VIDEO_END = 'DELETE_VIDEO_END'
const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS'
const DELETE_VIDEO_FAILED = 'DELETE_VIDEO_FAILED'

const RESET_FORM_VIDEO = 'RESET_FORM_VIDEO'
const RESET_DELETE_VIDEO = 'RESET_DELETE_VIDEO'

const actionTypes = Immutable({
  FETCH_VIDEO,
  FETCH_VIDEO_START,
  FETCH_VIDEO_END,
  FETCH_VIDEO_SUCCESS,
  FETCH_VIDEO_FAILED,

  CREATE_VIDEO,
  CREATE_VIDEO_START,
  CREATE_VIDEO_END,
  CREATE_VIDEO_SUCCESS,
  CREATE_VIDEO_FAILED,

  UPDATE_VIDEO,
  UPDATE_VIDEO_START,
  UPDATE_VIDEO_END,
  UPDATE_VIDEO_SUCCESS,
  UPDATE_VIDEO_FAILED,

  DELETE_VIDEO,
  DELETE_VIDEO_START,
  DELETE_VIDEO_END,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAILED,

  RESET_FORM_VIDEO,
  RESET_DELETE_VIDEO
})

export default actionTypes
