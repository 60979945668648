import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionFetchLaporanQuizPelajar } from "../../store/laporan-quiz-pelajar/actions"
import Pagination from "../../components/Table/Pagination"
import { changeUrl } from "../../utils/url"
import { alert, confirm } from "../../utils/alert"
import LimitTable from "../../components/Table/LimitTable"
import SearchTable from "../../components/Table/SearchTable"
import HeadTable from "../../components/Table/HeadTable"

const LaporanQuizAwalPelajarIndex = (props) => {
    const { actionFetchLaporanQuizPelajar, accessToken, index, del } = props
    const [sort, setSort] = useState({
        by: null,
        type: null
    })
    const [search, setSearch] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState("")
    const [submit, setSubmit] = useState(false)

    useEffect(() => {
        const titlePage = "Laporan Quiz Pelajar"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)
        fetchData()
    }, [])

    const fetchData = () => {
        const query = new URLSearchParams(props.location.search)

        if (query.get('page')) {
            setPage(parseInt(query.get('page')))
        } else {
            setPage(1)
        }

        if (query.get('search')) {
            setSearch(query.get('search'))
            setSearchValue(query.get('search'))
        }

        if (query.get('perPage')) {
            setPerPage(parseInt(query.get('perPage')))
        } else {
            setPerPage(10)
        }

        if (query.get('sortBy') || query.get('sortType')) {
            setSort({
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            })
        }

        actionFetchLaporanQuizPelajar({
            search: query.get('search') || "",
            page: parseInt(query.get('page')) || 1,
            sort: {
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            },
            perPage: parseInt(query.get('perPage')) || 10,
            accessToken
        })
    }

    const classStatus = (status) => {
        if (status === "Aktif") {
            return "bg-label-success"
        } else if (status === "Tidak Aktif") {
            return "bg-label-danger"
        }

        return "bg-label-secondary"
    }

    const sortData = (field, e) => {
        let sortType = sort?.type === "desc" ? "asc" : "desc"
        setSort({
            by: field,
            type: sortType
        })
        actionFetchLaporanQuizPelajar({
            search,
            page,
            sort: {
                by: field,
                type: sortType
            },
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const changePerPage = (val) => {
        setPerPage(val)
        actionFetchLaporanQuizPelajar({
            search,
            page,
            sort,
            perPage: val,
            accessToken
        })
    }

    useEffect(() => {
        let queryParams = {}
        if (page) {
            queryParams['page'] = page
        }
        if (search) {
            queryParams['search'] = search
        }
        if (sort?.by) {
            queryParams['sortBy'] = sort?.by
        }
        if (sort?.type) {
            queryParams['sortType'] = sort?.type
        }
        if (perPage) {
            queryParams['perPage'] = perPage
        }

        let query = new URLSearchParams(queryParams).toString()

        changeUrl("laporan-quiz-pelajar?" + query)
    }, [page, search, sort?.by, sort?.type, perPage])

    const pageData = (val, e) => {
        setPage(val)
        actionFetchLaporanQuizPelajar({
            search,
            page: val,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const onSearch = (e) => {
        setSearch(searchValue)
        actionFetchLaporanQuizPelajar({
            search: searchValue,
            page,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const onChangeSearch = (val) => {
        setSearchValue(val)
    }

    useEffect(() => {
        if (submit === true) {
            if (del?.success === true) {
                actionFetchLaporanQuizPelajar({
                    search,
                    page,
                    sort,
                    perPage,
                    accessToken
                })
                alert({ title: "Berhasil hapus data" })
            } else if (del?.success === false) {
                alert({ title: "Gagal hapus data" })
            }
        }
    }, [del?.success, submit])

    useEffect(() => {
        if (page && index?.last_page) {
            if (page > index?.last_page) {
                setPage(index?.last_page)
                actionFetchLaporanQuizPelajar({
                    search,
                    page: index?.last_page,
                    sort,
                    perPage,
                    accessToken
                })
            } else if (page < 0) {
                setPage(1)
                actionFetchLaporanQuizPelajar({
                    search,
                    page: 1,
                    sort,
                    perPage,
                    accessToken
                })
            }
        }
    }, [page, index?.last_page])

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-8"> </div>
                <div className="col-lg-4">
                    <SearchTable onSearch={onSearch} onChange={onChangeSearch} value={searchValue} />
                </div>
            </div>

            {
                index?.total > 0 ?
                    <p className="mt-2">Menampilkan {index?.from} - {index?.to} dari {index?.total} data</p>
                    :
                    <p className="mt-2">Menampilkan 0 data</p>
            }

            <div className="card mt-3">
                <div className="table-responsive grid-view">
                    <table className="table table-bordered">
                        <HeadTable
                            sort={sort}
                            sortData={sortData}
                            columns={[
                                { type: "label", label: "No." },
                                { type: "label", label: "Nama Pelajar / Pertemuan", field: "name" },
                                { type: "label", label: "Modul", field: "nilai" },
                                { type: "label", label: "Kursus", field: "nilai" },
                                { type: "label", label: "Nilai Quiz", field: "nilai" },
                            ]}
                        />
                        <tbody className="table-border-bottom-0">
                            {
                                index?.list?.length > 0 ?
                                    index?.list?.map((val, key) => {
                                        return <React.Fragment>
                                            <tr key={key + "-" + 1}>
                                                <td rowSpan={(val?.quiz?.length > 0 ? val?.quiz?.length : 1) + 2}><strong>{((page - 1) * perPage) + key + 1}</strong></td>
                                                <td colSpan={6}><strong>{val?.name}</strong></td>
                                            </tr>
                                            <tr key={key + "-" + 2}>
                                                <td><small className="text-muted fw-semibold text-uppercase">Pertemuan</small></td>
                                                <td><small className="text-muted fw-semibold text-uppercase">Modul</small></td>
                                                <td><small className="text-muted fw-semibold text-uppercase">Kursus</small></td>
                                                <td><small className="text-muted fw-semibold text-uppercase">Nilai Quiz</small></td>
                                            </tr>
                                            {
                                                val?.quiz?.length > 0 ?
                                                    val?.quiz?.map((val2, key2) => {
                                                        return <tr key={key + "-" + 3 + "-" + key2}>
                                                            <td>{val2?.pertemuan?.nama}</td>
                                                            <td>{val2?.pertemuan?.modul?.nama}</td>
                                                            <td>{val2?.pertemuan?.modul?.kursus?.nama}</td>
                                                            <td>{val2?.nilai_akhir === null ? "-" : val2?.nilai_akhir}</td>
                                                        </tr>
                                                    })
                                                    : <tr>
                                                        <td colSpan={4}><span className="text-muted">Belum pernah ikut quiz</span></td>
                                                    </tr>
                                            }
                                        </React.Fragment>
                                    })
                                    : <tr>
                                        <td colSpan={4}>Data tidak ditemukan</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-lg-8">
                    <Pagination page={page} pageUrl={index?.pageUrl} onChange={pageData} last_page={index?.last_page} />
                </div>
                <div className="col-lg-4">
                    <LimitTable perPage={perPage} onChange={changePerPage} />
                </div>
            </div>
        </div>
    </React.Fragment >
}

const mapStateToProps = state => {
    const { auth: { accessToken }, laporanQuizPelajar: { index } } = state

    return { accessToken, index }
}

export default withRouter(connect(mapStateToProps, { actionFetchLaporanQuizPelajar })(LaporanQuizAwalPelajarIndex))