import React from "react"
import Dropzone from 'react-dropzone'
import './index.css'

const FileInput = ({ id = null, selectedFiles, name, error = null, errors = null, hint = null, placeholder = null, label = null, onDrop, required = false, tabIndex = null, accept = null }) => {
    return <React.Fragment>
        <div className="mb-3">
            <label htmlFor={id} className="form-label">
                {label}
                {required === true ? <span className="text-danger"> *</span> : null}
            </label>
            <Dropzone id={id} name={name} onDrop={onDrop} multiple={false} accept={accept} tabIndex={tabIndex}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: 'dropzone' })} style={error ? { border: '3px dashed #ff3e1d' } : null}>
                        <input {...getInputProps()} />
                        {selectedFiles && selectedFiles[0].name ? (
                            <div className="selected-file">
                                {selectedFiles && selectedFiles[0].name}
                            </div>
                        ) : (
                            placeholder
                        )}
                    </div>
                )}
            </Dropzone>
            {
                hint ? <div className="form-text">{hint}</div> : null
            }
            {
                error ? <div className="invalid-feedback d-block">{error}</div> : null
            }
            {
                errors?.map((val, key) => {
                    return <div key={key} className="invalid-feedback d-block">{val}</div>
                })
            }
        </div>
    </React.Fragment >
}

export default FileInput