import actionTypes from './actionTypes'

export const actionFetchLaporan = (data) => {
  return {
    type: actionTypes.FETCH_LAPORAN,
    data
  }
}

export const actionFetchLaporanStart = () => {
  return {
    type: actionTypes.FETCH_LAPORAN_START
  }
}

export const actionFetchLaporanEnd = () => {
  return {
    type: actionTypes.FETCH_LAPORAN_END
  }
}

export const actionFetchLaporanSuccess = (data) => {
  return {
    type: actionTypes.FETCH_LAPORAN_SUCCESS,
    data
  }
}

export const actionCreateLaporan = (data) => {
  return {
    type: actionTypes.CREATE_LAPORAN,
    data
  }
}

export const actionCreateLaporanStart = () => {
  return {
    type: actionTypes.CREATE_LAPORAN_START
  }
}

export const actionCreateLaporanEnd = () => {
  return {
    type: actionTypes.CREATE_LAPORAN_END
  }
}

export const actionCreateLaporanSuccess = (data) => {
  return {
    type: actionTypes.CREATE_LAPORAN_SUCCESS,
    data
  }
}

export const actionCreateLaporanFailed = (data) => {
  return {
    type: actionTypes.CREATE_LAPORAN_FAILED,
    data
  }
}

export const actionUpdateLaporan = (data) => {
  return {
    type: actionTypes.UPDATE_LAPORAN,
    data
  }
}

export const actionUpdateLaporanStart = () => {
  return {
    type: actionTypes.UPDATE_LAPORAN_START
  }
}

export const actionUpdateLaporanEnd = () => {
  return {
    type: actionTypes.UPDATE_LAPORAN_END
  }
}

export const actionUpdateLaporanSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_LAPORAN_SUCCESS,
    data
  }
}

export const actionUpdateLaporanFailed = (data) => {
  return {
    type: actionTypes.UPDATE_LAPORAN_FAILED,
    data
  }
}

export const actionResetFormLaporan = () => {
  return {
    type: actionTypes.RESET_FORM_LAPORAN
  }
}

export const actionDeleteLaporan = (data) => {
  return {
    type: actionTypes.DELETE_LAPORAN,
    data
  }
}

export const actionDeleteLaporanStart = () => {
  return {
    type: actionTypes.DELETE_LAPORAN_START
  }
}

export const actionDeleteLaporanEnd = () => {
  return {
    type: actionTypes.DELETE_LAPORAN_END
  }
}

export const actionDeleteLaporanSuccess = () => {
  return {
    type: actionTypes.DELETE_LAPORAN_SUCCESS
  }
}

export const actionDeleteLaporanFailed = () => {
  return {
    type: actionTypes.DELETE_LAPORAN_FAILED
  }
}

export const actionResetDeleteLaporan = () => {
  return {
    type: actionTypes.RESET_DELETE_LAPORAN
  }
}