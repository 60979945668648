import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionDeleteModul, actionResetDeleteModul } from "../../store/modul/actions"
import { request } from "../../utils/request"
import { alert, confirm } from "../../utils/alert"
import { changeUrl, pushUrl } from "../../utils/url"
import HeadTable from "../../components/Table/HeadTable"
import SearchTable from "../../components/Table/SearchTable"
import Pagination from "../../components/Table/Pagination"
import LimitTable from "../../components/Table/LimitTable"
import Modal from "../../components/Modal"
import { default as CreatePertemuan } from "../Pertemuan/create"
import { default as DetailPertemuan } from "../Pertemuan/detail"
import { default as UpdateModul } from "../Modul/update"

const ModulDetail = (props) => {
    const { actionDeleteModul, actionResetDeleteModul, accessToken, match: { params: { id: _id } }, history: { location: { state } }, del, directCall = true, modulId = null, onClickBack = null, fromUrl = "" } = props
    const [data, setData] = useState(null)
    const [noData, setNoData] = useState(false)
    const [submit, setSubmit] = useState(false)

    const [index, setIndex] = useState(null)
    const [sort, setSort] = useState({
        by: null,
        type: null
    })
    const [search, setSearch] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState("")

    const [id, setId] = useState()
    const [successCreatePertemuan, setSuccessCreatePertemuan] = useState(null)

    const [pertemuanId, setPertemuanId] = useState("")

    const [successUpdateModul, setSuccessUpdateModul] = useState(null)

    useEffect(() => {
        // const titlePage = "Detail Modul"
        // changeTitle(titlePage)
        // changeBreadcrumbs(titlePage)

        if (directCall === true) {
            setId(_id)
            if (state) {
                setData(state)
            } else {
                getData()
            }
        } else {
            setId(modulId)
            getData(modulId)
        }

        actionResetDeleteModul()

        return () => actionResetDeleteModul()
    }, [])

    useEffect(() => {
        if (successUpdateModul === true) {
            getData()
            setSuccessUpdateModul(null)
        }
    }, [successUpdateModul])

    useEffect(() => {
        if (id) {
            getLovPertemuan()
            getPertemuan()
        }
    }, [id])

    const getPertemuan = () => {
        const query = new URLSearchParams(props.location.search)

        if (query.get('pertemuanId')) {
            setPertemuanId(query.get('pertemuanId'))
        }
    }

    const getLovPertemuan = () => {
        const query = new URLSearchParams(props.location.search)

        if (query.get('pagePertemuan')) {
            setPage(parseInt(query.get('pagePertemuan')))
        } else {
            setPage(1)
        }

        if (query.get('searchPertemuan')) {
            setSearch(query.get('searchPertemuan'))
            setSearchValue(query.get('searchPertemuan'))
        }

        if (query.get('perPagePertemuan')) {
            setPerPage(parseInt(query.get('perPagePertemuan')))
        } else {
            if (directCall === true) {
                setPerPage(10)
            } else {
                setPerPage(5)
            }
        }

        if (query.get('sortByPertemuan') || query.get('sortTypePertemuan')) {
            setSort({
                by: query.get('sortByPertemuan') || null,
                type: query.get('sortTypePertemuan') || null,
            })
        }

        if (!query.get('pertemuanId')) {
            // console.log("actionFetchPertemuan getLovPertemuan modul")
            actionFetchPertemuan({
                search: query.get('searchPertemuan') || "",
                page: parseInt(query.get('pagePertemuan')) || 1,
                sort: {
                    by: query.get('sortByPertemuan') || null,
                    type: query.get('sortTypePertemuan') || null,
                },
                perPage: parseInt(query.get('perPagePertemuan')) || (directCall === true ? 10 : 5),
                accessToken
            })
        }
    }

    const actionFetchPertemuan = async (params) => {
        // console.log("actionFetchPertemuan modul")
        const { accessToken, search, page, sort, perPage } = params

        const header = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
        }

        try {
            let sortParam = ""
            if (sort?.by) {
                sortParam += "&sortBy=" + sort?.by
            }
            if (sort?.type) {
                sortParam += "&sortType=" + sort?.type
            }

            let perPageParam = perPage ? "&perPage=" + perPage : ""

            let pageParam = page ? "?page=" + page : "?page=1"

            let searchParam = search ? "&search=" + search : ""

            let modul_id = "&modul_id=" + id

            let url = `${process.env.REACT_APP_SERVICE_PERTEMUAN}${pageParam}${searchParam}${sortParam}${perPageParam}${modul_id}`
            const res = await request(url, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                let data = {
                    list: res?.result?.data,
                    page: res?.result?.current_page,
                    pageUrl: {
                        first_page_url: res?.result?.first_page_url,
                        links: res?.result?.links,
                        last_page_url: res?.result?.last_page_url
                    },
                    last_page: res?.result?.last_page,
                    from: res?.result?.from,
                    to: res?.result?.to,
                    total: res?.result?.total
                }

                setIndex(data)
            } else {
                alert({ title: "Gagal", html: res?.message })
            }
        } catch (err) {
            alert({ title: "Gagal" })
        }
    }

    useEffect(() => {
        if (id) {
            let queryParams = {}
            if (page) {
                queryParams['pagePertemuan'] = page
            }
            if (search) {
                queryParams['searchPertemuan'] = search
            }
            if (sort?.by) {
                queryParams['sortByPertemuan'] = sort?.by
            }
            if (sort?.type) {
                queryParams['sortTypePertemuan'] = sort?.type
            }
            if (perPage) {
                queryParams['perPagePertemuan'] = perPage
            }
            if (pertemuanId) {
                queryParams['pertemuanId'] = pertemuanId
            }

            let query = new URLSearchParams(queryParams).toString()

            directCall === true ? changeUrl("/modul/detail/" + id + "?" + query) : changeUrl((fromUrl || window.location.href) + "&" + query)

        }
    }, [page, search, sort?.by, sort?.type, perPage, id, pertemuanId])

    useEffect(() => {
        if (submit === true) {
            if (del?.success === true) {
                alert({ title: "Berhasil hapus data" })

                if (directCall === true) {
                    pushUrl("/modul")
                } else {
                    onClickBack()
                }
            } else if (del?.success === false) {
                alert({ title: "Gagal hapus data" })
            }
        }
    }, [del?.success, submit])

    const getData = async (modulId = null) => {
        // console.log("getData modul")
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            let _id = id
            if (modulId) {
                _id = modulId
            }
            const res = await request(process.env.REACT_APP_SERVICE_MODUL + '/' + _id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setData(res?.result)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const classStatus = (status) => {
        if (status === "Aktif") {
            return "bg-label-success"
        } else if (status === "Tidak Aktif") {
            return "bg-label-danger"
        }

        return "bg-label-secondary"
    }

    const deleteData = async (e) => {
        e.preventDefault()

        let res = await confirm({
            title: "Hapus modul",
            html: "Apakah anda yakin menghapus data ini?"
        })
        if (res === true) {
            setSubmit(true)
            actionDeleteModul({ accessToken, id })
        }
    }

    const onSearch = (e) => {
        setSearch(searchValue)
        actionFetchPertemuan({
            search: searchValue,
            page,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const onChangeSearch = (val) => {
        setSearchValue(val)
    }

    const pageData = (val, e) => {
        setPage(val)
        actionFetchPertemuan({
            search,
            page: val,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const changePerPage = (val) => {
        setPerPage(val)
        actionFetchPertemuan({
            search,
            page,
            sort,
            perPage: val,
            accessToken
        })
    }

    useEffect(() => {
        if (successCreatePertemuan === true) {
            // console.log("actionFetchPertemuan getLovPertemuan successCreatePertemuan modul")
            getLovPertemuan()
        }
    }, [successCreatePertemuan])

    const dataView = () => {
        return <React.Fragment>
            {
                noData === true ?
                    <span>Data tidak ditemukan</span>
                    :
                    <React.Fragment>
                        {/* <small className="text-muted text-uppercase">Modul</small> */}
                        <div className="table-responsive text-nowrap detail-view mt-2">
                            <table className="table table-bordered">
                                <tbody className="table-border-bottom-0">
                                    <tr>
                                        <th style={{ width: "25%" }}>
                                            <strong>Nama Kursus</strong>
                                        </th>
                                        <td>{data?.kursus?.nama}</td>
                                    </tr>
                                    <tr>
                                        <th style={{ width: "25%" }}>
                                            <strong>Nama Modul</strong>
                                        </th>
                                        <td>{data?.nama}</td>
                                    </tr>
                                    <tr>
                                        <th style={{ width: "25%" }}>
                                            <strong>Keterangan</strong>
                                        </th>
                                        <td><span dangerouslySetInnerHTML={{ __html: data?.keterangan }}></span></td>
                                    </tr>
                                    <tr>
                                        <th style={{ width: "25%" }}>
                                            <strong>Jenis Urutan Pertemuan</strong>
                                        </th>
                                        <td>{data?.jenis_urutan}</td>
                                    </tr>
                                    <tr>
                                        <th style={{ width: "25%" }}>
                                            <strong>Status</strong>
                                        </th>
                                        <td><span className={"badge me-1 " + classStatus(data?.status)}>{data?.status}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
            }
        </React.Fragment>
    }

    const onClickPertemuan = (e, id) => {
        setPertemuanId(id)
        e.preventDefault()
    }

    const onClickBackPertemuan = () => {
        setPertemuanId("")
        actionFetchPertemuan({
            search,
            page,
            sort,
            perPage,
            accessToken
        })
    }

    const singlePertemuanView = () => {
        return <React.Fragment>
            <DetailPertemuan directCall={false} pertemuanId={pertemuanId} onClickBack={onClickBackPertemuan} />
        </React.Fragment>
    }

    const lovPertemuanView = () => {
        return <React.Fragment>
            {
                noData !== true ?
                    <React.Fragment>
                        <div className="mt-4">
                            <small className="text-muted text-uppercase">Pertemuan</small>
                        </div>
                        <div className="card mt-2">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <a href="#" type="button" data-bs-toggle="modal" data-bs-target="#modalCreateModulPertemuan" className="btn btn-primary mb-2 btn-md-block">Tambah Pertemuan</a>
                                    </div>
                                    <div className="col-lg-4 text-end">
                                        <SearchTable onSearch={onSearch} onChange={onChangeSearch} value={searchValue} />
                                    </div>
                                </div>
                                <div className="table-responsive grid-view mt-2">
                                    <table className="table table-bordered">
                                        <HeadTable
                                            colorized={false}
                                            columns={[
                                                { type: "label", label: "No." },
                                                { type: "label", label: "Nama" },
                                                { type: "label", label: "Jobsheet" },
                                                { type: "label", label: "Gambar" },
                                                { type: "label", label: "Video" },
                                                { type: "label", label: "Quiz" },
                                                { type: "label", label: "Tugas" },
                                                { type: "label", label: "" }
                                            ]}
                                        />
                                        <tbody className="table-border-bottom-0">
                                            {
                                                index?.list?.length > 0 ?
                                                    index?.list?.map((val, key) => {
                                                        return <tr key={key}>
                                                            <td><strong>{((page - 1) * perPage) + key + 1}</strong></td>
                                                            <td>{val?.nama}</td>
                                                            <td>{val?.jumlah_jobsheet || 0}</td>
                                                            <td>{val?.jumlah_gambar || 0}</td>
                                                            <td>{val?.jumlah_video || 0}</td>
                                                            <td>{val?.jumlah_quiz || 0}</td>
                                                            <td>{val?.jumlah_laporan || 0}</td>
                                                            <td style={{ width: 1 }} className="text-end">
                                                                {
                                                                    directCall === true ?
                                                                        <Link to={{
                                                                            pathname: process.env.REACT_APP_SUBDIR + "/pertemuan/detail/" + val?.id,
                                                                            state: val
                                                                        }} className="btn btn-info btn-sm">Detail</Link>
                                                                        :
                                                                        <button type="button" className="btn btn-info btn-sm" onClick={(e) => onClickPertemuan(e, val?.id)}>Detail</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    })
                                                    : <tr>
                                                        <td colSpan={8}>Data tidak ditemukan</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-lg-8">
                                        <Pagination page={page} pageUrl={index?.pageUrl} onChange={pageData} last_page={index?.last_page} />
                                    </div>
                                    <div className="col-lg-4">
                                        <LimitTable perPage={perPage} onChange={changePerPage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    : null
            }
        </React.Fragment>
    }

    return <React.Fragment>
        <Modal id={"modalCreateModulPertemuan"} title={"Tambah Pertemuan"} button={{ visible: true, label: "Kirim" }} size={"modal-lg"} buttons={false}>
            {
                id && successUpdateModul == null ? <CreatePertemuan fromModal={true} modulId={id} callbackSuccessModal={setSuccessCreatePertemuan} /> : null
            }
        </Modal>

        {
            directCall === true ?
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row">
                        <div className="col-lg-4">
                            <Link to={process.env.REACT_APP_SUBDIR + "/modul"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>
                        </div>
                        <div className="col-lg-8 text-end">
                            <Link to={process.env.REACT_APP_SUBDIR + "/modul/update/" + data?.id + "?from=detail"} className="btn btn-success mb-2 btn-md-block">Ubah</Link>
                            <a href="/#" onClick={(e) => deleteData(e)} className={"btn btn-danger mb-2 btn-md-block " + (del?.loading === true ? "disabled" : "")}>Hapus {del?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}</a>
                        </div>
                    </div>

                    <div className="mt-2">
                        <small className="text-muted text-uppercase">Modul</small>
                        <div className="card mt-2">
                            <div className="card-body">
                                {dataView()}
                            </div>
                        </div>
                    </div>

                    {
                        pertemuanId ?
                            singlePertemuanView()
                            :
                            lovPertemuanView()
                    }
                </div>
                : <React.Fragment>
                    <Modal id={"modalUpdateKursusModul"} title={"Ubah Modul"} button={{ visible: true, label: "Kirim" }} size={"modal-lg"} buttons={false}>
                        {
                            id ?
                                <UpdateModul fromModal={true} modulId={id} callbackSuccessModal={setSuccessUpdateModul} />
                                : null
                        }
                    </Modal>

                    <div className="card mt-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <button type="button" className="btn btn-white mb-2 btn-md-block" onClick={onClickBack}>Kembali ke List</button>
                                </div>
                                <div className="col-md-8 text-end">
                                    <a href="#" type="button" data-bs-toggle="modal" data-bs-target="#modalUpdateKursusModul" className="btn btn-success  mb-2">Ubah</a>
                                    <a href="/#" onClick={(e) => deleteData(e)} className={"btn btn-danger mb-2 btn-md-block " + (del?.loading === true ? "disabled" : "")}>Hapus Modul {del?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}</a>
                                </div>
                            </div>
                            {dataView()}
                        </div>
                    </div>

                    {
                        pertemuanId ?
                            singlePertemuanView()
                            :
                            lovPertemuanView()
                    }
                </React.Fragment>
        }
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, modul: { del, index } } = state

    return { accessToken, del, index }
}

export default withRouter(connect(mapStateToProps, { actionDeleteModul, actionResetDeleteModul })(ModulDetail))