export const closeModal = () => {
    let btnCloseModals = document.getElementsByClassName("btn-close")

    if (btnCloseModals?.length > 0) {
        Array.prototype.forEach.call(btnCloseModals, btnCloseModal => {
            btnCloseModal.click()
        })
    }

    return
}