import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionCreatePertemuan, actionResetFormPertemuan } from "../../store/pertemuan/actions"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import Dropdown from "../../components/Form/Dropdown"
import { request } from "../../utils/request"
import NumberInput from "../../components/Form/NumberInput"
import TextInput from "../../components/Form/TextInput"
import TextArea from "../../components/Form/TextArea"
import SelectSearch from "../../components/Form/SelectSearch"
import { closeModal } from "../../utils/modal"
import TextEditor from "../../components/Form/TextEditor"

const initialPayload = {
    nama: "",
    modul_id: "",
    keterangan: "",
    status: ""
}

const PertemuanCreate = (props) => {
    const { actionCreatePertemuan, actionResetFormPertemuan, accessToken, form, fromModal = false, modulId = null, callbackSuccessModal = null } = props

    const [payload, setPayload] = useState(initialPayload)
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)
    const [optModul, setOptModul] = useState([])

    useEffect(() => {
        // const titlePage = "Tambah Pertemuan"
        // changeTitle(titlePage)
        // changeBreadcrumbs(titlePage)

        actionResetFormPertemuan()

        getModulOpt()

        return () => actionResetFormPertemuan()
    }, [])

    useEffect(() => {
        if (fromModal === true) {
            // console.log("fromModal")
            if (modulId != null) {
                // console.log(modulId)
                let _payload = {
                    ...payload
                }
                _payload["modul_id"] = modulId
                setPayload(_payload)

            }
        }
    }, [modulId])

    const getModulOpt = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_MODUL + '/all', {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setOptModul(res?.result)
            }
        } catch (error) {
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    useEffect(async () => {
        if (fromModal === true) {
            callbackSuccessModal(null)
        }

        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                if (fromModal !== true) {
                    pushUrl("/pertemuan")
                } else {
                    callbackSuccessModal(true)
                    let _payload = {
                        ...initialPayload
                    }
                    _payload["modul_id"] = modulId
                    setPayload(_payload)
                    closeModal()
                }
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const onChange = ((field, value) => {
        payload[field] = value

        setPayload({
            ...payload,
        })
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            actionCreatePertemuan({
                payload,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.nama || payload?.nama === "") {
            hasError = true
            jsonErrors["nama"] = "Nama pertemuan harus diisi"
        }
        if (!payload?.modul_id || payload?.modul_id === "") {
            hasError = true
            jsonErrors["modul_id"] = "Modul harus diisi"
        }
        if (!payload?.keterangan || payload?.keterangan === "") {
            hasError = true
            jsonErrors["keterangan"] = "Keterangan harus diisi"
        }
        if (!payload?.durasi_quiz || payload?.durasi_quiz === "") {
            hasError = true
            jsonErrors["durasi_quiz"] = "Durasi quiz harus diisi"
        }
        if (!payload?.status || payload?.status === "") {
            hasError = true
            jsonErrors["status"] = "Status harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    const formView = () => {
        return <React.Fragment>
            <form onSubmit={onSubmit} >
                <div className="row">
                    <div className="col-lg-12 col-xl-10 col-xxl-8">
                        <TextInput
                            id={"nama"}
                            name={"nama"}
                            placeholder={"Masukkan nama pertemuan"}
                            label={"Nama Pertemuan"}
                            onChange={(e) => onChange('nama', e?.target?.value)}
                            required={true}
                            value={payload?.nama}
                            error={errors?.nama}
                            errors={form?.errors?.nama}
                            tabIndex={1} />

                        <TextEditor
                            id={"keterangan"}
                            name={"keterangan"}
                            placeholder={"Masukkan keterangan"}
                            label={"Keterangan"}
                            onChange={(value) => onChange('keterangan', value)}
                            required={true}
                            value={payload?.keterangan}
                            error={errors?.keterangan}
                            errors={form?.errors?.keterangan}
                            tabIndex={2} />

                        <TextInput
                            id={"durasi_quiz"}
                            name={"durasi_quiz"}
                            placeholder={"Masukkan durasi quiz pertemuan"}
                            label={"Durasi Quiz (Menit)"}
                            onChange={(e) => onChange('durasi_quiz', e?.target?.value)}
                            required={true}
                            value={payload?.durasi_quiz}
                            error={errors?.durasi_quiz}
                            errors={form?.errors?.durasi_quiz}
                            tabIndex={3} />

                        <Dropdown
                            id={"status"}
                            name={"status"}
                            placeholder={"Pilih status"}
                            label={"Status"}
                            onChange={(e) => onChange('status', e?.target?.value)}
                            required={true}
                            value={payload?.status}
                            error={errors?.status}
                            errors={form?.errors?.status}
                            tabIndex={4}
                            options={[
                                { label: "Aktif", value: "Aktif" },
                                { label: "Tidak Aktif", value: "Tidak Aktif" }
                            ]} />

                        <button
                            tabIndex={4}
                            type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                            Kirim
                            {form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                        </button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    }

    return <React.Fragment>
        {
            fromModal !== true ?
                <div className="container-xxl flex-grow-1 container-p-y">
                    <Link to={process.env.REACT_APP_SUBDIR + "/pertemuan"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

                    <div className="card mt-3">
                        <div className="card-body">
                            {formView()}
                        </div>
                    </div>
                </div>
                : formView()
        }
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, pertemuan: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionCreatePertemuan, actionResetFormPertemuan })(PertemuanCreate))