import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionDeleteKursus, actionFetchKursus } from "../../store/kursus/actions"
import Pagination from "../../components/Table/Pagination"
import { changeUrl } from "../../utils/url"
import { alert, confirm } from "../../utils/alert"
import LimitTable from "../../components/Table/LimitTable"
import SearchTable from "../../components/Table/SearchTable"
import HeadTable from "../../components/Table/HeadTable"
import OuterSortTable from "../../components/Table/OuterSortTable"
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../constants"

const ProgressKursusIndex = (props) => {
    const { actionFetchKursus, actionDeleteKursus, accessToken, index, del } = props
    const [sort, setSort] = useState({
        by: null,
        type: null
    })
    const [search, setSearch] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState("")
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const titlePage = "Kursus"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)
        fetchData()
    }, [])

    const fetchData = () => {
        const query = new URLSearchParams(props.location.search)

        if (query.get('page')) {
            setPage(parseInt(query.get('page')))
        } else {
            setPage(1)
        }

        if (query.get('search')) {
            setSearch(query.get('search'))
            setSearchValue(query.get('search'))
        }

        if (query.get('perPage')) {
            setPerPage(parseInt(query.get('perPage')))
        } else {
            setPerPage(10)
        }

        if (query.get('sortBy') || query.get('sortType')) {
            setSort({
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            })
        }

        actionFetchKursus({
            search: query.get('search') || "",
            page: parseInt(query.get('page')) || 1,
            sort: {
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            },
            perPage: parseInt(query.get('perPage')) || 10,
            accessToken
        })
    }

    const changeSortBy = (val) => {
        let arr = val?.split("-")
        let field = arr[0]
        let sortType = arr[1]
        setSort({
            by: field,
            type: sortType
        })
        actionFetchKursus({
            search,
            page,
            sort: {
                by: field,
                type: sortType
            },
            perPage,
            accessToken
        })
    }

    const changePerPage = (val) => {
        setPerPage(val)
        actionFetchKursus({
            search,
            page,
            sort,
            perPage: val,
            accessToken
        })
    }

    useEffect(() => {
        let queryParams = {}
        if (page) {
            queryParams['page'] = page
        }
        if (search) {
            queryParams['search'] = search
        }
        if (sort?.by) {
            queryParams['sortBy'] = sort?.by
        }
        if (sort?.type) {
            queryParams['sortType'] = sort?.type
        }
        if (perPage) {
            queryParams['perPage'] = perPage
        }

        let query = new URLSearchParams(queryParams).toString()

        changeUrl("progress-kursus?" + query)
    }, [page, search, sort?.by, sort?.type, perPage])

    const pageData = (val, e) => {
        setPage(val)
        actionFetchKursus({
            search,
            page: val,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const onSearch = (e) => {
        setSearch(searchValue)
        actionFetchKursus({
            search: searchValue,
            page,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const onChangeSearch = (val) => {
        setSearchValue(val)
    }

    useEffect(() => {
        if (submit === true) {
            if (del?.success === true) {
                actionFetchKursus({
                    search,
                    page,
                    sort,
                    perPage,
                    accessToken
                })
                alert({ title: "Berhasil hapus data" })
            } else if (del?.success === false) {
                alert({ title: "Gagal hapus data" })
            }
        }
    }, [del?.success, submit])

    useEffect(() => {
        if (page && index?.last_page) {
            if (page > index?.last_page) {
                setPage(index?.last_page)
                actionFetchKursus({
                    search,
                    page: index?.last_page,
                    sort,
                    perPage,
                    accessToken
                })
            } else if (page < 0) {
                setPage(1)
                actionFetchKursus({
                    search,
                    page: 1,
                    sort,
                    perPage,
                    accessToken
                })
            }
        }
    }, [page, index?.last_page])

    useEffect(() => {
        setLoading(true)
    }, [index?.loading])

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-4 mb-3">
                    <OuterSortTable sortBy={sort?.by + "-" + sort?.type} onChange={changeSortBy} sortItem={[
                        { value: "created_at-desc", label: "Terbaru" },
                        { value: "created_at-asc", label: "Terlama" },
                        { value: "nama-asc", label: "Nama A-Z" },
                        { value: "nama-desc", label: "Nama Z-A" }
                    ]} />
                </div>
                <div className="col-lg-4"></div>
                <div className="col-lg-4">
                    <SearchTable onSearch={onSearch} onChange={onChangeSearch} value={searchValue} />
                </div>
            </div>


            {
                index?.total > 0 ?
                    <p className="mt-2">Menampilkan {index?.from} - {index?.to} dari {index?.total} data</p>
                    :
                    <p className="mt-2">Menampilkan 0 data</p>
            }

            {
                loading === true ?
                    index?.list?.length > 0 ?
                        index?.list?.map((val, key) => {
                            return <div key={key} className="card mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4 col-lg-3 col-xl-2">
                                        <img className="card-img custom" src={process.env.REACT_APP_SERVICE + '/uploads/kursus/logo/' + val?.logo} alt={val?.nama} />
                                    </div>
                                    <div className="col-md-8 col-lg-9 col-xl-10">
                                        <div className="card-body">
                                            <h5 className="card-title">{val?.nama}</h5>
                                            <span className="card-text" dangerouslySetInnerHTML={{ __html: val?.keterangan?.length > 500 ? val?.keterangan?.slice(0, 500) + "..." : val?.keterangan }}></span>
                                            <p className="card-text">
                                                <small className="text-muted">Terakhir diperbarui oleh {val?.created_by?.name || "-"}</small>
                                                {/* <br />
                                                <small className="text-muted">{val?.created_at}</small> */}
                                            </p>
                                            <Link to={{
                                                pathname: process.env.REACT_APP_SUBDIR + "/progress-kursus/detail/" + val?.id,
                                                state: val
                                            }} className="btn btn-info btn-sm">Detail</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                        : <div className="card mb-3">
                            <div className="card-body">
                                <span>Data tidak ditemukan</span>
                            </div>
                        </div>
                    : <p className="text-center mt-3">
                        <span className="spinner-border ms-2" role="status" aria-hidden="true" style={{ borderColor: PRIMARY_COLOR, borderRightColor: SECONDARY_COLOR, width: "1.5rem", height: "1.5rem" }}></span>
                    </p>
            }

            <div className="row mt-5">
                <div className="col-lg-8">
                    <Pagination page={page} pageUrl={index?.pageUrl} onChange={pageData} last_page={index?.last_page} />
                </div>
                <div className="col-lg-4">
                    <LimitTable perPage={perPage} onChange={changePerPage} max={25} />
                </div>
            </div>
        </div>
    </React.Fragment >
}

const mapStateToProps = state => {
    const { auth: { accessToken }, kursus: { index, del } } = state

    return { accessToken, index, del }
}

export default withRouter(connect(mapStateToProps, { actionFetchKursus, actionDeleteKursus })(ProgressKursusIndex))