import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { request } from "../../utils/request"
import { alert, confirm } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import HeadTable from "../../components/Table/HeadTable"
import DetailView from "../../components/DetailView"

const PenilaianTugasPelajarDetail = (props) => {
    const { accessToken, match: { params: { id } }, history: { location: { state } } } = props
    const [data, setData] = useState(null)
    const [noData, setNoData] = useState(false)

    useEffect(() => {
        const titlePage = "Detail Tugas Pelajar"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        getData()
    }, [])

    const getData = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_PENILAIAN_TUGAS_PELAJAR + '/' + id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setData(res?.result)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const classStatus = (status) => {
        if (status === "Sudah Dinilai") {
            return "bg-label-success"
        } else if (status === "Belum Dinilai") {
            return "bg-label-danger"
        }

        return "bg-label-secondary"
    }

    const listTugas = () => {
        return <React.Fragment>
            <div className="mt-3">
                <small className="text-muted text-uppercase">Tugas</small>
            </div>
            <div className="card mt-2">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <HeadTable
                                colorized={false}
                                columns={[
                                    { type: "label", label: "No." },
                                    { type: "label", label: "Tugas" },
                                    { type: "label", label: "File" },
                                    { type: "label", label: "Nilai" },
                                    { type: "label", label: "Hasil" }
                                ]}
                            />
                            <tbody className="table-border-bottom-0">
                                {
                                    data?.tugas?.length > 0 ?
                                        data?.tugas?.map((val, key) => {
                                            return <tr key={key}>
                                                <td><strong>{key + 1}</strong></td>
                                                <td>{val?.laporan?.nama || "-"}</td>
                                                <td>
                                                    <a href={process.env.REACT_APP_PELAJAR_SERVICE + '/uploads/laporan/' + val?.file} target="_blank">
                                                        Download
                                                    </a>
                                                </td>
                                                <td>{val?.nilai || "-"}</td>
                                                <td>{val?.hasil || "-"}</td>
                                            </tr>
                                        })
                                        : <tr>
                                            <td colSpan={5}>Data tidak ditemukan</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-sm-4">
                    <Link to={process.env.REACT_APP_SUBDIR + "/penilaian-tugas-pelajar"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>
                </div>
                <div className="col-sm-8 text-end">
                    {
                        data?.status_penilaian === "Belum Dinilai"
                            ?
                            <Link to={process.env.REACT_APP_SUBDIR + "/penilaian-tugas-pelajar/update/" + data?.id + "?from=detail"} className="btn btn-success mb-2 btn-md-block">Penilaian</Link>
                            : null
                    }
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">
                    {
                        noData === true ?
                            <span>Data tidak ditemukan</span>
                            :
                            <div className="table-responsive text-nowrap detail-view">
                                <table className="table table-bordered">
                                    <tbody className="table-border-bottom-0">
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Nama Pelajar</strong>
                                            </th>
                                            <td>{data?.pelajar?.user?.name}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Kelompok</strong>
                                            </th>
                                            <td>{data?.pelajar?.kelompok?.nama}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Pertemuan</strong>
                                            </th>
                                            <td>{data?.pertemuan?.nama}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Modul</strong>
                                            </th>
                                            <td>{data?.modul?.nama}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Kursus</strong>
                                            </th>
                                            <td>{data?.kursus?.nama}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Nilai</strong>
                                            </th>
                                            <td>{data?.nilai || "-"}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Hasil</strong>
                                            </th>
                                            <td>{data?.hasil || "-"}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Status</strong>
                                            </th>
                                            <td><span className={"badge me-1 " + classStatus(data?.status_penilaian)}>{data?.status_penilaian}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
            </div>

            {
                noData !== true ?
                    listTugas()
                    : null
            }
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken } } = state

    return { accessToken }
}

export default withRouter(connect(mapStateToProps)(PenilaianTugasPelajarDetail))