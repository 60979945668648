import actionTypes from './actionTypes'

export const actionFetchQuiz = (data) => {
  return {
    type: actionTypes.FETCH_QUIZ,
    data
  }
}

export const actionFetchQuizStart = () => {
  return {
    type: actionTypes.FETCH_QUIZ_START
  }
}

export const actionFetchQuizEnd = () => {
  return {
    type: actionTypes.FETCH_QUIZ_END
  }
}

export const actionFetchQuizSuccess = (data) => {
  return {
    type: actionTypes.FETCH_QUIZ_SUCCESS,
    data
  }
}

export const actionCreateQuiz = (data) => {
  return {
    type: actionTypes.CREATE_QUIZ,
    data
  }
}

export const actionCreateQuizStart = () => {
  return {
    type: actionTypes.CREATE_QUIZ_START
  }
}

export const actionCreateQuizEnd = () => {
  return {
    type: actionTypes.CREATE_QUIZ_END
  }
}

export const actionCreateQuizSuccess = (data) => {
  return {
    type: actionTypes.CREATE_QUIZ_SUCCESS,
    data
  }
}

export const actionCreateQuizFailed = (data) => {
  return {
    type: actionTypes.CREATE_QUIZ_FAILED,
    data
  }
}

export const actionUpdateQuiz = (data) => {
  return {
    type: actionTypes.UPDATE_QUIZ,
    data
  }
}

export const actionUpdateQuizStart = () => {
  return {
    type: actionTypes.UPDATE_QUIZ_START
  }
}

export const actionUpdateQuizEnd = () => {
  return {
    type: actionTypes.UPDATE_QUIZ_END
  }
}

export const actionUpdateQuizSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_QUIZ_SUCCESS,
    data
  }
}

export const actionUpdateQuizFailed = (data) => {
  return {
    type: actionTypes.UPDATE_QUIZ_FAILED,
    data
  }
}

export const actionResetFormQuiz = () => {
  return {
    type: actionTypes.RESET_FORM_QUIZ
  }
}

export const actionDeleteQuiz = (data) => {
  return {
    type: actionTypes.DELETE_QUIZ,
    data
  }
}

export const actionDeleteQuizStart = () => {
  return {
    type: actionTypes.DELETE_QUIZ_START
  }
}

export const actionDeleteQuizEnd = () => {
  return {
    type: actionTypes.DELETE_QUIZ_END
  }
}

export const actionDeleteQuizSuccess = () => {
  return {
    type: actionTypes.DELETE_QUIZ_SUCCESS
  }
}

export const actionDeleteQuizFailed = () => {
  return {
    type: actionTypes.DELETE_QUIZ_FAILED
  }
}

export const actionResetDeleteQuiz = () => {
  return {
    type: actionTypes.RESET_DELETE_QUIZ
  }
}