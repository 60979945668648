import { combineReducers } from "redux"
import { connectRouter } from 'connected-react-router'
import history from '../utils/history'

import auth from "./auth/reducer"
import notifikasi from "./notifikasi/reducer"

import pengajar from "./pengajar/reducer"
import pelajar from "./pelajar/reducer"
import kursus from "./kursus/reducer"
import modul from "./modul/reducer"
import pertemuan from "./pertemuan/reducer"
import jobsheet from "./jobsheet/reducer"
import gambar from "./gambar/reducer"
import video from "./video/reducer"
import laporan from "./laporan/reducer"
import quiz from "./quiz/reducer"
import quizAwal from "./quiz-awal/reducer"
import kelompok from "./kelompok/reducer"
import laporanTugasPelajar from "./laporan-tugas-pelajar/reducer"
import laporanQuizPelajar from "./laporan-quiz-pelajar/reducer"
import laporanQuizAwalPelajar from "./laporan-quiz-awal-pelajar/reducer"
import laporanCapaianPelajar from "./laporan-capaian-pelajar/reducer"
import penilaianTugasPelajar from "./penilaian-tugas-pelajar/reducer"

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth,
  notifikasi,
  
  pengajar,
  pelajar,
  kursus,
  modul,
  pertemuan,
  jobsheet,
  gambar,
  video,
  laporan,
  quiz,
  quizAwal,
  kelompok,

  laporanTugasPelajar,
  laporanQuizPelajar,
  laporanQuizAwalPelajar,
  laporanCapaianPelajar,
  penilaianTugasPelajar,
})

export default rootReducer
