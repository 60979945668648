import React, { useEffect, useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"

import './index.css'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../constants'

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

const options = {
    cMapUrl: 'cmaps/',
    standardFontDataUrl: 'standard_fonts/',
}

const PDFViewer = ({ url }) => {
    const [loading, setLoading] = useState(null)
    const [file, setFile] = useState(null)
    const [numPages, setNumPages] = useState(null)
    const [page, setPage] = useState(1)

    useEffect(() => {
        setLoading(true)
        setNumPages(null)
        setPage(1)
        setFile(url)
    }, [url])

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setLoading(false)
        setNumPages(nextNumPages)
    }

    const clickBack = (e) => {
        setPage(page - 1)
        e.preventDefault()
    }

    const clickNext = (e) => {
        setPage(page + 1)
        e.preventDefault()
    }

    const view = () => {
        return <React.Fragment>
            {
                numPages > 0 ?
                    <p className="text-center" style={{ zIndex: "99999", position: "relative" }}>
                        {
                            page <= 1 ? <a onClick={(e) => e.preventDefault()}>Sebelumnya</a> : <a href="#" onClick={(e) => clickBack(e)}>Sebelumnya</a>
                        }

                        <span className="mx-5"> Halaman {page} dari {numPages} </span>

                        {
                            page >= numPages ? <a onClick={(e) => e.preventDefault()}>Selanjutnya</a> : <a href="#" onClick={(e) => clickNext(e)}>Selanjutnya</a>
                        }
                    </p>
                    : null
            }
            <div className="pdf_viewer">
                <div className="pdf_viewer__container">
                    <div className="pdf_viewer__container__document">
                        <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                            {/* {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))} */}
                            <Page key={`page_${page}`} pageNumber={page} />
                        </Document>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    return <React.Fragment>
        {
            loading === true ?
                <p className="text-center mt-3">
                    <span className="spinner-border ms-2" role="status" aria-hidden="true" style={{ borderColor: PRIMARY_COLOR, borderRightColor: SECONDARY_COLOR, width: "1.5rem", height: "1.5rem" }}></span>
                </p>
                : null
        }

        {view()}
    </React.Fragment>
}

export default PDFViewer