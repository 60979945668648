import Immutable from 'seamless-immutable'

const FETCH_JOBSHEET = 'FETCH_JOBSHEET'
const FETCH_JOBSHEET_START = 'FETCH_JOBSHEET_START'
const FETCH_JOBSHEET_END = 'FETCH_JOBSHEET_END'
const FETCH_JOBSHEET_SUCCESS = 'FETCH_JOBSHEET_SUCCESS'
const FETCH_JOBSHEET_FAILED = 'FETCH_JOBSHEET_FAILED'

const CREATE_JOBSHEET = 'CREATE_JOBSHEET'
const CREATE_JOBSHEET_START = 'CREATE_JOBSHEET_START'
const CREATE_JOBSHEET_END = 'CREATE_JOBSHEET_END'
const CREATE_JOBSHEET_SUCCESS = 'CREATE_JOBSHEET_SUCCESS'
const CREATE_JOBSHEET_FAILED = 'CREATE_JOBSHEET_FAILED'

const UPDATE_JOBSHEET = 'UPDATE_JOBSHEET'
const UPDATE_JOBSHEET_START = 'UPDATE_JOBSHEET_START'
const UPDATE_JOBSHEET_END = 'UPDATE_JOBSHEET_END'
const UPDATE_JOBSHEET_SUCCESS = 'UPDATE_JOBSHEET_SUCCESS'
const UPDATE_JOBSHEET_FAILED = 'UPDATE_JOBSHEET_FAILED'

const DELETE_JOBSHEET = 'DELETE_JOBSHEET'
const DELETE_JOBSHEET_START = 'DELETE_JOBSHEET_START'
const DELETE_JOBSHEET_END = 'DELETE_JOBSHEET_END'
const DELETE_JOBSHEET_SUCCESS = 'DELETE_JOBSHEET_SUCCESS'
const DELETE_JOBSHEET_FAILED = 'DELETE_JOBSHEET_FAILED'

const RESET_FORM_JOBSHEET = 'RESET_FORM_JOBSHEET'
const RESET_DELETE_JOBSHEET = 'RESET_DELETE_JOBSHEET'

const actionTypes = Immutable({
  FETCH_JOBSHEET,
  FETCH_JOBSHEET_START,
  FETCH_JOBSHEET_END,
  FETCH_JOBSHEET_SUCCESS,
  FETCH_JOBSHEET_FAILED,

  CREATE_JOBSHEET,
  CREATE_JOBSHEET_START,
  CREATE_JOBSHEET_END,
  CREATE_JOBSHEET_SUCCESS,
  CREATE_JOBSHEET_FAILED,

  UPDATE_JOBSHEET,
  UPDATE_JOBSHEET_START,
  UPDATE_JOBSHEET_END,
  UPDATE_JOBSHEET_SUCCESS,
  UPDATE_JOBSHEET_FAILED,

  DELETE_JOBSHEET,
  DELETE_JOBSHEET_START,
  DELETE_JOBSHEET_END,
  DELETE_JOBSHEET_SUCCESS,
  DELETE_JOBSHEET_FAILED,

  RESET_FORM_JOBSHEET,
  RESET_DELETE_JOBSHEET
})

export default actionTypes
