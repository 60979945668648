import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import AuthLayout from "./components/Layout/AuthLayout"
import NonAuthLayout from "./components/Layout/NonAuthLayout"
import ErrorLayout from "./components/Layout/ErrorLayout"
import { useSelector } from 'react-redux'
import Page404 from './pages/Error/Page404'
import SignIn from './pages/SignIn'
import SignOut from './pages/SignOut'
import Beranda from './pages/Beranda'

import KursusIndex from './pages/Kursus/index'
import KursusCreate from './pages/Kursus/create'
import KursusDetail from './pages/Kursus/detail'
import KursusUpdate from './pages/Kursus/update'

import ModulIndex from './pages/Modul/index'
import ModulCreate from './pages/Modul/create'
import ModulDetail from './pages/Modul/detail'
import ModulUpdate from './pages/Modul/update'

import PertemuanIndex from './pages/Pertemuan/index'
import PertemuanCreate from './pages/Pertemuan/create'
import PertemuanDetail from './pages/Pertemuan/detail'
import PertemuanUpdate from './pages/Pertemuan/update'

import KelompokIndex from './pages/Kelompok/index'
import KelompokCreate from './pages/Kelompok/create'
import KelompokDetail from './pages/Kelompok/detail'
import KelompokUpdate from './pages/Kelompok/update'

import PenilaianTugasPelajarIndex from './pages/PenilaianTugasPelajar/index'
import PenilaianTugasPelajarDetail from './pages/PenilaianTugasPelajar/detail'
import PenilaianTugasPelajarUpdate from './pages/PenilaianTugasPelajar/update'

import LaporanTugasPelajarIndex from './pages/LaporanTugasPelajar/index'
import LaporanQuizAwalPelajarIndex from './pages/LaporanQuizAwalPelajar/index'
import LaporanQuizPelajarIndex from './pages/LaporanQuizPelajar/index'
import LaporanCapaianPelajarIndex from './pages/LaporanCapaianPelajar/index'

import ProgressKursusIndex from './pages/ProgressKursus/index'
import ProgressKursusDetail from './pages/ProgressKursus/detail'
import ProgressKursusPertemuanDetail from './pages/ProgressKursus/Pertemuan/detail'

const App = ({ childProps }) => {
  return (
    <Switch>
      <Route exact path={"/"} render={() => <Redirect to={process.env.REACT_APP_SUBDIR + '/beranda'} />} />
      <Route exact path={process.env.REACT_APP_SUBDIR + "/"} render={() => <Redirect to={process.env.REACT_APP_SUBDIR + '/beranda'} />} />

      <NonAuthRoute path={process.env.REACT_APP_SUBDIR + "/sign-in"} component={SignIn} props={childProps} />

      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/beranda"} component={Beranda} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/kursus"} component={KursusIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/kursus/create"} component={KursusCreate} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/kursus/detail/:id"} component={KursusDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/kursus/update/:id"} component={KursusUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/progress-kursus"} component={ProgressKursusIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/progress-kursus/detail/:id"} component={ProgressKursusDetail} props={childProps} exact />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/progress-kursus/detail/:id/pertemuan/:pertemuanId"} component={ProgressKursusPertemuanDetail} props={childProps} exact />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/modul"} component={ModulIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/modul/create"} component={ModulCreate} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/modul/detail/:id"} component={ModulDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/modul/update/:id"} component={ModulUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/pertemuan"} component={PertemuanIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/pertemuan/create"} component={PertemuanCreate} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/pertemuan/detail/:id"} component={PertemuanDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/pertemuan/update/:id"} component={PertemuanUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/kelompok"} component={KelompokIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/kelompok/create"} component={KelompokCreate} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/kelompok/detail/:id"} component={KelompokDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/kelompok/update/:id"} component={KelompokUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/penilaian-tugas-pelajar"} component={PenilaianTugasPelajarIndex} props={childProps} />
      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/penilaian-tugas-pelajar/detail/:id"} component={PenilaianTugasPelajarDetail} props={childProps} />
      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/penilaian-tugas-pelajar/update/:id"} component={PenilaianTugasPelajarUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/laporan-tugas-pelajar"} component={LaporanTugasPelajarIndex} props={childProps} />
      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/laporan-quiz-awal-pelajar"} component={LaporanQuizAwalPelajarIndex} props={childProps} />
      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/laporan-quiz-pelajar"} component={LaporanQuizPelajarIndex} props={childProps} />
      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/laporan-capaian-pelajar"} component={LaporanCapaianPelajarIndex} props={childProps} />

      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/sign-out"} component={SignOut} props={childProps} />

      <ErrorRoute path='*' exact={true} component={Page404} />
    </Switch>
  )
}

const ErrorRoute = ({ component: Component, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <ErrorLayout>
          <Component {...props} />
        </ErrorLayout>
      )}
    />
  )
}

const NonAuthRoute = ({ component: Component, location, ...rest }) => {
  const auth = useSelector(state => state.auth)
  let pass = false

  if (auth?.user) {
    const now = parseInt((new Date).getTime() / 1000)
    const sisaWaktu = auth?.expiredAt - now

    if (sisaWaktu > 0) {
      pass = true
    }
  }

  return (
    <Route
      {...rest}
      render={props => (
        pass === true ?
          // <NonAuthLayout>
          //   <Component {...props} />
          // </NonAuthLayout>
          <Redirect
            to={{
              pathname: process.env.REACT_APP_SUBDIR + '/beranda',
              state: { from: props.location }
            }}
          />
          :
          <NonAuthLayout>
            <Component {...props} />
          </NonAuthLayout>
      )}
    />
  )
}

const AuthRoute = ({ component: Component, location, ...rest }) => {
  const auth = useSelector(state => state.auth)
  let pass = false

  if (auth && auth.user) {
    pass = true
  }

  return (
    <Route
      {...rest}
      render={props => (
        pass === true ?
          <AuthLayout>
            <Component {...props} />
          </AuthLayout>
          :
          <Redirect
            to={{
              pathname: process.env.REACT_APP_SUBDIR + '/sign-in',
              state: { from: props.location }
            }} />
      )}
    />
  )
}

export default App