import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { dateTime, strtotime } from "../../utils/formatter"
import { actionUpdateLaporan, actionResetFormLaporan } from "../../store/laporan/actions"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import Dropdown from "../../components/Form/Dropdown"
import { request } from "../../utils/request"
import NumberInput from "../../components/Form/NumberInput"
import TextInput from "../../components/Form/TextInput"
import { closeModal } from "../../utils/modal"
import DatePicker from "../../components/Form/DatePicker"
import TextEditor from "../../components/Form/TextEditor"
import MultiSelect from "../../components/Form/MultiSelect"

let optJam = []
for (let i = 0; i < 24; i++) {
    optJam.push({
        label: (i < 10 ? "0" : "") + i.toString(),
        value: (i < 10 ? "0" : "") + i.toString()
    })
}

let optMenit = []
for (let i = 0; i < 60; i += 15) {
    optMenit.push({
        label: (i < 10 ? "0" : "") + i.toString(),
        value: (i < 10 ? "0" : "") + i.toString()
    })
}

const initialPayload = {
    nama: "",
    pertemuan_id: "",
    batas_kirim: "",
    batas_kirim_jam: "",
    batas_kirim_menit: "",
    keterangan: "",
    kelompok: [],
    status: ""
}

const LaporanUpdate = (props) => {
    const { actionUpdateLaporan, actionResetFormLaporan, accessToken, match: { params: { id: _id } }, history: { location: { state } }, form, fromModal = false, laporanId = null, callbackSuccessModal = null } = props

    const [id, setId] = useState()

    const [payload, setPayload] = useState(initialPayload)
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)
    const [noData, setNoData] = useState(false)
    const [from, setFrom] = useState("/laporan")

    const [optKelompok, setOptKelompok] = useState([])

    useEffect(() => {
        // const titlePage = "Ubah Laporan"
        // changeTitle(titlePage)
        // changeBreadcrumbs(titlePage)

        if (fromModal === false) {
            setId(_id)
            getData(_id)
        } else {
            setId(laporanId)
            getData(laporanId)
        }

        // const query = new URLSearchParams(props.location.search)
        // if (query.get('from') === "detail") {
        //     setFrom("/laporan/detail/" + id)
        // }

        actionResetFormLaporan()
        getKelompok()

        return () => actionResetFormLaporan()
    }, [laporanId, _id])

    const getKelompok = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_KELOMPOK + '/all', {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setOptKelompok(res?.result)
            }
        } catch (error) {
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const getData = async (_id = null) => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }

            if (_id === null) {
                _id = id
            }

            const res = await request(process.env.REACT_APP_SERVICE_LAPORAN + '/' + _id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                let _payload = {
                    ...res?.result,
                    batas_kirim: new Date(strtotime(res?.result?.batas_kirim)),
                    "keterangan": ""
                }
                setPayload(_payload)
                setTimeout(() => {
                    setPayload({
                        ..._payload,
                        "keterangan": res?.result?.keterangan
                    })
                }, 500)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    useEffect(async () => {
        if (fromModal === true) {
            callbackSuccessModal(null)
        }

        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                if (fromModal !== true) {
                    pushUrl(from)
                } else {
                    callbackSuccessModal(true)
                    closeModal()
                }
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const onChange = ((field, value) => {
        if (field === "kelompok") {
            let kelompok = []

            value?.map((val) => {
                kelompok.push(val?.value)
            })

            payload[field] = kelompok
        } else {
            payload[field] = value
        }

        setPayload({
            ...payload,
        })
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            actionUpdateLaporan({
                id,
                payload,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.nama || payload?.nama === "") {
            hasError = true
            jsonErrors["nama"] = "Nama tugas harus diisi"
        }
        if (!payload?.batas_kirim || payload?.batas_kirim === "") {
            hasError = true
            jsonErrors["batas_kirim"] = "Batas tanggal pengiriman harus diisi"
        }
        if (!payload?.batas_kirim_jam || payload?.batas_kirim_jam === "") {
            hasError = true
            jsonErrors["batas_kirim_jam"] = "Jam harus diisi"
        }
        if (!payload?.batas_kirim_menit || payload?.batas_kirim_menit === "") {
            hasError = true
            jsonErrors["batas_kirim_menit"] = "Menit harus diisi"
        }
        if (!payload?.keterangan || payload?.keterangan === "") {
            hasError = true
            jsonErrors["keterangan"] = "Keterangan harus diisi"
        }
        if (!payload?.kelompok || payload?.kelompok?.length < 1) {
            hasError = true
            jsonErrors["kelompok"] = "Kelompok harus diisi"
        }
        if (!payload?.status || payload?.status === "") {
            hasError = true
            jsonErrors["status"] = "Status harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    const formView = () => {
        return <React.Fragment>
            {
                noData === true ?
                    <span>Data tidak ditemukan</span>
                    :
                    <form onSubmit={onSubmit} >
                        <div className="row">
                            <div className="col-lg-12 col-xl-10 col-xxl-8">
                                <TextInput
                                    id={"nama"}
                                    name={"nama"}
                                    placeholder={"Masukkan nama tugas"}
                                    label={"Nama"}
                                    onChange={(e) => onChange('nama', e?.target?.value)}
                                    required={true}
                                    value={payload?.nama}
                                    error={errors?.nama}
                                    errors={form?.errors?.nama}
                                    tabIndex={1} />

                                <div className="row">
                                    <div className="col-md-6">
                                        <DatePicker
                                            id={"batas_kirim"}
                                            name={"batas_kirim"}
                                            placeholder={"Masukkan batas tanggal pengiriman"}
                                            label={"Batas Tanggal Pengiriman"}
                                            onChange={(val) => onChange('batas_kirim', val)}
                                            required={true}
                                            value={payload?.batas_kirim}
                                            error={errors?.batas_kirim}
                                            errors={form?.errors?.batas_kirim}
                                            tabIndex={2} />
                                    </div>

                                    <div className="col-md-3">
                                        <Dropdown
                                            id={"batas_kirim_jam"}
                                            name={"batas_kirim_jam"}
                                            placeholder={"Pilih jam"}
                                            label={"Jam"}
                                            onChange={(e) => onChange('batas_kirim_jam', e?.target?.value)}
                                            required={true}
                                            value={payload?.batas_kirim_jam}
                                            error={errors?.batas_kirim_jam}
                                            errors={form?.errors?.batas_kirim_jam}
                                            tabIndex={3}
                                            options={optJam} />
                                    </div>
                                    <div className="col-md-3">
                                        <Dropdown
                                            id={"batas_kirim_menit"}
                                            name={"batas_kirim_menit"}
                                            placeholder={"Pilih menit"}
                                            label={"Menit"}
                                            onChange={(e) => onChange('batas_kirim_menit', e?.target?.value)}
                                            required={true}
                                            value={payload?.batas_kirim_menit}
                                            error={errors?.batas_kirim_menit}
                                            errors={form?.errors?.batas_kirim_menit}
                                            tabIndex={4}
                                            options={optMenit} />
                                    </div>
                                </div>

                                <TextEditor
                                    id={"keterangan"}
                                    name={"keterangan"}
                                    placeholder={"Masukkan keterangan"}
                                    label={"Keterangan"}
                                    onChange={(value) => onChange('keterangan', value)}
                                    required={true}
                                    value={payload?.keterangan}
                                    error={errors?.keterangan}
                                    errors={form?.errors?.keterangan}
                                    tabIndex={5} />

                                <MultiSelect
                                    id={"kelompok"}
                                    name={"kelompok"}
                                    placeholder={"Pilih kelompok"}
                                    label={"Kelompok"}
                                    onChange={(e) => onChange('kelompok', e)}
                                    required={true}
                                    value={payload?.kelompok}
                                    error={errors?.kelompok}
                                    errors={form?.errors?.kelompok}
                                    tabIndex={6}
                                    options={optKelompok}
                                />

                                <Dropdown
                                    id={"status"}
                                    name={"status"}
                                    placeholder={"Pilih status"}
                                    label={"Status"}
                                    onChange={(e) => onChange('status', e?.target?.value)}
                                    required={true}
                                    value={payload?.status}
                                    error={errors?.status}
                                    errors={form?.errors?.status}
                                    tabIndex={7}
                                    options={[
                                        { label: "Aktif", value: "Aktif" },
                                        { label: "Tidak Aktif", value: "Tidak Aktif" }
                                    ]} />

                                <button
                                    tabIndex={8}
                                    type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                                    Kirim
                                    {form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                </button>
                            </div>
                        </div>
                    </form>
            }
        </React.Fragment>
    }

    return <React.Fragment>
        {
            fromModal !== true ?
                <div className="container-xxl flex-grow-1 container-p-y">
                    <Link to={process.env.REACT_APP_SUBDIR + from} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

                    <div className="card mt-3">
                        <div className="card-body">
                            {formView()}
                        </div>
                    </div>
                </div>
                : formView()
        }
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, laporan: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionUpdateLaporan, actionResetFormLaporan })(LaporanUpdate))