import actionTypes from './actionTypes'

export const actionFetchJobsheet = (data) => {
  return {
    type: actionTypes.FETCH_JOBSHEET,
    data
  }
}

export const actionFetchJobsheetStart = () => {
  return {
    type: actionTypes.FETCH_JOBSHEET_START
  }
}

export const actionFetchJobsheetEnd = () => {
  return {
    type: actionTypes.FETCH_JOBSHEET_END
  }
}

export const actionFetchJobsheetSuccess = (data) => {
  return {
    type: actionTypes.FETCH_JOBSHEET_SUCCESS,
    data
  }
}

export const actionCreateJobsheet = (data) => {
  return {
    type: actionTypes.CREATE_JOBSHEET,
    data
  }
}

export const actionCreateJobsheetStart = () => {
  return {
    type: actionTypes.CREATE_JOBSHEET_START
  }
}

export const actionCreateJobsheetEnd = () => {
  return {
    type: actionTypes.CREATE_JOBSHEET_END
  }
}

export const actionCreateJobsheetSuccess = (data) => {
  return {
    type: actionTypes.CREATE_JOBSHEET_SUCCESS,
    data
  }
}

export const actionCreateJobsheetFailed = (data) => {
  return {
    type: actionTypes.CREATE_JOBSHEET_FAILED,
    data
  }
}

export const actionUpdateJobsheet = (data) => {
  return {
    type: actionTypes.UPDATE_JOBSHEET,
    data
  }
}

export const actionUpdateJobsheetStart = () => {
  return {
    type: actionTypes.UPDATE_JOBSHEET_START
  }
}

export const actionUpdateJobsheetEnd = () => {
  return {
    type: actionTypes.UPDATE_JOBSHEET_END
  }
}

export const actionUpdateJobsheetSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_JOBSHEET_SUCCESS,
    data
  }
}

export const actionUpdateJobsheetFailed = (data) => {
  return {
    type: actionTypes.UPDATE_JOBSHEET_FAILED,
    data
  }
}

export const actionResetFormJobsheet = () => {
  return {
    type: actionTypes.RESET_FORM_JOBSHEET
  }
}

export const actionDeleteJobsheet = (data) => {
  return {
    type: actionTypes.DELETE_JOBSHEET,
    data
  }
}

export const actionDeleteJobsheetStart = () => {
  return {
    type: actionTypes.DELETE_JOBSHEET_START
  }
}

export const actionDeleteJobsheetEnd = () => {
  return {
    type: actionTypes.DELETE_JOBSHEET_END
  }
}

export const actionDeleteJobsheetSuccess = () => {
  return {
    type: actionTypes.DELETE_JOBSHEET_SUCCESS
  }
}

export const actionDeleteJobsheetFailed = () => {
  return {
    type: actionTypes.DELETE_JOBSHEET_FAILED
  }
}

export const actionResetDeleteJobsheet = () => {
  return {
    type: actionTypes.RESET_DELETE_JOBSHEET
  }
}