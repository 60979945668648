import Immutable from 'seamless-immutable'

const FETCH_GAMBAR = 'FETCH_GAMBAR'
const FETCH_GAMBAR_START = 'FETCH_GAMBAR_START'
const FETCH_GAMBAR_END = 'FETCH_GAMBAR_END'
const FETCH_GAMBAR_SUCCESS = 'FETCH_GAMBAR_SUCCESS'
const FETCH_GAMBAR_FAILED = 'FETCH_GAMBAR_FAILED'

const CREATE_GAMBAR = 'CREATE_GAMBAR'
const CREATE_GAMBAR_START = 'CREATE_GAMBAR_START'
const CREATE_GAMBAR_END = 'CREATE_GAMBAR_END'
const CREATE_GAMBAR_SUCCESS = 'CREATE_GAMBAR_SUCCESS'
const CREATE_GAMBAR_FAILED = 'CREATE_GAMBAR_FAILED'

const UPDATE_GAMBAR = 'UPDATE_GAMBAR'
const UPDATE_GAMBAR_START = 'UPDATE_GAMBAR_START'
const UPDATE_GAMBAR_END = 'UPDATE_GAMBAR_END'
const UPDATE_GAMBAR_SUCCESS = 'UPDATE_GAMBAR_SUCCESS'
const UPDATE_GAMBAR_FAILED = 'UPDATE_GAMBAR_FAILED'

const DELETE_GAMBAR = 'DELETE_GAMBAR'
const DELETE_GAMBAR_START = 'DELETE_GAMBAR_START'
const DELETE_GAMBAR_END = 'DELETE_GAMBAR_END'
const DELETE_GAMBAR_SUCCESS = 'DELETE_GAMBAR_SUCCESS'
const DELETE_GAMBAR_FAILED = 'DELETE_GAMBAR_FAILED'

const RESET_FORM_GAMBAR = 'RESET_FORM_GAMBAR'
const RESET_DELETE_GAMBAR = 'RESET_DELETE_GAMBAR'

const actionTypes = Immutable({
  FETCH_GAMBAR,
  FETCH_GAMBAR_START,
  FETCH_GAMBAR_END,
  FETCH_GAMBAR_SUCCESS,
  FETCH_GAMBAR_FAILED,

  CREATE_GAMBAR,
  CREATE_GAMBAR_START,
  CREATE_GAMBAR_END,
  CREATE_GAMBAR_SUCCESS,
  CREATE_GAMBAR_FAILED,

  UPDATE_GAMBAR,
  UPDATE_GAMBAR_START,
  UPDATE_GAMBAR_END,
  UPDATE_GAMBAR_SUCCESS,
  UPDATE_GAMBAR_FAILED,

  DELETE_GAMBAR,
  DELETE_GAMBAR_START,
  DELETE_GAMBAR_END,
  DELETE_GAMBAR_SUCCESS,
  DELETE_GAMBAR_FAILED,

  RESET_FORM_GAMBAR,
  RESET_DELETE_GAMBAR
})

export default actionTypes
