import Immutable from 'seamless-immutable'

const FETCH_LAPORAN = 'FETCH_LAPORAN'
const FETCH_LAPORAN_START = 'FETCH_LAPORAN_START'
const FETCH_LAPORAN_END = 'FETCH_LAPORAN_END'
const FETCH_LAPORAN_SUCCESS = 'FETCH_LAPORAN_SUCCESS'
const FETCH_LAPORAN_FAILED = 'FETCH_LAPORAN_FAILED'

const CREATE_LAPORAN = 'CREATE_LAPORAN'
const CREATE_LAPORAN_START = 'CREATE_LAPORAN_START'
const CREATE_LAPORAN_END = 'CREATE_LAPORAN_END'
const CREATE_LAPORAN_SUCCESS = 'CREATE_LAPORAN_SUCCESS'
const CREATE_LAPORAN_FAILED = 'CREATE_LAPORAN_FAILED'

const UPDATE_LAPORAN = 'UPDATE_LAPORAN'
const UPDATE_LAPORAN_START = 'UPDATE_LAPORAN_START'
const UPDATE_LAPORAN_END = 'UPDATE_LAPORAN_END'
const UPDATE_LAPORAN_SUCCESS = 'UPDATE_LAPORAN_SUCCESS'
const UPDATE_LAPORAN_FAILED = 'UPDATE_LAPORAN_FAILED'

const DELETE_LAPORAN = 'DELETE_LAPORAN'
const DELETE_LAPORAN_START = 'DELETE_LAPORAN_START'
const DELETE_LAPORAN_END = 'DELETE_LAPORAN_END'
const DELETE_LAPORAN_SUCCESS = 'DELETE_LAPORAN_SUCCESS'
const DELETE_LAPORAN_FAILED = 'DELETE_LAPORAN_FAILED'

const RESET_FORM_LAPORAN = 'RESET_FORM_LAPORAN'
const RESET_DELETE_LAPORAN = 'RESET_DELETE_LAPORAN'

const actionTypes = Immutable({
  FETCH_LAPORAN,
  FETCH_LAPORAN_START,
  FETCH_LAPORAN_END,
  FETCH_LAPORAN_SUCCESS,
  FETCH_LAPORAN_FAILED,

  CREATE_LAPORAN,
  CREATE_LAPORAN_START,
  CREATE_LAPORAN_END,
  CREATE_LAPORAN_SUCCESS,
  CREATE_LAPORAN_FAILED,

  UPDATE_LAPORAN,
  UPDATE_LAPORAN_START,
  UPDATE_LAPORAN_END,
  UPDATE_LAPORAN_SUCCESS,
  UPDATE_LAPORAN_FAILED,

  DELETE_LAPORAN,
  DELETE_LAPORAN_START,
  DELETE_LAPORAN_END,
  DELETE_LAPORAN_SUCCESS,
  DELETE_LAPORAN_FAILED,

  RESET_FORM_LAPORAN,
  RESET_DELETE_LAPORAN
})

export default actionTypes
