import Immutable from 'seamless-immutable'

const FETCH_LAPORAN_QUIZ_PELAJAR = 'FETCH_LAPORAN_QUIZ_PELAJAR'
const FETCH_LAPORAN_QUIZ_PELAJAR_START = 'FETCH_LAPORAN_QUIZ_PELAJAR_START'
const FETCH_LAPORAN_QUIZ_PELAJAR_END = 'FETCH_LAPORAN_QUIZ_PELAJAR_END'
const FETCH_LAPORAN_QUIZ_PELAJAR_SUCCESS = 'FETCH_LAPORAN_QUIZ_PELAJAR_SUCCESS'
const FETCH_LAPORAN_QUIZ_PELAJAR_FAILED = 'FETCH_LAPORAN_QUIZ_PELAJAR_FAILED'

const actionTypes = Immutable({
  FETCH_LAPORAN_QUIZ_PELAJAR,
  FETCH_LAPORAN_QUIZ_PELAJAR_START,
  FETCH_LAPORAN_QUIZ_PELAJAR_END,
  FETCH_LAPORAN_QUIZ_PELAJAR_SUCCESS,
  FETCH_LAPORAN_QUIZ_PELAJAR_FAILED
})

export default actionTypes
