import { takeEvery, put, all, call, select } from 'redux-saga/effects'
import actionTypes from './actionTypes'
import { request } from '../../utils/request'
import { alert } from '../../utils/alert'
import { actionUpdateQuizAwalEnd, actionUpdateQuizAwalFailed, actionUpdateQuizAwalStart, actionUpdateQuizAwalSuccess, actionCreateQuizAwalEnd, actionCreateQuizAwalFailed, actionCreateQuizAwalStart, actionCreateQuizAwalSuccess, actionFetchQuizAwalEnd, actionFetchQuizAwalStart, actionFetchQuizAwalSuccess, actionDeleteQuizAwalSuccess, actionDeleteQuizAwalFailed, actionDeleteQuizAwalStart, actionDeleteQuizAwalEnd } from './actions'
import { pushUrl } from '../../utils/url'

function* sagaFetchQuizAwal(params) {
  yield put(actionFetchQuizAwalStart())

  const { data: { accessToken, search, page, sort, perPage, pertemuanId } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let sortParam = ""
    if (sort?.by) {
      sortParam += "&sortBy=" + sort?.by
    }
    if (sort?.type) {
      sortParam += "&sortType=" + sort?.type
    }

    let perPageParam = perPage ? "&perPage=" + perPage : ""

    let pageParam = page ? "?page=" + page : "?page=1"

    let searchParam = search ? "&search=" + search : ""

    let pertemuan_id = pertemuanId ? "&pertemuan_id=" + pertemuanId : ""

    let url = `${process.env.REACT_APP_SERVICE_QUIZ_AWAL}${pageParam}${searchParam}${sortParam}${perPageParam}${pertemuan_id}`
    const res = yield call(request, url, {
      method: 'GET',
      headers: header
    })

    if (res?.success === true) {
      let data = {
        list: res?.result?.data,
        page: res?.result?.current_page,
        pageUrl: {
          first_page_url: res?.result?.first_page_url,
          links: res?.result?.links,
          last_page_url: res?.result?.last_page_url
        },
        last_page: res?.result?.last_page,
        from: res?.result?.from,
        to: res?.result?.to,
        total: res?.result?.total
      }
      yield put(actionFetchQuizAwalSuccess(data))
    } else {
      alert({ title: "Gagal", html: res?.message })
    }
  } catch (err) {
    alert({ title: "Gagal" })
    // yield put(actionSignInFailed())
  }

  yield put(actionFetchQuizAwalEnd())
}

function* sagaCreateQuizAwal(params) {
  yield put(actionCreateQuizAwalStart())

  const { data: { accessToken, payload } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_QUIZ_AWAL}`
    const res = yield call(request, url, {
      method: 'POST',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionCreateQuizAwalSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionCreateQuizAwalFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal menambah data",
      errors: null
    }

    yield put(actionCreateQuizAwalFailed(data))
  }

  yield put(actionCreateQuizAwalEnd())
}

function* sagaUpdateQuizAwal(params) {
  yield put(actionUpdateQuizAwalStart())

  const { data: { accessToken, payload, id } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_QUIZ_AWAL}/${id}`
    const res = yield call(request, url, {
      method: 'PUT',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionUpdateQuizAwalSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionUpdateQuizAwalFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal merubah data",
      errors: null
    }

    yield put(actionUpdateQuizAwalFailed(data))
  }

  yield put(actionUpdateQuizAwalEnd())
}

function* sagaDeleteQuizAwal(params) {
  yield put(actionDeleteQuizAwalStart())

  const { data: { accessToken, id } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_QUIZ_AWAL}/${id}`
    const res = yield call(request, url, {
      method: 'DELETE',
      headers: header
    })

    if (res?.success === true) {
      yield put(actionDeleteQuizAwalSuccess())
    } else {
      yield put(actionDeleteQuizAwalFailed())
    }
  } catch (err) {
    yield put(actionDeleteQuizAwalFailed())
  }

  yield put(actionDeleteQuizAwalEnd())
}

function* quizAwalSaga() {
  yield all([
    takeEvery(actionTypes.FETCH_QUIZ_AWAL, sagaFetchQuizAwal),
    takeEvery(actionTypes.CREATE_QUIZ_AWAL, sagaCreateQuizAwal),
    takeEvery(actionTypes.UPDATE_QUIZ_AWAL, sagaUpdateQuizAwal),
    takeEvery(actionTypes.DELETE_QUIZ_AWAL, sagaDeleteQuizAwal),
  ])
}

export default quizAwalSaga