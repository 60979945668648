import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionDeleteKursus, actionResetDeleteKursus } from "../../store/kursus/actions"
import { request } from "../../utils/request"
import { alert, confirm } from "../../utils/alert"
import { changeUrl, pushUrl } from "../../utils/url"
import HeadTable from "../../components/Table/HeadTable"
import SearchTable from "../../components/Table/SearchTable"
import Pagination from "../../components/Table/Pagination"
import LimitTable from "../../components/Table/LimitTable"
import Modal from "../../components/Modal"
import { default as CreateModul } from "../Modul/create"
import { default as DetailModul } from "../Modul/detail"

const KursusDetail = (props) => {
    const { actionDeleteKursus, actionResetDeleteKursus, accessToken, match: { params: { id } }, history: { location: { state } }, del } = props
    const [data, setData] = useState(null)
    const [dataModul, setDataModul] = useState(null)
    const [noData, setNoData] = useState(false)
    const [submit, setSubmit] = useState(false)

    const [index, setIndex] = useState(null)
    const [sort, setSort] = useState({
        by: null,
        type: null
    })
    const [search, setSearch] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState("")
    const [successCreateModul, setSuccessCreateModul] = useState(null)
    const [modulId, setModulId] = useState("")

    const [logoLoad, setLogoLoad] = useState(null)
    const [bannerLoad, setBannerLoad] = useState(null)

    const [currentUrl, setCurrentUrl] = useState("")

    useEffect(() => {
        const titlePage = "Detail Kursus"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        if (state) {
            setData(state)
        } else {
            getDataKursus()
        }

        actionResetDeleteKursus()

        return () => actionResetDeleteKursus()
    }, [])

    useEffect(() => {
        getLovModul()
        getModul()
    }, [id])

    useEffect(() => {
        if (submit === true) {
            if (del?.success === true) {
                alert({ title: "Berhasil hapus data" })
                pushUrl("/kursus")
            } else if (del?.success === false) {
                alert({ title: "Gagal hapus data" })
            }
        }
    }, [del?.success, submit])

    const getDataKursus = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_KURSUS + '/' + id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setData(res?.result)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const classStatus = (status) => {
        if (status === "Aktif") {
            return "bg-label-success"
        } else if (status === "Tidak Aktif") {
            return "bg-label-danger"
        }

        return "bg-label-secondary"
    }

    const deleteData = async (e) => {
        e.preventDefault()

        let res = await confirm({
            title: "Hapus kursus",
            html: "Apakah anda yakin menghapus data ini?"
        })
        if (res === true) {
            setSubmit(true)
            actionDeleteKursus({ accessToken, id })
        }
    }

    const onSearch = (e) => {
        setSearch(searchValue)
        actionFetchModul({
            search: searchValue,
            page,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const onChangeSearch = (val) => {
        setSearchValue(val)
    }

    const pageData = (val, e) => {
        setPage(val)
        actionFetchModul({
            search,
            page: val,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const changePerPage = (val) => {
        setPerPage(val)
        actionFetchModul({
            search,
            page,
            sort,
            perPage: val,
            accessToken
        })
    }

    const getLovModul = () => {
        // console.log("getLovModul")
        const query = new URLSearchParams(props.location.search)

        if (query.get('page')) {
            setPage(parseInt(query.get('page')))
        } else {
            setPage(1)
        }

        if (query.get('search')) {
            setSearch(query.get('search'))
            setSearchValue(query.get('search'))
        }

        if (query.get('perPage')) {
            setPerPage(parseInt(query.get('perPage')))
        } else {
            setPerPage(5)
        }

        if (query.get('sortBy') || query.get('sortType')) {
            setSort({
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            })
        }

        if (query.get('sortBy') || query.get('sortType')) {
            setSort({
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            })
        }

        if (!query.get('modulId')) {
            actionFetchModul({
                search: query.get('search') || "",
                page: parseInt(query.get('page')) || 1,
                sort: {
                    by: query.get('sortBy') || null,
                    type: query.get('sortType') || null,
                },
                perPage: parseInt(query.get('perPage')) || 5,
                accessToken
            })
        }
    }

    const actionFetchModul = async (params) => {
        const { accessToken, search, page, sort, perPage } = params

        const header = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
        }

        try {
            let sortParam = ""
            if (sort?.by) {
                sortParam += "&sortBy=" + sort?.by
            }
            if (sort?.type) {
                sortParam += "&sortType=" + sort?.type
            }

            let perPageParam = perPage ? "&perPage=" + perPage : ""

            let pageParam = page ? "?page=" + page : "?page=1"

            let searchParam = search ? "&search=" + search : ""

            let kursus_id = "&kursus_id=" + id

            let url = `${process.env.REACT_APP_SERVICE_MODUL}${pageParam}${searchParam}${sortParam}${perPageParam}${kursus_id}`
            const res = await request(url, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                let data = {
                    list: res?.result?.data,
                    page: res?.result?.current_page,
                    pageUrl: {
                        first_page_url: res?.result?.first_page_url,
                        links: res?.result?.links,
                        last_page_url: res?.result?.last_page_url
                    },
                    last_page: res?.result?.last_page,
                    from: res?.result?.from,
                    to: res?.result?.to,
                    total: res?.result?.total
                }

                setIndex(data)
            } else {
                alert({ title: "Gagal", html: res?.message })
            }
        } catch (err) {
            alert({ title: "Gagal" })
        }
    }

    const sortData = (field, e) => {
        let sortType = sort?.type === "desc" ? "asc" : "desc"
        setSort({
            by: field,
            type: sortType
        })
        actionFetchModul({
            search,
            page,
            sort: {
                by: field,
                type: sortType
            },
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    useEffect(() => {
        let queryParams = {}
        if (page) {
            queryParams['page'] = page
        }
        if (search) {
            queryParams['search'] = search
        }
        if (sort?.by) {
            queryParams['sortBy'] = sort?.by
        }
        if (sort?.type) {
            queryParams['sortType'] = sort?.type
        }
        if (perPage) {
            queryParams['perPage'] = perPage
        }
        if (modulId) {
            queryParams['modulId'] = modulId
        }

        let query = new URLSearchParams(queryParams).toString()

        changeUrl("/kursus/detail/" + id + "?" + query)
        setCurrentUrl("/kursus/detail/" + id + "?" + query)
    }, [page, search, sort?.by, sort?.type, perPage, modulId])


    useEffect(() => {
        if (successCreateModul === true) {
            getLovModul()
        }
    }, [successCreateModul])

    const onClickModul = (e, id) => {
        console.log({ id })
        setModulId(id)
        e.preventDefault()
    }

    const getModul = () => {
        const query = new URLSearchParams(props.location.search)

        if (query.get('modulId')) {
            setModulId(query.get('modulId'))
        }
    }

    const lovModulView = () => {
        return <React.Fragment>
            <div className="card mt-2">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-8">
                            <a href="#" type="button" data-bs-toggle="modal" data-bs-target="#modalCreateKursusModul" className="btn btn-primary mb-2 btn-md-block">Tambah Modul</a>
                        </div>
                        <div className="col-md-4 text-end">
                            <SearchTable onSearch={onSearch} onChange={onChangeSearch} value={searchValue} />
                        </div>
                    </div>
                    {
                        index?.total > 0 ?
                            <p className="mt-2">Menampilkan {index?.from} - {index?.to} dari {index?.total} data</p>
                            :
                            <p className="mt-2">Menampilkan 0 data</p>
                    }
                    <div className="table-responsive grid-view mt-2">
                        <table className="table table-bordered">
                            <HeadTable
                                sort={sort}
                                sortData={sortData}
                                colorized={false}
                                columns={[
                                    { type: "label", label: "No." },
                                    { type: "sortable", label: "Nama", field: "nama" },
                                    { type: "sortable", label: "Jumlah Pertemuan", field: "jumlah_pertemuan" },
                                    { type: "label", label: "" }
                                ]}
                            />
                            <tbody className="table-border-bottom-0">
                                {
                                    index?.list?.length > 0 ?
                                        index?.list?.map((val, key) => {
                                            return <tr key={key}>
                                                <td><strong>{((page - 1) * perPage) + key + 1}</strong></td>
                                                <td>{val?.nama}</td>
                                                <td>{val?.jumlah_pertemuan}</td>
                                                <td style={{ width: 200 }} className="text-end">
                                                    <button type="button" className="btn btn-info btn-sm" onClick={(e) => onClickModul(e, val?.id)}>Detail</button>
                                                </td>
                                            </tr>
                                        })
                                        : <tr>
                                            <td colSpan={4}>Data tidak ditemukan</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="row mt-4">
                        <div className="col-lg-8">
                            <Pagination page={page} pageUrl={index?.pageUrl} onChange={pageData} last_page={index?.last_page} />
                        </div>
                        <div className="col-lg-4">
                            <LimitTable perPage={perPage} onChange={changePerPage} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    const onClickBackModul = () => {
        setModulId("")
        actionFetchModul({
            search,
            page,
            sort,
            perPage,
            accessToken
        })
    }

    const singleModulView = () => {
        return <React.Fragment>
            <DetailModul directCall={false} modulId={modulId} onClickBack={onClickBackModul} fromUrl={currentUrl} />
        </React.Fragment>
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-4">
                    <Link to={process.env.REACT_APP_SUBDIR + "/kursus"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>
                </div>
                <div className="col-lg-8 text-end">
                    <Link to={process.env.REACT_APP_SUBDIR + "/kursus/update/" + data?.id + "?from=detail"} className="btn btn-success mb-2 btn-md-block">Ubah</Link>
                    <a href="/#" onClick={(e) => deleteData(e)} className={"btn btn-danger mb-2 btn-md-block " + (del?.loading === true ? "disabled" : "")}>Hapus {del?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}</a>
                </div>
            </div>

            <div className="mt-2">
                <small className="text-muted text-uppercase">Kursus</small>
            </div>
            <div className="card mt-2">
                <div className="card-body">
                    {
                        noData === true ?
                            <span>Data tidak ditemukan</span>
                            :
                            <React.Fragment>
                                {/* <small className="text-muted text-uppercase">Kursus</small> */}
                                <div className="table-responsive text-nowrap detail-view mt-2">
                                    <table className="table table-bordered">
                                        <tbody className="table-border-bottom-0">
                                            <tr>
                                                <th style={{ width: "25%" }}>
                                                    <strong>Nama</strong>
                                                </th>
                                                <td>{data?.nama}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ width: "25%" }}>
                                                    <strong>Keterangan</strong>
                                                </th>
                                                <td><span dangerouslySetInnerHTML={{ __html: data?.keterangan }}></span></td>
                                            </tr>
                                            <tr>
                                                <th style={{ width: "25%" }}>
                                                    <strong>Jenis Urutan Modul</strong>
                                                </th>
                                                <td>{data?.jenis_urutan}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ width: "25%" }}>
                                                    <strong>Logo</strong>
                                                </th>
                                                <td>
                                                    {
                                                        data?.id ?
                                                            data?.logo ?
                                                                <img src={process.env.REACT_APP_SERVICE + '/uploads/kursus/logo/' + data?.logo} onLoad={() => setLogoLoad(true)} onError={() => setLogoLoad(false)} style={{ display: logoLoad === true ? "block" : "none", width: "300px" }} />
                                                                : <span>-</span>
                                                            : null
                                                    }
                                                    {
                                                        logoLoad === false ?
                                                            <span>-</span>
                                                            : null
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{ width: "25%" }}>
                                                    <strong>Banner</strong>
                                                </th>
                                                <td>
                                                    {
                                                        data?.id ?
                                                            data?.banner ?
                                                                <img src={process.env.REACT_APP_SERVICE + '/uploads/kursus/banner/' + data?.banner} onLoad={() => setBannerLoad(true)} onError={() => setBannerLoad(false)} style={{ display: bannerLoad === true ? "block" : "none", width: "300px" }} />
                                                                : <span>-</span>
                                                            : null
                                                    }
                                                    {
                                                        bannerLoad === false ?
                                                            <span>-</span>
                                                            : null
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{ width: "25%" }}>
                                                    <strong>Status</strong>
                                                </th>
                                                <td><span className={"badge me-1 " + classStatus(data?.status)}>{data?.status}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment>
                    }
                </div>
            </div>

            <Modal id={"modalCreateKursusModul"} title={"Tambah Modul"} button={{ visible: true, label: "Kirim" }} size={"modal-lg"} buttons={false}>
                <CreateModul fromModal={true} kursusId={id} callbackSuccessModal={setSuccessCreateModul} />
            </Modal>

            {
                noData !== true ?
                    <React.Fragment>
                        <div className="mt-4">
                            <small className="text-muted text-uppercase">Modul</small>
                        </div>
                        {
                            modulId ?
                                singleModulView()
                                :
                                lovModulView()
                        }
                    </React.Fragment>
                    : null
            }
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, kursus: { del } } = state

    return { accessToken, del }
}

export default withRouter(connect(mapStateToProps, { actionDeleteKursus, actionResetDeleteKursus })(KursusDetail))