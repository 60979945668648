import Immutable from 'seamless-immutable'

const FETCH_PENILAIAN_TUGAS_PELAJAR = 'FETCH_PENILAIAN_TUGAS_PELAJAR'
const FETCH_PENILAIAN_TUGAS_PELAJAR_START = 'FETCH_PENILAIAN_TUGAS_PELAJAR_START'
const FETCH_PENILAIAN_TUGAS_PELAJAR_END = 'FETCH_PENILAIAN_TUGAS_PELAJAR_END'
const FETCH_PENILAIAN_TUGAS_PELAJAR_SUCCESS = 'FETCH_PENILAIAN_TUGAS_PELAJAR_SUCCESS'
const FETCH_PENILAIAN_TUGAS_PELAJAR_FAILED = 'FETCH_PENILAIAN_TUGAS_PELAJAR_FAILED'

const UPDATE_PENILAIAN_TUGAS_PELAJAR = 'UPDATE_PENILAIAN_TUGAS_PELAJAR'
const UPDATE_PENILAIAN_TUGAS_PELAJAR_START = 'UPDATE_PENILAIAN_TUGAS_PELAJAR_START'
const UPDATE_PENILAIAN_TUGAS_PELAJAR_END = 'UPDATE_PENILAIAN_TUGAS_PELAJAR_END'
const UPDATE_PENILAIAN_TUGAS_PELAJAR_SUCCESS = 'UPDATE_PENILAIAN_TUGAS_PELAJAR_SUCCESS'
const UPDATE_PENILAIAN_TUGAS_PELAJAR_FAILED = 'UPDATE_PENILAIAN_TUGAS_PELAJAR_FAILED'

const RESET_FORM_PENILAIAN_TUGAS_PELAJAR = 'RESET_FORM_PENILAIAN_TUGAS_PELAJAR'

const actionTypes = Immutable({
  FETCH_PENILAIAN_TUGAS_PELAJAR,
  FETCH_PENILAIAN_TUGAS_PELAJAR_START,
  FETCH_PENILAIAN_TUGAS_PELAJAR_END,
  FETCH_PENILAIAN_TUGAS_PELAJAR_SUCCESS,
  FETCH_PENILAIAN_TUGAS_PELAJAR_FAILED,

  UPDATE_PENILAIAN_TUGAS_PELAJAR,
  UPDATE_PENILAIAN_TUGAS_PELAJAR_START,
  UPDATE_PENILAIAN_TUGAS_PELAJAR_END,
  UPDATE_PENILAIAN_TUGAS_PELAJAR_SUCCESS,
  UPDATE_PENILAIAN_TUGAS_PELAJAR_FAILED,

  RESET_FORM_PENILAIAN_TUGAS_PELAJAR
})

export default actionTypes
