import Immutable from 'seamless-immutable'

const FETCH_QUIZ_AWAL = 'FETCH_QUIZ_AWAL'
const FETCH_QUIZ_AWAL_START = 'FETCH_QUIZ_AWAL_START'
const FETCH_QUIZ_AWAL_END = 'FETCH_QUIZ_AWAL_END'
const FETCH_QUIZ_AWAL_SUCCESS = 'FETCH_QUIZ_AWAL_SUCCESS'
const FETCH_QUIZ_AWAL_FAILED = 'FETCH_QUIZ_AWAL_FAILED'

const CREATE_QUIZ_AWAL = 'CREATE_QUIZ_AWAL'
const CREATE_QUIZ_AWAL_START = 'CREATE_QUIZ_AWAL_START'
const CREATE_QUIZ_AWAL_END = 'CREATE_QUIZ_AWAL_END'
const CREATE_QUIZ_AWAL_SUCCESS = 'CREATE_QUIZ_AWAL_SUCCESS'
const CREATE_QUIZ_AWAL_FAILED = 'CREATE_QUIZ_AWAL_FAILED'

const UPDATE_QUIZ_AWAL = 'UPDATE_QUIZ_AWAL'
const UPDATE_QUIZ_AWAL_START = 'UPDATE_QUIZ_AWAL_START'
const UPDATE_QUIZ_AWAL_END = 'UPDATE_QUIZ_AWAL_END'
const UPDATE_QUIZ_AWAL_SUCCESS = 'UPDATE_QUIZ_AWAL_SUCCESS'
const UPDATE_QUIZ_AWAL_FAILED = 'UPDATE_QUIZ_AWAL_FAILED'

const DELETE_QUIZ_AWAL = 'DELETE_QUIZ_AWAL'
const DELETE_QUIZ_AWAL_START = 'DELETE_QUIZ_AWAL_START'
const DELETE_QUIZ_AWAL_END = 'DELETE_QUIZ_AWAL_END'
const DELETE_QUIZ_AWAL_SUCCESS = 'DELETE_QUIZ_AWAL_SUCCESS'
const DELETE_QUIZ_AWAL_FAILED = 'DELETE_QUIZ_AWAL_FAILED'

const RESET_FORM_QUIZ_AWAL = 'RESET_FORM_QUIZ_AWAL'
const RESET_DELETE_QUIZ_AWAL = 'RESET_DELETE_QUIZ_AWAL'

const actionTypes = Immutable({
  FETCH_QUIZ_AWAL,
  FETCH_QUIZ_AWAL_START,
  FETCH_QUIZ_AWAL_END,
  FETCH_QUIZ_AWAL_SUCCESS,
  FETCH_QUIZ_AWAL_FAILED,

  CREATE_QUIZ_AWAL,
  CREATE_QUIZ_AWAL_START,
  CREATE_QUIZ_AWAL_END,
  CREATE_QUIZ_AWAL_SUCCESS,
  CREATE_QUIZ_AWAL_FAILED,

  UPDATE_QUIZ_AWAL,
  UPDATE_QUIZ_AWAL_START,
  UPDATE_QUIZ_AWAL_END,
  UPDATE_QUIZ_AWAL_SUCCESS,
  UPDATE_QUIZ_AWAL_FAILED,

  DELETE_QUIZ_AWAL,
  DELETE_QUIZ_AWAL_START,
  DELETE_QUIZ_AWAL_END,
  DELETE_QUIZ_AWAL_SUCCESS,
  DELETE_QUIZ_AWAL_FAILED,

  RESET_FORM_QUIZ_AWAL,
  RESET_DELETE_QUIZ_AWAL
})

export default actionTypes
