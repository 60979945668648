import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionDeleteVideo, actionFetchVideo } from "../../store/video/actions"
import Pagination from "../../components/Table/Pagination"
import { changeUrl } from "../../utils/url"
import { alert, confirm } from "../../utils/alert"
import LimitTable from "../../components/Table/LimitTable"
import SearchTable from "../../components/Table/SearchTable"
import HeadTable from "../../components/Table/HeadTable"
import DetailView from "../../components/DetailView"
import Modal from "../../components/Modal"
import Create from "./create"
import Update from "./update"
import { closeModal } from "../../utils/modal"
import VideoPlayer from "../../components/VideoPlayer"

const VideoIndex = (props) => {
    const { actionFetchVideo, actionDeleteVideo, accessToken, index, del, directCall = true, pertemuanId = null, callback = null } = props
    const [sort, setSort] = useState({
        by: null,
        type: null
    })
    const [search, setSearch] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState("")
    const [submit, setSubmit] = useState(false)

    const [urlVideo, setUrlVideo] = useState()
    const [successCreate, setSuccessCreate] = useState(null)
    const [successUpdate, setSuccessUpdate] = useState(null)

    const [videoId, setVideoId] = useState(null)

    // useEffect(() => {
    //     const titlePage = "Video"
    //     changeTitle(titlePage)
    //     changeBreadcrumbs(titlePage)
    // }, [])

    useEffect(() => {
        fetchData()
    }, [pertemuanId])

    const fetchData = () => {
        const query = new URLSearchParams(props.location.search)

        if (query.get('page')) {
            setPage(parseInt(query.get('page')))
        } else {
            setPage(1)
        }

        if (query.get('search')) {
            setSearch(query.get('search'))
            setSearchValue(query.get('search'))
        }

        if (query.get('perPage')) {
            setPerPage(parseInt(query.get('perPage')))
        } else {
            setPerPage(10)
        }

        if (query.get('sortBy') || query.get('sortType')) {
            setSort({
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            })
        }

        actionFetchVideo({
            pertemuanId,
            search: query.get('search') || "",
            page: parseInt(query.get('page')) || 1,
            sort: {
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            },
            perPage: parseInt(query.get('perPage')) || 10,
            accessToken
        })
    }

    const classStatus = (status) => {
        if (status === "Aktif") {
            return "bg-label-success"
        } else if (status === "Tidak Aktif") {
            return "bg-label-danger"
        }

        return "bg-label-secondary"
    }

    const sortData = (field, e) => {
        let sortType = sort?.type === "desc" ? "asc" : "desc"
        setSort({
            by: field,
            type: sortType
        })
        actionFetchVideo({
            pertemuanId,
            search,
            page,
            sort: {
                by: field,
                type: sortType
            },
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const changePerPage = (val) => {
        setPerPage(val)
        actionFetchVideo({
            pertemuanId,
            search,
            page,
            sort,
            perPage: val,
            accessToken
        })
    }

    useEffect(() => {
        let queryParams = {}
        if (page) {
            queryParams['page'] = page
        }
        if (search) {
            queryParams['search'] = search
        }
        if (sort?.by) {
            queryParams['sortBy'] = sort?.by
        }
        if (sort?.type) {
            queryParams['sortType'] = sort?.type
        }
        if (perPage) {
            queryParams['perPage'] = perPage
        }

        let query = new URLSearchParams(queryParams).toString()

        // directCall === true ? changeUrl("video?" + query) : changeUrl(window.location.href + "&" + query)
        if (directCall === true) {
            changeUrl("video?" + query)
        }
    }, [page, search, sort?.by, sort?.type, perPage])

    const pageData = (val, e) => {
        setPage(val)
        actionFetchVideo({
            pertemuanId,
            search,
            page: val,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const onSearch = (e) => {
        setSearch(searchValue)
        actionFetchVideo({
            pertemuanId,
            search: searchValue,
            page,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const onChangeSearch = (val) => {
        setSearchValue(val)
    }

    const deleteData = async (e, id, nama) => {
        e.preventDefault()

        let res = await confirm({
            title: "Hapus " + nama,
            html: "Apakah anda yakin menghapus data ini?"
        })
        if (res === true) {
            setSubmit(true)
            actionDeleteVideo({ accessToken, id })
        }
    }

    useEffect(() => {
        if (submit === true) {
            if (del?.success === true) {
                callback()
                actionFetchVideo({
                    pertemuanId,
                    search,
                    page,
                    sort,
                    perPage,
                    accessToken
                })
                alert({ title: "Berhasil hapus data" })
            } else if (del?.success === false) {
                alert({ title: "Gagal hapus data" })
            }
        }
    }, [del?.success, submit])

    useEffect(() => {
        if (page && index?.last_page) {
            if (page > index?.last_page) {
                setPage(index?.last_page)
                actionFetchVideo({
                    pertemuanId,
                    search,
                    page: index?.last_page,
                    sort,
                    perPage,
                    accessToken
                })
            } else if (page < 0) {
                setPage(1)
                actionFetchVideo({
                    pertemuanId,
                    search,
                    page: 1,
                    sort,
                    perPage,
                    accessToken
                })
            }
        }
    }, [page, index?.last_page])

    const updateData = async (e, id) => {
        e.preventDefault()

        setVideoId(null)

        let openUpdateVideoModal = document.getElementById('openUpdateVideoModal')

        if (openUpdateVideoModal) {
            setVideoId(id)
            openUpdateVideoModal.click()
        }
    }

    const openVideoModal = (e, fileName) => {
        let url = process.env.REACT_APP_SERVICE + '/uploads/video/' + fileName + "?t=" + (new Date())
        let openVideoModal = document.getElementById('openVideoModal')

        if (openVideoModal) {
            setUrlVideo(url)
            openVideoModal.click()
        }

        e.preventDefault()
    }

    useEffect(() => {
        if (successCreate === true || successUpdate === true) {
            fetchData()
            callback()
            closeModal()
        }
    }, [successCreate, successUpdate])

    const sizeToKb = (val) => {
        return (parseInt(val / 1000)).toString() + "kb"
    }

    const minimalDurasiLihat = (val1, val2) => {
        if (val1 === "Detik Tertentu") {
            return val2 + " detik"
        }

        return val1
    }

    const kelompokBadge = (data) => {
        return <React.Fragment>
            {
                data?.length > 0 ?
                    data?.map((val, key) => {
                        return <span key={key} className="badge bg-primary me-1">{val?.kelompok?.nama}</span>
                    })
                    : null
            }
        </React.Fragment>
    }

    const view = () => {
        return <React.Fragment>
            <Modal id={"modalVideo"} title={"Lihat Video"} button={{ visible: true, label: "Download" }} size={"modal-lg"}>
                <VideoPlayer url={urlVideo} />
            </Modal>
            <Modal id={"modalCreatePertemuanVideo"} title={"Tambah Video"} size={"modal-lg"} buttons={false}>
                <Create fromModal={true} pertemuanId={pertemuanId} callbackSuccessModal={setSuccessCreate} />
            </Modal>
            <Modal id={"modalUpdatePertemuanVideo"} title={"Ubah Video"} size={"modal-lg"} buttons={false}>
                {
                    videoId ?
                        <Update fromModal={true} videoId={videoId} callbackSuccessModal={setSuccessUpdate} />
                        : null
                }
            </Modal>

            <a href="#" id="openVideoModal" type="button" data-bs-toggle="modal" data-bs-target="#modalVideo" className="d-none">Buka Video</a>
            <a href="#" id="openUpdateVideoModal" type="button" data-bs-toggle="modal" data-bs-target="#modalUpdatePertemuanVideo" className="d-none">Ubah Video</a>

            <div className="row">
                <div className="col-lg-8">
                    <a href="#" type="button" data-bs-toggle="modal" data-bs-target="#modalCreatePertemuanVideo" className="btn btn-primary mb-2 btn-md-block">Tambah Video</a>
                </div>
                <div className="col-lg-4">
                    <SearchTable onSearch={onSearch} onChange={onChangeSearch} value={searchValue} />
                </div>
            </div>

            {
                index?.total > 0 ?
                    <p className="mt-2">Menampilkan {index?.from} - {index?.to} dari {index?.total} data</p>
                    :
                    <p className="mt-2">Menampilkan 0 data</p>
            }

            <div className={directCall === true ? "card mt-3" : "mt-3"}>
                {
                    index?.list?.length > 0 ?
                        index?.list?.map((val, key) => {
                            return <div key={key} className="mb-4">
                                <DetailView
                                    data={[
                                        { label: "Nama", value: val?.nama },
                                        { label: "Keterangan", value: val?.keterangan, inner: true },
                                        { label: "Minimal Durasi Lihat", value: minimalDurasiLihat(val?.minimal_durasi_lihat, val?.nilai_minimal_durasi_lihat) },
                                        { label: "Kelompok", value: kelompokBadge(val?.kelompok) },
                                        {
                                            label: "File", value: <React.Fragment>
                                                <a href="#" type="button" onClick={(e) => openVideoModal(e, val?.file_name_real)}>{val?.file_name_alias}</a> [<span>{sizeToKb(val?.file_size)}</span>]
                                            </React.Fragment>
                                        },
                                        {
                                            label: "Aksi", value: <React.Fragment>
                                                <a href="/#" onClick={(e) => updateData(e, val?.id)} className={"btn btn-success btn-sm"}>Ubah</a>
                                                <a href="/#" onClick={(e) => deleteData(e, val?.id, "video")} className={"btn btn-danger btn-sm " + (del?.loading === true ? "disabled" : "")}>Hapus {del?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}</a>
                                            </React.Fragment>
                                        }
                                    ]}
                                />
                            </div>
                        })
                        : <div className="mt-4 mb-4">
                            <div className="alert alert-primary" role="alert">
                                <span>Data tidak ditemukan</span>
                            </div>
                        </div>
                }
            </div>

            <div className="row mt-4">
                <div className="col-lg-8">
                    <Pagination page={page} pageUrl={index?.pageUrl} onChange={pageData} last_page={index?.last_page} />
                </div>
                <div className="col-lg-4">
                    <LimitTable perPage={perPage} onChange={changePerPage} />
                </div>
            </div>
        </React.Fragment>
    }

    return <React.Fragment>
        {
            directCall === true ?
                <div className="container-xxl flex-grow-1 container-p-y">
                    {view()}
                </div>
                : view()
        }
    </React.Fragment >
}

const mapStateToProps = state => {
    const { auth: { accessToken }, video: { index, del } } = state

    return { accessToken, index, del }
}

export default withRouter(connect(mapStateToProps, { actionFetchVideo, actionDeleteVideo })(VideoIndex))