import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../../utils/page"
import { actionDeleteKursus, actionResetDeleteKursus } from "../../../store/kursus/actions"
import { request } from "../../../utils/request"
import { alert, confirm } from "../../../utils/alert"
import { pushUrl } from "../../../utils/url"
import Modal from "../../../components/Modal"
import PDFViewer from "../../../components/PDFViewer"
import VideoPlayer from "../../../components/VideoPlayer"
import FileInput from "../../../components/Form/FileInput"
import { closeModal } from "../../../utils/modal"
import { asTimeWithLabel, dateTime } from "../../../utils/formatter"
import HeadTable from "../../../components/Table/HeadTable"


const ProgressKursusPertemuanDetail = (props) => {
    const { actionResetDeleteKursus, accessToken, match: { params: { id, pertemuanId } }, history: { location: { state } }, del, user } = props
    const [data, setData] = useState(null)
    const [noData, setNoData] = useState(false)


    const [tab, setTab] = useState("Rasional")
    const [tab2, setTab2] = useState("Forum")

    const [urlPdf, setUrlPdf] = useState(null)
    const [urlGambar, setUrlGambar] = useState(null)
    const [urlVideo, setUrlVideo] = useState(null)

    const [chats, setChats] = useState([])
    const [loadingSubmitChat, setLoadingSubmitChat] = useState(false)
    const [newChats, setNewChats] = useState([])
    const [payloadChatText, setPayloadChatText] = useState("")

    const chatsEndRef = useRef(null)

    useEffect(() => {
        const titlePage = "Detail Kursus"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        getData()
        getChat()

        actionResetDeleteKursus()

        return () => actionResetDeleteKursus()
    }, [])

    useEffect(() => {
        let intervalId = setInterval(() => getLatestChat(), 5000)

        return () => clearInterval(intervalId)
    }, [chats])

    const getChat = async () => {
        try {
            const headerPost = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`,
            }

            let data = {
                pertemuan_id: pertemuanId
            }

            const res = await request(process.env.REACT_APP_SERVICE_CHAT + '/load', {
                method: 'POST',
                headers: headerPost,
                body: JSON.stringify(data)
            })

            if (res?.success === true) {
                let result = res?.result
                setChats(result)
                // setFirstLoad(false)
                setTimeout(() => scrollToBottom(), 200)
            }
        } catch (error) {
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const getLatestChat = async () => {
        let lastChat = chats.at(-1)
        if (lastChat) {
            try {
                const headerPost = {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`,
                }

                let data = {
                    pertemuan_id: pertemuanId,
                    last_id: lastChat?.id
                }

                const res = await request(process.env.REACT_APP_SERVICE_CHAT + '/load-after', {
                    method: 'POST',
                    headers: headerPost,
                    body: JSON.stringify(data)
                })

                if (res?.success === true) {
                    let result = res?.result
                    if (result?.length > 0) {
                        let toPush = chats.concat(result)
                        setChats(toPush)
                        setNewChats([])
                    }
                }
            } catch (error) {
                alert({ title: "Gagal", html: "Gagal meminta data ke server" })
            }
        }
    }

    const getOldestChat = async () => {
        let firstChat = chats.at(0)
        if (firstChat) {
            try {
                const headerPost = {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`,
                }

                let data = {
                    pertemuan_id: pertemuanId,
                    last_id: firstChat?.id
                }

                const res = await request(process.env.REACT_APP_SERVICE_CHAT + '/load-before', {
                    method: 'POST',
                    headers: headerPost,
                    body: JSON.stringify(data)
                })

                if (res?.success === true) {
                    let result = res?.result
                    if (result?.length > 0) {
                        let first = chats[0]
                        let toPush = result.concat(chats)
                        setChats(toPush)
                        setNewChats([])
                        scrollToStart(first)
                    }
                }
            } catch (error) {
                alert({ title: "Gagal", html: "Gagal meminta data ke server" })
            }
        }
    }

    const getData = async () => {
        console.log("tes")
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_KURSUS + '/detail-pertemuan/' + pertemuanId, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                let result = res?.result

                setData(result)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const viewHeader = () => {
        return <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-body">
                            {
                                data === null ?
                                    null
                                    : <React.Fragment>
                                        <h4>
                                            {data?.nama}
                                        </h4>

                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: data?.keterangan }}></p>

                                        <p className="card-text">
                                            <small className="text-muted">Terakhir diperbarui oleh {data?.updated_by?.name || "-"}</small>
                                            {/* <br />
                                            <small className="text-muted">{data?.updated_at}</small> */}
                                        </p>
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    const openPdfModal = (e, id, fileName) => {
        let url = process.env.REACT_APP_SERVICE + '/uploads/jobsheet/' + fileName
        let openPdfModal = document.getElementById('openPdfModal')

        if (openPdfModal) {
            setUrlPdf(url)
            openPdfModal.click()
        }

        e.preventDefault()
    }

    const downloadPdf = () => {
        const a = document.createElement('a')
        a.href = urlPdf
        a.target = "_blank"
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const viewJobsheet = () => {
        return <React.Fragment>
            {
                data?.jobsheet?.length > 0 ?
                    <React.Fragment>
                        <a href="#" id="openPdfModal" type="button" data-bs-toggle="modal" data-bs-target="#modalPdf" className="d-none">Buka PDF</a>
                        <Modal id={"modalPdf"} title={"Lihat Jobsheet"} button={{ visible: true, label: "Download", class: "mx-auto", onClick: downloadPdf }} size={"modal-fullscreen"} button2={{ visible: false }}>
                            <PDFViewer url={urlPdf} />
                        </Modal>
                        {
                            data?.jobsheet?.map((val, key) => {
                                return <div key={key} className="card mt-1 mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {val?.nama}
                                        </h5>
                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: val?.keterangan }}></p>
                                        <p className="card-text">
                                            <small className="text-muted">Terakhir diperbarui oleh {val?.updated_by?.name || "-"}</small>
                                            {/* <br />
                                            <small className="text-muted">{val?.updated_at || "-"}</small> */}
                                        </p>

                                        <button type="button" onClick={(e) => openPdfModal(e, val?.id, val?.file_name_real)} className="btn btn-primary btn-sm text-nowrap">
                                            Lihat
                                        </button>
                                    </div>
                                </div>
                            })
                        }
                    </React.Fragment>

                    : <div className="card mt-1 mb-3">
                        <div className="card-body">
                            <p className="card-text">Jobsheet tidak tersedia</p>
                        </div>
                    </div>
            }

            <div className="mt-4">
                <small className="text-muted text-uppercase">Progress Pelajar</small>
                <div className="card pt-0 mt-2">
                    <div className="card-body">
                        <div className="table-responsive grid-view mt-2">
                            <table className="table table-bordered">
                                <HeadTable
                                    colorized={false}
                                    columns={[
                                        { type: "label", label: "Nama Pelajar" },
                                        { type: "label", label: "Jobsheet" },
                                        { type: "label", label: "Jumlah Halaman Dibaca" },
                                        { type: "label", label: "Status" }
                                    ]}
                                />
                                <tbody className="table-border-bottom-0">
                                    {
                                        data?.jobsheetProgress?.length > 0 ?
                                            data?.jobsheetProgress?.map((val, key) => {
                                                if (val?.pelajar?.name)
                                                    return <React.Fragment key={key}>
                                                        <tr>
                                                            <td>{val?.pelajar?.name}</td>
                                                            <td>{val?.jobsheet?.nama}</td>
                                                            <td>{val?.halaman_selesai} halaman</td>
                                                            <td>{val?.status}</td>
                                                        </tr>
                                                    </React.Fragment>
                                            })
                                            : <tr>
                                                <td colSpan={4}>Data tidak ditemukan</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    const openGambarModal = (e, id, fileName) => {
        let url = process.env.REACT_APP_SERVICE + '/uploads/gambar/' + fileName
        let openGambarModal = document.getElementById('openGambarModal')

        if (openGambarModal) {
            setUrlGambar(url)
            openGambarModal.click()
        }

        e.preventDefault()
    }

    const downloadGambar = () => {
        const a = document.createElement('a')
        a.href = urlGambar
        a.target = "_blank"
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const viewGambar = () => {
        return <React.Fragment>
            {
                data?.gambar?.length > 0 ?
                    <React.Fragment>
                        <a href="#" id="openGambarModal" type="button" data-bs-toggle="modal" data-bs-target="#modalGambar" className="d-none">Buka Gambar</a>
                        <Modal id={"modalGambar"} title={"Lihat Gambar"} button={{ visible: true, label: "Download", class: "mx-auto", onClick: downloadGambar }} size={"modal-fullscreen"} button2={{ visible: false }}>
                            <div style={{ width: "100%" }} className="text-center">
                                <img
                                    src={urlGambar}
                                    alt={urlGambar}
                                    className="img-fluid"
                                // style={{ maxHeight: "100%" }}
                                />
                            </div>
                        </Modal>
                        {
                            data?.gambar?.map((val, key) => {
                                return <div key={key} className="card mt-1 mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {val?.nama}
                                        </h5>
                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: val?.keterangan }}></p>
                                        <p className="card-text">
                                            <small className="text-muted">Terakhir diperbarui oleh {val?.updated_by?.name || "-"}</small>
                                            {/* <br />
                                            <small className="text-muted">{val?.updated_at || "-"}</small> */}
                                        </p>

                                        <button type="button" onClick={(e) => openGambarModal(e, val?.id, val?.file_name_real)} className="btn btn-primary btn-sm text-nowrap">
                                            Lihat
                                        </button>
                                    </div>
                                </div>
                            })
                        }
                    </React.Fragment>
                    : <div className="card mt-1 mb-3">
                        <div className="card-body">
                            <p className="card-text">Gambar tidak tersedia</p>
                        </div>
                    </div>
            }

            <div className="mt-4">
                <small className="text-muted text-uppercase">Progress Pelajar</small>
                <div className="card pt-0 mt-2">
                    <div className="card-body">
                        <div className="table-responsive grid-view mt-2">
                            <table className="table table-bordered">
                                <HeadTable
                                    colorized={false}
                                    columns={[
                                        { type: "label", label: "Nama Pelajar" },
                                        { type: "label", label: "Gambar" },
                                        { type: "label", label: "Status" }
                                    ]}
                                />
                                <tbody className="table-border-bottom-0">
                                    {
                                        data?.gambarProgress?.length > 0 ?
                                            data?.gambarProgress?.map((val, key) => {
                                                if (val?.pelajar?.name)
                                                    return <React.Fragment key={key}>
                                                        <tr>
                                                            <td>{val?.pelajar?.name}</td>
                                                            <td>{val?.gambar?.nama}</td>
                                                            <td>{val?.status}</td>
                                                        </tr>
                                                    </React.Fragment>
                                            })
                                            : <tr>
                                                <td colSpan={3}>Data tidak ditemukan</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    }

    const openVideoModal = (e, id, fileName, minDurasi, nilaiMinDurasi) => {
        let url = process.env.REACT_APP_SERVICE + '/uploads/video/' + fileName
        let openVideoModal = document.getElementById('openVideoModal')

        if (openVideoModal) {
            setUrlVideo(url)
            openVideoModal.click()
        }

        e.preventDefault()
    }

    const downloadVideo = () => {
        const a = document.createElement('a')
        a.href = urlVideo
        a.target = "_blank"
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const viewVideo = () => {
        return <React.Fragment>
            {
                data?.video?.length > 0 ?
                    <React.Fragment>
                        <a href="#" id="openVideoModal" type="button" data-bs-toggle="modal" data-bs-target="#modalVideo" className="d-none">Buka Video</a>
                        <Modal id={"modalVideo"} title={"Lihat Video"} button={{ visible: true, label: "Download", class: "mx-auto", onClick: downloadVideo }} size={"modal-fullscreen"} button2={{ visible: false }}>
                            <VideoPlayer url={urlVideo} track={true} />
                        </Modal>

                        {data?.video?.map((val, key) => {
                            return <div key={key} className="card mt-1 mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        {val?.nama}
                                    </h5>
                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: val?.keterangan }}></p>
                                    <p className="card-text">
                                        <small className="text-muted">Terakhir diperbarui oleh {val?.updated_by?.name || "-"}</small>
                                        {/* <br />
                                        <small className="text-muted">{val?.updated_at || "-"}</small> */}
                                    </p>

                                    <button type="button" onClick={(e) => openVideoModal(e, val?.id, val?.file_name_real)} className="btn btn-primary btn-sm text-nowrap">
                                        Lihat
                                    </button>
                                </div>
                            </div>
                        })
                        }
                    </React.Fragment>
                    : <div className="card mt-1 mb-3">
                        <div className="card-body">
                            <p className="card-text">Video tidak tersedia</p>
                        </div>
                    </div>
            }

            <div className="mt-4">
                <small className="text-muted text-uppercase">Progress Pelajar</small>
                <div className="card pt-0 mt-2">
                    <div className="card-body">
                        <div className="table-responsive grid-view mt-2">
                            <table className="table table-bordered">
                                <HeadTable
                                    colorized={false}
                                    columns={[
                                        { type: "label", label: "Nama Pelajar" },
                                        { type: "label", label: "Video" },
                                        { type: "label", label: "Durasi Lihat" },
                                        { type: "label", label: "Status" }
                                    ]}
                                />
                                <tbody className="table-border-bottom-0">
                                    {
                                        data?.videoProgress?.length > 0 ?
                                            data?.videoProgress?.map((val, key) => {
                                                if (val?.pelajar?.name)
                                                    return <React.Fragment key={key}>
                                                        <tr>
                                                            <td>{val?.pelajar?.name}</td>
                                                            <td>{val?.video?.nama}</td>
                                                            <td>{val?.durasi_selesai < 0 ? 'Semua' : val?.durasi_selesai + ' detik'} </td>
                                                            <td>{val?.status}</td>
                                                        </tr>
                                                    </React.Fragment>
                                            })
                                            : <tr>
                                                <td colSpan={4}>Data tidak ditemukan</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    const viewQuiz = () => {
        return <React.Fragment>
            <div className="card mt-1 mb-3">
                <div className="card-body">
                    {
                        data?.quiz > 0 ? <React.Fragment>
                            <ul className="list-unstyled mb-2">
                                <li className="d-flex align-items-center mb-2"><i className="bx bx-list-ul fw-semibold"></i><span className="mx-2 text-muted">Jumlah Pertanyaan:</span> <span className="text-muted">{data?.quiz}</span></li>
                                <li className="d-flex align-items-center mb-2"><i className="bx bx-time-five fw-semibold"></i><span className="mx-2 text-muted">Durasi Quiz:</span> <span className="text-muted">{asTimeWithLabel(data?.durasi_quiz)}</span></li>
                            </ul>
                        </React.Fragment>
                            : <p className="card-text">Quiz tidak tersedia</p>
                    }
                </div>
            </div>

            <div className="mt-4">
                <small className="text-muted text-uppercase">Progress Pelajar</small>
                <div className="card pt-0 mt-2">
                    <div className="card-body">
                        <div className="table-responsive grid-view mt-2">
                            <table className="table table-bordered">
                                <HeadTable
                                    colorized={false}
                                    columns={[
                                        { type: "label", label: "Nama Pelajar" },
                                        { type: "label", label: "Nilai Akhir" },
                                        { type: "label", label: "Status" }
                                    ]}
                                />
                                <tbody className="table-border-bottom-0">
                                    {
                                        data?.quizProgress?.length > 0 ?
                                            data?.quizProgress?.map((val, key) => {
                                                if (val?.pelajar?.name)
                                                    return <React.Fragment key={key}>
                                                        <tr>
                                                            <td>{val?.pelajar?.name}</td>
                                                            <td>{val?.nilai_akhir === null ? "-" : val?.nilai_akhir}</td>
                                                            <td>{val?.status}</td>
                                                        </tr>
                                                    </React.Fragment>
                                            })
                                            : <tr>
                                                <td colSpan={3}>Data tidak ditemukan</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    const viewQuizAwal = () => {
        return <React.Fragment>
            <div className="card mt-1 mb-3">
                <div className="card-body">
                    {
                        data?.quiz > 0 ? <React.Fragment>
                            <ul className="list-unstyled mb-2">
                                <li className="d-flex align-items-center mb-2"><i className="bx bx-list-ul fw-semibold"></i><span className="mx-2 text-muted">Jumlah Pertanyaan:</span> <span className="text-muted">{data?.quiz}</span></li>
                                <li className="d-flex align-items-center mb-2"><i className="bx bx-time-five fw-semibold"></i><span className="mx-2 text-muted">Durasi Quiz:</span> <span className="text-muted">{asTimeWithLabel(data?.durasi_quiz)}</span></li>
                            </ul>
                        </React.Fragment>
                            : <p className="card-text">Quiz awal tidak tersedia</p>
                    }
                </div>
            </div>

            <div className="mt-4">
                <small className="text-muted text-uppercase">Progress Pelajar</small>
                <div className="card pt-0 mt-2">
                    <div className="card-body">
                        <div className="table-responsive grid-view mt-2">
                            <table className="table table-bordered">
                                <HeadTable
                                    colorized={false}
                                    columns={[
                                        { type: "label", label: "Nama Pelajar" },
                                        { type: "label", label: "Nilai Akhir" },
                                        { type: "label", label: "Status" }
                                    ]}
                                />
                                <tbody className="table-border-bottom-0">
                                    {
                                        data?.quizAwalProgress?.length > 0 ?
                                            data?.quizAwalProgress?.map((val, key) => {
                                                if (val?.pelajar?.name)
                                                    return <React.Fragment key={key}>
                                                        <tr>
                                                            <td>{val?.pelajar?.name}</td>
                                                            <td>{val?.nilai_akhir === null ? "-" : val?.nilai_akhir}</td>
                                                            <td>{val?.status}</td>
                                                        </tr>
                                                    </React.Fragment>
                                            })
                                            : <tr>
                                                <td colSpan={3}>Data tidak ditemukan</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    const openLaporanModal = (e, id, fileName) => {
        let openLaporanModal = document.getElementById('openLaporanModal')

        if (openLaporanModal) {
            openLaporanModal.click()
        }

        e.preventDefault()
    }

    const viewLaporan = () => {
        return <React.Fragment>
            {
                data?.laporan?.length > 0 ?
                    <React.Fragment>
                        <a href="#" id="openLaporanModal" type="button" data-bs-toggle="modal" data-bs-target="#modalLaporan" className="d-none">Buka Laporan</a>
                        <Modal id={"modalLaporan"} title={"Lihat Laporan"} button={{ visible: false }} size={"modal-fullscreen"} button2={{ visible: false }}>

                        </Modal>

                        {
                            data?.laporan?.map((val, key) => {
                                return <div key={key} className="card mt-1 mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {val?.nama}
                                        </h5>
                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: val?.keterangan }}></p>
                                        <p className="card-text">
                                            <small className="text-muted">Terakhir diperbarui oleh {val?.updated_by?.name || "-"}</small>
                                            {/* <br />
                                            <small className="text-muted">{val?.updated_at || "-"}</small> */}
                                        </p>
                                    </div>
                                </div>
                            })
                        }
                    </React.Fragment>
                    : <div className="card mt-1 mb-3">
                        <div className="card-body">
                            <p className="card-text">Tugas tidak tersedia</p>
                        </div>
                    </div>
            }

            <div className="mt-4">
                <small className="text-muted text-uppercase">Progress Pelajar</small>
                <div className="card pt-0 mt-2">
                    <div className="card-body">
                        <div className="table-responsive grid-view mt-2">
                            <table className="table table-bordered">
                                <HeadTable
                                    colorized={false}
                                    columns={[
                                        { type: "label", label: "Nama Pelajar" },
                                        { type: "label", label: "Tugas" },
                                        { type: "label", label: "File" },
                                        { type: "label", label: "Nilai" },
                                        { type: "label", label: "Hasil" },
                                        { type: "label", label: "Status" }
                                    ]}
                                />
                                <tbody className="table-border-bottom-0">
                                    {
                                        data?.laporanProgress?.length > 0 ?
                                            data?.laporanProgress?.map((val, key) => {
                                                if (val?.user?.name)
                                                    return <React.Fragment key={key}>
                                                        <tr>
                                                            <td>{val?.user?.name}</td>
                                                            <td>{val?.laporan?.nama || '-'}</td>
                                                            <td>
                                                                {
                                                                    (val?.file && val?.file != '-') ? <a href={process.env.REACT_APP_PELAJAR_SERVICE + '/uploads/laporan/' + val?.file} target="_blank">
                                                                        Download
                                                                    </a>
                                                                        : <span>-</span>
                                                                }
                                                            </td>
                                                            <td>{val?.nilai || '-'}</td>
                                                            <td>{val?.hasil || '-'}</td>
                                                            <td>{val?.status}</td>
                                                        </tr>
                                                    </React.Fragment>
                                            })
                                            : <tr>
                                                <td colSpan={5}>Data tidak ditemukan</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    const scrollToBottom = () => {
        chatsEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const scrollToStart = (data) => {
        let el = document.getElementById("el-" + data?.id)
        if (el) {
            el.scrollIntoView({ behavior: "instant" })
        }
    }

    const handleScrollChat = (e) => {
        let pos = e?.target?.scrollTop
        if (pos <= 0) {
            if (chats?.length > 0) {
                getOldestChat()
            }
        }
    }

    const viewForum = () => {
        return <React.Fragment>
            <div className="card mt-1 mb-3">
                <div className="card-body px-0">
                    <div className="app-chat overflow-hidden" onScroll={handleScrollChat}>
                        <div className="app-chat-history">
                            <div className="chat-history-body">
                                <ul className="list-unstyled chat-history mb-0">
                                    {
                                        chats?.length > 0 ?
                                            chats?.map((val, key) => {
                                                return user?.id !== val?.user?.id ?
                                                    <li className="chat-message mb-1" key={"chats" + key} id={"el-" + val?.id}>
                                                        <div className="d-flex overflow-hidden">
                                                            <div className="chat-message-wrapper flex-grow-1">
                                                                {
                                                                    showChatUser(key) ?
                                                                        <div className="text-muted mt-1">
                                                                            <small>{val?.user?.name}</small>
                                                                        </div>
                                                                        : null
                                                                }
                                                                <div className="chat-message-text">
                                                                    <p className="mb-0">{val?.text}</p>
                                                                </div>
                                                                {
                                                                    showChatTime(key) ?
                                                                        <div className="text-muted mt-1 mb-2">
                                                                            <small>{val?.created_at}</small>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                    :
                                                    < li className="chat-message chat-message-right mb-1" key={"chats" + key} id={"el-" + val?.id}>
                                                        <div className="d-flex overflow-hidden">
                                                            <div className="chat-message-wrapper flex-grow-1">
                                                                {
                                                                    showChatUser(key) ?
                                                                        <div className="text-end text-muted mt-1">
                                                                            <small>{val?.user?.name}</small>
                                                                        </div>
                                                                        : null
                                                                }
                                                                <div className="chat-message-text">
                                                                    <p className="mb-0">{val?.text}</p>
                                                                </div>
                                                                {
                                                                    showChatTime(key) ?
                                                                        <div className="text-end text-muted mt-1 mb-2">
                                                                            {/* <i className="bx bx-check-double text-muted"></i> */}
                                                                            <small>{val?.created_at}</small>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                            })
                                            : null
                                    }

                                    {
                                        newChats?.length > 0 ?
                                            newChats?.map((val, key) => {
                                                return < li className="chat-message chat-message-right mb-1" key={"newChats" + key}>
                                                    <div className="d-flex overflow-hidden">
                                                        <div className="chat-message-wrapper flex-grow-1">
                                                            {
                                                                showNewChatUser(key) ?
                                                                    <div className="text-end text-muted mt-1">
                                                                        <small>{val?.user?.name}</small>
                                                                    </div>
                                                                    : null
                                                            }
                                                            <div className="chat-message-text">
                                                                <p className="mb-0">{val?.text}</p>
                                                            </div>
                                                            {
                                                                showNewChatTime(key) ?
                                                                    <div className="text-end text-muted mt-1">
                                                                        {/* <i className="bx bx-check-double text-muted"></i> */}
                                                                        <small>{val?.created_at}</small>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            })
                                            : null
                                    }

                                    {
                                        newChats?.length > 0 || chats?.length > 0 ? null
                                            : <li className="text-muted text-center">Belum ada pesan</li>
                                    }

                                    <li>
                                        <div ref={chatsEndRef} />
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="bg-abu">
                    <form onSubmit={onSubmitChat} className="form-send-message d-flex justify-content-between align-items-center ">
                        <input className="form-control message-input border-0 me-3 shadow-none" value={payloadChatText} onChange={onChangeChatText} placeholder="Ketik pesan anda..." />
                        <div className="message-actions d-flex align-items-center">
                            <button type="submit" className="btn btn-primary d-flex send-msg-btn" disabled={loadingSubmitChat === true}>
                                <span className="align-middle">
                                    {loadingSubmitChat === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : "Kirim"}
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment >
    }

    const showChatUser = (key) => {
        let arr = chats
        let val1 = arr.at(key)
        let val2 = arr.at(key - 1)

        if (key === 0) {
            return true
        }

        if (!val2) {
            return true
        }

        if (val1?.created_at !== val2?.created_at) {
            return true
        }

        if (val1?.user_id !== val2?.user_id) {
            return true
        }

        return false
    }

    const showNewChatUser = (key) => {
        key = (key + chats?.length)
        let arr = [...chats, ...newChats]
        // let arr = newChats
        let val1 = arr.at(key)
        let val2 = arr.at(key - 1)

        if (key === 0) {
            return true
        }

        if (!val2) {
            return true
        }

        if (val1?.created_at !== val2?.created_at) {
            return true
        }

        return false
    }

    const showChatTime = (key) => {
        let arr = chats
        let val1 = arr.at(key)
        let val2 = arr.at(key + 1)

        if (!val2) {
            return true
        }

        if (val1?.created_at !== val2?.created_at) {
            return true
        }

        if (val1?.user_id !== val2?.user_id) {
            return true
        }

        return false
    }

    const showNewChatTime = (key) => {
        key = parseInt(key) + parseInt(chats?.length)
        let arr = [...chats, ...newChats]
        // let arr = newChats
        let val1 = arr.at(key)
        let val2 = arr.at(key + 1)

        if (key === (arr?.length - 1)) {
            return true
        }

        if (!val2) {
            return true
        }

        if (val1?.created_at !== val2?.created_at) {
            return true
        }

        return false
    }

    const onChangeChatText = (e) => {
        setPayloadChatText(e?.target?.value)
    }

    const onSubmitChat = async (e) => {
        e.preventDefault()

        if (payloadChatText) {
            setLoadingSubmitChat(true)

            try {
                const headerPost = {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`,
                }

                let data = {
                    pertemuan_id: pertemuanId,
                    text: payloadChatText
                }

                const res = await request(process.env.REACT_APP_SERVICE_CHAT + '/send', {
                    method: 'POST',
                    headers: headerPost,
                    body: JSON.stringify(data)
                })

                if (res?.success === true) {
                    setPayloadChatText("")
                    let _newChats = [...newChats]
                    _newChats.push({
                        user: {
                            id: user?.id,
                            name: user?.name
                        },
                        user_id: user?.id,
                        pertemuan_id: pertemuanId,
                        text: payloadChatText,
                        created_at: dateTime((new Date).getTime(), "DD-MM-YY HH:mm")
                    })
                    setNewChats(_newChats)
                    scrollToBottom()
                } else {
                    await alert({ title: "Gagal", html: "Gagal kirim data, terjadi kesalahan di sisi server." })
                }
                setLoadingSubmitChat(false)
            } catch (error) {
                setLoadingSubmitChat(false)
                await alert({ title: "Gagal", html: "Gagal kirim data, terjadi kesalahan di sisi server." })
            }
        }
    }

    const viewBodyLeft = () => {
        return <React.Fragment>
            <div className="nav-align-top mt-1 mb-3">
                <ul className="kursus nav nav-tabs pb-1" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab('Rasional')} type="button" className={"nav-link " + (tab === "Rasional" ? "active" : "")} tabIndex="-1">
                            Rasional
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab('Quiz Awal')} type="button" className={"nav-link " + (tab === "Quiz Awal" ? "active" : "")} tabIndex="-1">
                            Quiz Awal
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab('Jobsheet')} type="button" className={"nav-link " + (tab === "Jobsheet" ? "active" : "")} tabIndex="-1">
                            Jobsheet
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab('Gambar')} type="button" className={"nav-link " + (tab === "Gambar" ? "active" : "")} tabIndex="-1">
                            Gambar
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab('Video')} type="button" className={"nav-link " + (tab === "Video" ? "active" : "")} tabIndex="-1">
                            Video
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab('Quiz')} type="button" className={"nav-link " + (tab === "Quiz" ? "active" : "")} tabIndex="-1">
                            Quiz
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab('Tugas')} type="button" className={"nav-link " + (tab === "Tugas" ? "active" : "")} tabIndex="-1">
                            Tugas
                        </button>
                    </li>
                </ul>

                {
                    data?.id ?
                        <React.Fragment>
                            <div className={"mt-2 " + (tab === "Rasional" ? "" : "d-none")}>
                                <div className="card mt-1 mb-3">
                                    <div className="card-body">
                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: data?.rasional }}></p>
                                    </div>
                                </div>
                            </div>
                            <div className={"mt-2 " + (tab === "Quiz Awal" ? "" : "d-none")}>
                                {viewQuizAwal()}
                            </div>
                            <div className={"mt-2 " + (tab === "Jobsheet" ? "" : "d-none")}>
                                {viewJobsheet()}
                            </div>
                            <div className={"mt-2 " + (tab === "Gambar" ? "" : "d-none")}>
                                {viewGambar()}
                            </div>
                            <div className={"mt-2 " + (tab === "Video" ? "" : "d-none")}>
                                {viewVideo()}
                            </div>
                            <div className={"mt-2 " + (tab === "Quiz" ? "" : "d-none")}>
                                {viewQuiz()}
                            </div>
                            <div className={"mt-2 " + (tab === "Tugas" ? "" : "d-none")}>
                                {viewLaporan()}
                            </div>
                        </React.Fragment>
                        : null
                }
            </div>
        </React.Fragment>
    }

    const viewBodyRight = () => {
        return <React.Fragment>
            <div className="nav-align-top mt-1 mb-3">
                <ul className="kursus nav nav-tabs pb-1" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab2('Forum')} type="button" className={"nav-link " + (tab2 === "Forum" ? "active" : "")} tabIndex="-1">
                            Forum
                        </button>
                    </li>
                </ul>

                {
                    data?.id ?
                        <React.Fragment>
                            <div className={"mt-2 " + (tab2 === "Forum" ? "" : "d-none")}>
                                {viewForum()}
                            </div>
                        </React.Fragment>
                        : null
                }
            </div>
        </React.Fragment>
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row mb-2">
                <div className="col-lg-4">
                    <Link to={process.env.REACT_APP_SUBDIR + "/progress-kursus/detail/" + id} className="btn btn-white mb-2 btn-md-block">Kembali</Link>
                </div>
                <div className="col-lg-8 text-end"></div>
            </div>

            {viewHeader()}

            <div className="row mb-2">
                <div className="col-xl-8 col-lg-7 col-md-7">
                    {viewBodyLeft()}
                </div>

                <div className="col-xl-4 col-lg-5 col-md-5">
                    {viewBodyRight()}
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken, user }, kursus: { del } } = state

    return { accessToken, user, del }
}

export default withRouter(connect(mapStateToProps, { actionDeleteKursus, actionResetDeleteKursus })(ProgressKursusPertemuanDetail))