import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionUpdateQuizAwal, actionResetFormQuizAwal } from "../../store/quiz-awal/actions"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import Dropdown from "../../components/Form/Dropdown"
import { request } from "../../utils/request"
import NumberInput from "../../components/Form/NumberInput"
import TextInput from "../../components/Form/TextInput"
import { closeModal } from "../../utils/modal"
import MultiSelect from "../../components/Form/MultiSelect"
import TextEditor from "../../components/Form/TextEditor"
import TextArea from "../../components/Form/TextArea"

const initialPayload = {
    pertanyaan: "",
    kategori_soal_id: "",
    status: "",
    kunci_jawaban: "",
    point: 1,
    kelompok: [],
    jawaban_a: "",
    jawaban_b: "",
    jawaban_c: "",
    jawaban_d: "",
    jawaban_e: ""
}

const optKunciJawaban = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "E", value: "E" }
]

const QuizAwalUpdate = (props) => {
    const { actionUpdateQuizAwal, actionResetFormQuizAwal, accessToken, match: { params: { id: _id } }, history: { location: { state } }, form, fromModal = false, quizId = null, callbackSuccessModal = null } = props

    const [id, setId] = useState()

    const [payload, setPayload] = useState(initialPayload)
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)
    const [noData, setNoData] = useState(false)
    const [from, setFrom] = useState("/quiz-awal")
    const [optKelompok, setOptKelompok] = useState([])

    useEffect(() => {
        // const titlePage = "Ubah Quiz Awal"
        // changeTitle(titlePage)
        // changeBreadcrumbs(titlePage)

        if (fromModal === false) {
            setId(_id)
            getData(_id)
        } else {
            setId(quizId)
            getData(quizId)
        }

        // const query = new URLSearchParams(props.location.search)
        // if (query.get('from') === "detail") {
        //     setFrom("/quiz-awal/detail/" + id)
        // }

        actionResetFormQuizAwal()
        getKelompok()

        return () => actionResetFormQuizAwal()
    }, [quizId, _id])

    const getKelompok = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_KELOMPOK + '/all', {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setOptKelompok(res?.result)
            }
        } catch (error) {
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const getData = async (_id = null) => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }

            if (_id === null) {
                _id = id
            }

            const res = await request(process.env.REACT_APP_SERVICE_QUIZ_AWAL + '/' + _id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                let result = {
                    ...res?.result,
                    "pertanyaan": ""
                }
                setPayload(result)
                setTimeout(() => {
                    setPayload({
                        ...result,
                        "pertanyaan": res?.result?.pertanyaan
                    })
                }, 500)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    useEffect(async () => {
        if (fromModal === true) {
            callbackSuccessModal(null)
        }

        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })

                if (fromModal !== true) {
                    pushUrl(from)
                } else {
                    callbackSuccessModal(true)
                    closeModal()
                }
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const onChange = ((field, value) => {
        if (field === "kelompok") {
            let kelompok = []

            value?.map((val) => {
                kelompok.push(val?.value)
            })

            payload[field] = kelompok
        } else {
            payload[field] = value
        }

        setPayload({
            ...payload,
        })
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            let _payload = {
                pertanyaan: payload?.pertanyaan,
                pertemuan_id: payload?.pertemuan_id,
                status: payload?.status,
                kelompok: payload?.kelompok,
                jawaban: [
                    {
                        kode: "A",
                        jawaban: payload?.jawaban_a,
                        jawaban_betul: payload?.kunci_jawaban === "A" ? true : false,
                        point: payload?.kunci_jawaban === "A" ? payload?.point : 0
                    },
                    {
                        kode: "B",
                        jawaban: payload?.jawaban_b,
                        jawaban_betul: payload?.kunci_jawaban === "B" ? true : false,
                        point: payload?.kunci_jawaban === "B" ? payload?.point : 0
                    },
                    {
                        kode: "C",
                        jawaban: payload?.jawaban_c,
                        jawaban_betul: payload?.kunci_jawaban === "C" ? true : false,
                        point: payload?.kunci_jawaban === "C" ? payload?.point : 0
                    },
                    {
                        kode: "D",
                        jawaban: payload?.jawaban_d,
                        jawaban_betul: payload?.kunci_jawaban === "D" ? true : false,
                        point: payload?.kunci_jawaban === "D" ? payload?.point : 0
                    },
                    {
                        kode: "E",
                        jawaban: payload?.jawaban_e,
                        jawaban_betul: payload?.kunci_jawaban === "E" ? true : false,
                        point: payload?.kunci_jawaban === "E" ? payload?.point : 0
                    }
                ]
            }

            actionUpdateQuizAwal({
                id,
                payload: _payload,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false


        if (!payload?.pertanyaan || payload?.pertanyaan === "") {
            hasError = true
            jsonErrors["pertanyaan"] = "Pertanyaan harus diisi"
        }
        if (!payload?.kunci_jawaban || payload?.kunci_jawaban === "") {
            hasError = true
            jsonErrors["kunci_jawaban"] = "Kunci Jawaban harus diisi"
        }
        if (!payload?.point || payload?.point === "") {
            hasError = true
            jsonErrors["point"] = "Point harus diisi"
        }
        if (!payload?.jawaban_a || payload?.jawaban_a === "") {
            hasError = true
            jsonErrors["jawaban_a"] = "Jawaban A harus diisi"
        }
        if (!payload?.jawaban_b || payload?.jawaban_b === "") {
            hasError = true
            jsonErrors["jawaban_b"] = "Jawaban B harus diisi"
        }
        if (!payload?.jawaban_c || payload?.jawaban_c === "") {
            hasError = true
            jsonErrors["jawaban_c"] = "Jawaban C harus diisi"
        }
        if (!payload?.jawaban_d || payload?.jawaban_d === "") {
            hasError = true
            jsonErrors["jawaban_d"] = "Jawaban D harus diisi"
        }
        if (!payload?.jawaban_e || payload?.jawaban_e === "") {
            hasError = true
            jsonErrors["jawaban_e"] = "Jawaban E harus diisi"
        }
        if (!payload?.kelompok || payload?.kelompok?.length < 1) {
            hasError = true
            jsonErrors["kelompok"] = "Kelompok harus diisi"
        }
        if (!payload?.status || payload?.status === "") {
            hasError = true
            jsonErrors["status"] = "Status harus diisi"
        }

        console.log("errors", jsonErrors)
        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    const formView = () => {
        return <React.Fragment>
            {
                noData === true ?
                    <span>Data tidak ditemukan</span>
                    :
                    <form onSubmit={onSubmit} >
                        <div className="row">
                            <div className="col-lg-12 col-xl-10 col-xxl-8">
                                <TextEditor
                                    id={"pertanyaan"}
                                    name={"pertanyaan"}
                                    placeholder={"Masukkan pertanyaan"}
                                    label={"Pertanyaan"}
                                    onChange={(value) => onChange('pertanyaan', value)}
                                    required={true}
                                    value={payload?.pertanyaan}
                                    error={errors?.pertanyaan}
                                    errors={form?.errors?.pertanyaan}
                                    tabIndex={1} />


                                <div className="row">
                                    <div className="col-lg-6">
                                        <Dropdown
                                            id={"kunci_jawaban"}
                                            name={"kunci_jawaban"}
                                            placeholder={"Pilih kunci jawaban"}
                                            label={"Kunci Jawaban"}
                                            onChange={(e) => onChange('kunci_jawaban', e?.target?.value)}
                                            required={true}
                                            value={payload?.kunci_jawaban}
                                            error={errors?.kunci_jawaban}
                                            errors={form?.errors?.kunci_jawaban}
                                            tabIndex={2}
                                            options={optKunciJawaban} />
                                    </div>
                                    <div className="col-lg-6">
                                        <Dropdown
                                            id={"status"}
                                            name={"status"}
                                            placeholder={"Pilih status"}
                                            label={"Status"}
                                            onChange={(e) => onChange('status', e?.target?.value)}
                                            required={true}
                                            value={payload?.status}
                                            error={errors?.status}
                                            errors={form?.errors?.status}
                                            tabIndex={3}
                                            options={[
                                                { label: "Aktif", value: "Aktif" },
                                                { label: "Tidak Aktif", value: "Tidak Aktif" }
                                            ]} />
                                    </div>

                                    <div className="col-lg-6">
                                        <TextArea
                                            id={"jawaban_a"}
                                            name={"jawaban_a"}
                                            placeholder={"Masukkan jawaban A"}
                                            label={"Jawaban A"}
                                            onChange={(e) => onChange('jawaban_a', e?.target?.value)}
                                            required={true}
                                            value={payload?.jawaban_a}
                                            error={errors?.jawaban_a}
                                            errors={form?.errors?.jawaban_a}
                                            tabIndex={4} />
                                    </div>
                                    <div className="col-lg-6">
                                        <TextArea
                                            id={"jawaban_b"}
                                            name={"jawaban_b"}
                                            placeholder={"Masukkan jawaban B"}
                                            label={"Jawaban B"}
                                            onChange={(e) => onChange('jawaban_b', e?.target?.value)}
                                            required={true}
                                            value={payload?.jawaban_b}
                                            error={errors?.jawaban_b}
                                            errors={form?.errors?.jawaban_b}
                                            tabIndex={5} />
                                    </div>
                                    <div className="col-lg-6">
                                        <TextArea
                                            id={"jawaban_c"}
                                            name={"jawaban_c"}
                                            placeholder={"Masukkan jawaban C"}
                                            label={"Jawaban C"}
                                            onChange={(e) => onChange('jawaban_c', e?.target?.value)}
                                            required={true}
                                            value={payload?.jawaban_c}
                                            error={errors?.jawaban_c}
                                            errors={form?.errors?.jawaban_c}
                                            tabIndex={6} />
                                    </div>
                                    <div className="col-lg-6">
                                        <TextArea
                                            id={"jawaban_d"}
                                            name={"jawaban_d"}
                                            placeholder={"Masukkan jawaban D"}
                                            label={"Jawaban D"}
                                            onChange={(e) => onChange('jawaban_d', e?.target?.value)}
                                            required={true}
                                            value={payload?.jawaban_d}
                                            error={errors?.jawaban_d}
                                            errors={form?.errors?.jawaban_d}
                                            tabIndex={7} />
                                    </div>
                                    <div className="col-lg-6">
                                        <TextArea
                                            id={"jawaban_e"}
                                            name={"jawaban_e"}
                                            placeholder={"Masukkan jawaban E"}
                                            label={"Jawaban E"}
                                            onChange={(e) => onChange('jawaban_e', e?.target?.value)}
                                            required={true}
                                            value={payload?.jawaban_e}
                                            error={errors?.jawaban_e}
                                            errors={form?.errors?.jawaban_e}
                                            tabIndex={8} />
                                    </div>
                                </div>

                                <MultiSelect
                                    id={"kelompok"}
                                    name={"kelompok"}
                                    placeholder={"Pilih kelompok"}
                                    label={"Kelompok"}
                                    onChange={(e) => onChange('kelompok', e)}
                                    required={true}
                                    value={payload?.kelompok}
                                    error={errors?.kelompok}
                                    errors={form?.errors?.kelompok}
                                    tabIndex={9}
                                    options={optKelompok}
                                />

                                <button
                                    tabIndex={10}
                                    type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                                    Kirim
                                    {form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                </button>
                            </div>
                        </div>
                    </form>
            }
        </React.Fragment>
    }

    return <React.Fragment>
        {
            fromModal !== true ?
                <div className="container-xxl flex-grow-1 container-p-y">
                    <Link to={process.env.REACT_APP_SUBDIR + from} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

                    <div className="card mt-3">
                        <div className="card-body">
                            {formView()}
                        </div>
                    </div>
                </div>
                : formView()
        }
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, quizAwal: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionUpdateQuizAwal, actionResetFormQuizAwal })(QuizAwalUpdate))