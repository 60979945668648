import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// divider: {
//     label: "Label",
//     type: "divider"
// }

// parentMenu: {
//     type: "parent",
//     label: "Label",
//     icon: "bx bx-user-check",
//     childs: [
//         { label: "Belum Daftar Ulang", url: "/laporan-peserta-belum-daftar-ulang" },
//         { label: "Calon Peserta Ujian", url: "/laporan-peserta-daftar-ulang" },
//         { label: "Peserta Ujian", url: "/laporan-peserta-ikut-ujian" },
//     ]
// }

const Menu = () => {
    const [items, setItems] = useState([])
    const [arrLocation, setArrLocation] = useState(null)

    const location = useLocation();

    useEffect(() => {
        let arrLocation = location?.pathname?.split("/")

        if (arrLocation?.length > 0) {
            setArrLocation(arrLocation)
        }
    }, [location]);

    useEffect(() => {
        let arrItems = [
            {
                type: "menu",
                label: "Beranda",
                url: "/beranda",
                icon: "bx bx-home-circle"
            },
            {
                label: "Master",
                type: "divider"
            },
            {
                type: "menu",
                label: "Kursus",
                url: "/kursus",
                icon: "bx bx-category-alt"
            },
            // {
            //     type: "menu",
            //     label: "Modul",
            //     url: "/modul",
            //     icon: "bx bx-file"
            // },
            // {
            //     type: "menu",
            //     label: "Pertemuan",
            //     url: "/pertemuan",
            //     icon: "bx bx-copy-alt"
            // },
            // {
            //     type: "menu",
            //     label: "Kelompok",
            //     url: "/kelompok",
            //     icon: "bx bx-grid-alt"
            // },
            {
                label: "Progress",
                type: "divider"
            },
            {
                type: "menu",
                label: "Kursus",
                url: "/progress-kursus",
                icon: "bx bx-category-alt"
            },
            {
                type: "menu",
                label: "Tugas Pelajar",
                url: "/penilaian-tugas-pelajar",
                icon: "bx bx-task"
            },
            {
                label: "Report",
                type: "divider"
            },
            {
                type: "menu",
                label: "Tugas Pelajar",
                url: "/laporan-tugas-pelajar",
                icon: "bx bx-task"
            },
            {
                type: "menu",
                label: "Quiz Awal Pelajar",
                url: "/laporan-quiz-awal-pelajar",
                icon: "bx bx-list-ol"
            },
            {
                type: "menu",
                label: "Quiz Pelajar",
                url: "/laporan-quiz-pelajar",
                icon: "bx bx-list-ol"
            },
            {
                type: "menu",
                label: "Capaian Pelajar",
                url: "/laporan-capaian-pelajar",
                icon: "bx bx-spreadsheet"
            }
        ]

        setItems(arrItems)
    }, [])

    return <React.Fragment>
        <ul className="menu-inner py-1">
            {
                items?.map((item, key) => {
                    if (item?.type === "parent") {
                        return <li key={key} className={"menu-item" + (item?.childs?.filter((x) => x?.url === "/" + arrLocation[1])?.length > 0 ? " active open" : "")}>
                            <a href="/#" onClick={(e) => e.preventDefault()} className="menu-link menu-toggle">
                                <i className={"menu-icon tf-icons " + item?.icon}></i>
                                <div>{item?.label}</div>
                            </a>
                            <ul className="menu-sub">
                                {
                                    item?.childs?.map((item2, key2) => {
                                        return <li key={key2} className={"menu-item" + (item2?.url === "/" + arrLocation[1] ? " active" : "")}>
                                            <Link to={item2?.url} className="menu-link">
                                                <div>{item2?.label}</div>
                                            </Link>
                                        </li>
                                    })
                                }
                            </ul>
                        </li>
                    } else if (item?.type === "menu") {
                        return <li key={key} className={"menu-item" + (item?.url === "/" + arrLocation[1] ? " active" : "")}>
                            <Link to={item?.url} className="menu-link">
                                <i className={"menu-icon tf-icons " + item?.icon}></i>
                                <div>{item?.label}</div>
                            </Link>
                        </li>
                    } else if (item?.type === "divider") {
                        return <li key={key} className="menu-header small text-uppercase">
                            <span className="menu-header-text">{item?.label}</span>
                        </li>
                    }
                })
            }
        </ul>
    </React.Fragment>
}

export default Menu