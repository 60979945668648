import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionUpdatePertemuan, actionResetFormPertemuan } from "../../store/pertemuan/actions"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import Dropdown from "../../components/Form/Dropdown"
import { request } from "../../utils/request"
import NumberInput from "../../components/Form/NumberInput"
import TextInput from "../../components/Form/TextInput"
import FileInput from "../../components/Form/FileInput"
import TextArea from "../../components/Form/TextArea"
import { closeModal } from "../../utils/modal"
import TextEditor from "../../components/Form/TextEditor"

const PertemuanUpdate = (props) => {
    const { actionUpdatePertemuan, actionResetFormPertemuan, accessToken, match: { params: { id: _id } }, history: { location: { state } }, form, fromModal = false, pertemuanId = null, callbackSuccessModal = null } = props

    const [id, setId] = useState()

    const [payload, setPayload] = useState({
        nama: "",
        modul_id: "",
        keterangan: "",
        status: ""
    })
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)
    const [noData, setNoData] = useState(false)
    const [from, setFrom] = useState("/pertemuan")

    useEffect(() => {
        // const titlePage = "Ubah Pertemuan"
        // changeTitle(titlePage)
        // changeBreadcrumbs(titlePage)

        if (fromModal === false) {
            setId(_id)
            getData(_id)
        } else {
            setId(pertemuanId)
            getData(pertemuanId)
        }
        const query = new URLSearchParams(props.location.search)
        if (query.get('from') === "detail") {
            setFrom("/pertemuan/detail/" + id)
        }

        actionResetFormPertemuan()

        return () => actionResetFormPertemuan()
    }, [])

    const getData = async (_id = null) => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }

            if (_id === null) {
                _id = id
            }

            const res = await request(process.env.REACT_APP_SERVICE_PERTEMUAN + '/' + _id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                let result = {
                    ...res?.result,
                    "keterangan": ""
                }
                setPayload(result)
                setTimeout(() => {
                    setPayload({
                        ...result,
                        "keterangan": res?.result?.keterangan
                    })
                }, 500)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    useEffect(async () => {
        if (fromModal === true) {
            callbackSuccessModal(null)
        }

        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                if (fromModal !== true) {
                    pushUrl(from)
                } else {
                    callbackSuccessModal(true)
                    closeModal()
                }
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const onChange = ((field, value) => {
        payload[field] = value

        setPayload({
            ...payload,
        })
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            actionUpdatePertemuan({
                id,
                payload,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.nama || payload?.nama === "") {
            hasError = true
            jsonErrors["nama"] = "Nama harus diisi"
        }
        if (!payload?.status || payload?.status === "") {
            hasError = true
            jsonErrors["status"] = "Status harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    const formView = () => {
        return <React.Fragment>
            {
                noData === true ?
                    <span>Data tidak ditemukan</span>
                    :
                    <form onSubmit={onSubmit} >
                        <div className="row">
                            <div className="col-lg-12 col-xl-10 col-xxl-8">
                                <TextInput
                                    id={"nama"}
                                    name={"nama"}
                                    placeholder={"Masukkan nama pertemuan"}
                                    label={"Nama"}
                                    onChange={(e) => onChange('nama', e?.target?.value)}
                                    required={true}
                                    value={payload?.nama}
                                    error={errors?.nama}
                                    errors={form?.errors?.nama}
                                    tabIndex={1} />

                                <TextEditor
                                    id={"keterangan"}
                                    name={"keterangan"}
                                    placeholder={"Masukkan keterangan"}
                                    label={"Keterangan"}
                                    onChange={(value) => onChange('keterangan', value)}
                                    required={true}
                                    value={payload?.keterangan}
                                    error={errors?.keterangan}
                                    errors={form?.errors?.keterangan}
                                    tabIndex={2} />

                                <TextInput
                                    id={"durasi_quiz"}
                                    name={"durasi_quiz"}
                                    placeholder={"Masukkan durasi quiz pertemuan"}
                                    label={"Durasi Quiz (Menit)"}
                                    onChange={(e) => onChange('durasi_quiz', e?.target?.value)}
                                    required={true}
                                    value={payload?.durasi_quiz}
                                    error={errors?.durasi_quiz}
                                    errors={form?.errors?.durasi_quiz}
                                    tabIndex={3} />

                                <Dropdown
                                    id={"status"}
                                    name={"status"}
                                    placeholder={"Pilih status"}
                                    label={"Status"}
                                    onChange={(e) => onChange('status', e?.target?.value)}
                                    required={true}
                                    value={payload?.status}
                                    error={errors?.status}
                                    errors={form?.errors?.status}
                                    tabIndex={4}
                                    options={[
                                        { label: "Aktif", value: "Aktif" },
                                        { label: "Tidak Aktif", value: "Tidak Aktif" }
                                    ]} />

                                <button
                                    tabIndex={4}
                                    type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                                    Kirim
                                    {form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                </button>
                            </div>
                        </div>
                    </form>
            }
        </React.Fragment>
    }

    return <React.Fragment>
        {
            fromModal === false ?
                <div className="container-xxl flex-grow-1 container-p-y">
                    <Link to={process.env.REACT_APP_SUBDIR + from} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

                    <div className="card mt-3">
                        <div className="card-body">
                            {formView()}
                        </div>
                    </div>
                </div>
                : formView()
        }
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, pertemuan: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionUpdatePertemuan, actionResetFormPertemuan })(PertemuanUpdate))