import React from "react"

const LimitTable = ({ perPage, onChange, max = 100 }) => {
    return <React.Fragment>
        <div className="d-block d-lg-flex">
            <label className="me-2 justify-content-center align-self-center">Tampil</label>
            <select aria-controls="Maks" className="form-select" value={perPage} onChange={(e) => onChange(e?.target?.value)}>
                {
                    5 <= max ?
                        <option value={"5"}>5</option>
                        : null
                }
                {
                    10 <= max ?
                        <option value={"10"}>10</option>
                        : null
                }
                {
                    25 <= max ?
                        <option value={"25"}>25</option>
                        : null
                }
                {
                    50 <= max ?
                        <option value={"50"}>50</option>
                        : null
                }
                {
                    100 <= max ?
                        <option value={"100"}>100</option>
                        : null
                }
            </select>
        </div>
    </React.Fragment>
}

export default LimitTable